const dialogProps = 'width=' + screen.availWidth + ',height=' + screen.availHeight

const getDelegationUrl = (lang: string) => {
  const requestAuthorizationsUrl = new URL(process.env.REACT_APP_API || '')
  requestAuthorizationsUrl.pathname = `/v1/user/authorizations/add`
  requestAuthorizationsUrl.searchParams.append('origin', location.protocol + '//' + location.host)
  requestAuthorizationsUrl.searchParams.append('lang', lang)
  return requestAuthorizationsUrl.toString()
}

const receiveOmUidFromMessage = () =>
  new Promise<number>((resolve, reject) => {
    window.addEventListener('message', function parseOmUid(event: MessageEvent) {
      if (event.origin === process.env.REACT_APP_DELEGATION_URL) {
        try {
          window.removeEventListener('message', parseOmUid)
          const omUid: number | null = Number(JSON.parse(event.data).omUid) || null

          if (omUid) {
            resolve(omUid)
          } else {
            reject(new Error('Did not receive om uid from API'))
          }
        } catch (error) {
          reject(error)
        }
      }
    })
  })

const isIE =
  typeof navigator !== 'undefined' &&
  (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1)

class DelegationService {
  public requestNewAuthorization = async (lang: string) => {
    try {
      const delegateUrl = getDelegationUrl(lang)

      let dialog
      if (isIE) {
        dialog = window.open('/', 'Authorization', dialogProps)
        dialog!.location.href = 'about:blank'
        dialog!.location.href = delegateUrl
      } else {
        dialog = window.open(delegateUrl, 'Authorization', dialogProps)
      }
      const omUid = await receiveOmUidFromMessage()
      return omUid
    } catch (error) {
      throw new Error('Request failed')
    }
  }
}

export default new DelegationService()
