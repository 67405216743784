import { Dayjs } from 'dayjs'
import React, { useEffect, useState } from 'react'

import { AppointmentId } from '../../state/common/atoms'
import api from '../services/api'
import dayjs from '../utils/dayjs/dayjs'

import useLoginState from './useLoginState'

export const useLockAppointment = (
  appointmentId: AppointmentId,
  isReservedRef: React.MutableRefObject<boolean>,
  duration?: number,
  lockLength?: number
): { lockReleaseDateTime: Dayjs | undefined; error: boolean } => {
  const { loginStatus } = useLoginState()
  const [lockReleaseDateTime, setLockReleaseDateTime] = useState<Dayjs>()
  const [lockedAppointmentIds, setLockedAppointmentIds] = useState<number[]>()
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    setLockedAppointmentIds(undefined)
  }, [appointmentId, duration])

  useEffect(() => {
    if (loginStatus && !lockedAppointmentIds && typeof appointmentId === 'number') {
      api.v1
        .lockAppointment(
          { appointmentId, duration, lockLength },
          { credentials: 'include', baseUrl: process.env.REACT_APP_API }
        )
        .then((res) => {
          if (res.error) {
            setError(true)
            setLockReleaseDateTime(undefined)
            setLockedAppointmentIds(undefined)
            return
          }
          setLockReleaseDateTime(dayjs(res.data.endDateTime))
          setLockedAppointmentIds(res.data.appointmentIds)
        })
        .catch(() => {
          setError(true)
          setLockReleaseDateTime(undefined)
          setLockedAppointmentIds(undefined)
        })
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (loginStatus && lockedAppointmentIds && !isReservedRef.current) {
        api.v1
          .unlockAppointment(
            { appointmentIds: lockedAppointmentIds },
            { credentials: 'include', baseUrl: process.env.REACT_APP_API }
          )
          .catch(() => {
            /* NO-OP: server will eventually clean up if the request fails. */
          })
      }
    }
  }, [loginStatus, lockedAppointmentIds, appointmentId, duration, lockLength, isReservedRef])

  return { lockReleaseDateTime, error }
}
