import { useEffect, useMemo, useState } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const debounce = <Params extends any[], ReturnType>(
  func: (...args: Params) => ReturnType,
  wait: number
): ((...args: Params) => void) => {
  let timeout: NodeJS.Timeout
  return (...args: Params) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      func(...args)
    }, wait)
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDebounce = <Params extends any[], ReturnType>(
  func: (...args: Params) => ReturnType,
  wait: number
): ((...args: Params) => void) => {
  return useMemo(() => debounce(func, wait), [func, wait])
}

export const useDebouncedValue = <T>(value: T, wait: number): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)
  const debouncedSet = useDebounce(setDebouncedValue, wait)

  useEffect(() => {
    debouncedSet(value)
  }, [debouncedSet, value])

  return debouncedValue
}

export default debounce
