import { useFormikContext } from 'formik'
import React, { useEffect } from 'react'

const ScrollToError: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { isSubmitting, errors } = useFormikContext()

  useEffect(() => {
    if (!isSubmitting) {
      return
    }

    const id = Object.keys(errors).shift()
    if (!id) {
      return
    }

    const element = document.getElementById(id)
    element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    element?.focus({ preventScroll: true })
  }, [isSubmitting, errors])

  return null
}

export default ScrollToError
