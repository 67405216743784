import React from 'react'

import DatePickerCalendar from '../../../common/components/DateSelect/DatePickerCalendar'
import { useIsDesktop, useIsTablet } from '../../../common/hooks/useBreakpoint'
import useSearchCalendar from '../hooks/useSearchCalendar'

const DesktopDateSelect: React.FC<React.PropsWithChildren<unknown>> = () => {
  const isDesktop = useIsDesktop()
  const isTablet = useIsTablet()
  const { selectedDate, setSelectedDate, calendarDataFetcher } = useSearchCalendar()

  if (!isDesktop && !isTablet) {
    return null
  }

  return (
    <DatePickerCalendar
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
      calendarDataFetcher={calendarDataFetcher}
      variant="small"
    />
  )
}

export default DesktopDateSelect
