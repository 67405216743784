import { TFunction } from 'i18next'

import {
  LocationAutocompleteResult,
  LocationResult,
  LocationType,
} from '../../../__generated__/api'
import { allLocationsSelection } from '../../../state/search/atoms'
import { GeolocateResult, GeolocateStatus } from '../../services/geolocate'
import { SearchTreeItem } from '../Search/types'

export const convertLocationsToSearchTreeItems = (
  locations: LocationResult[],
  rootSearchItemTitle: string,
  rootSearchItemSubtitle: string,
  supportedLocationTypes: number[]
): Array<SearchTreeItem<LocationResult>> => {
  const searchItems = locations
    .filter(
      (location) =>
        location.uniqueId === 'ALL-LOCATIONS' ||
        location?.locationTypes.some((locationType) =>
          supportedLocationTypes.length ? supportedLocationTypes.includes(locationType) : true
        )
    )
    .map((location) => ({
      id: location.uniqueId,
      name: location.name,
      subtitle: '',
      item: {
        ...location,
      },
    }))

  const rootSearchItems = searchItems.filter(
    (searchItem) =>
      searchItem.item.type === LocationType.Region ||
      searchItem.item.type === LocationType.City ||
      searchItem.item.type === LocationType.All
  )

  return rootSearchItems.map((rootSearchItem) => {
    const clinics = searchItems
      .filter(
        (searchItem) =>
          searchItem.item.type === LocationType.Clinic &&
          (searchItem.item.clinicIDs.some((clinicId) =>
            rootSearchItem.item.clinicIDs.includes(clinicId)
          ) ||
            searchItem.item.assisDentBranchIDs.some((clinicId) =>
              rootSearchItem.item.assisDentBranchIDs.includes(clinicId)
            ))
      )
      .sort((a, b) => a.item.priority - b.item.priority)
    return {
      ...rootSearchItem,
      subtitle:
        rootSearchItem.item.type === LocationType.All
          ? rootSearchItem.subtitle
          : clinics.length > 1
          ? rootSearchItemSubtitle
          : undefined,
      children: [
        ...(clinics.length > 1
          ? [
              {
                ...rootSearchItem,
                name: `${rootSearchItemTitle} ${rootSearchItem.name}`,
                subtitle: undefined,
              },
            ]
          : []),
        ...clinics.map((clinic) => ({
          ...clinic,
        })),
      ],
    }
  })
}

export const isLocationSupported = (
  option: LocationAutocompleteResult,
  supportedLocationTypes: number[]
) =>
  supportedLocationTypes.length
    ? option.locationTypes.some((locationType) => supportedLocationTypes.includes(locationType))
    : true

const ALL_GEOLOCATE_LOCATIONS_ID = 'ALL_GEOLOCATE_LOCATIONS'

const GEOLOCATE_ITEM_ID = 'GEOLOCATE'

export const convertGeolocateResultToSearchTreeItem = (
  t: TFunction,
  geolocateResult?: GeolocateResult
): SearchTreeItem<LocationResult> => {
  let children: Array<SearchTreeItem<LocationResult>> = []
  if (geolocateResult) {
    const { results: foundLocations } = geolocateResult
    if (foundLocations.length > 0) {
      const aggregate =
        foundLocations.length > 1
          ? [
              {
                id: ALL_GEOLOCATE_LOCATIONS_ID,
                name: t('component.locationSelect.allFoundLocationsTitle'),
                item: {
                  id: 0,
                  uniqueId: ALL_GEOLOCATE_LOCATIONS_ID,
                  clinicIDs: foundLocations.map((location) => location.uniqueId),
                  assisDentBranchIDs: foundLocations.map((location) => location.uniqueId),
                  address: null,
                  clinicType: LocationType.All,
                  imageUrl: null,
                  lat: null,
                  long: null,
                  name: t('component.locationSelect.allFoundLocationsTitle'),
                  shortName: null,
                  phone: null,
                  priority: 0,
                  type: LocationType.All,
                  favorite: true,
                  locationTypes: [],
                },
              },
            ]
          : []

      children = [
        ...aggregate,
        ...foundLocations.map((location) => ({
          id: location.uniqueId,
          name: location.name,
          item: location,
        })),
      ]
    } else {
      // List of found is empty, add all locations selection to children
      children = [
        {
          id: 0,
          name: t('component.locationSelect.allLocationsSelectTitle'),
          item: {
            id: 0,
            uniqueId: allLocationsSelection[0],
            clinicIDs: allLocationsSelection,
            assisDentBranchIDs: [],
            address: null,
            clinicType: LocationType.All,
            imageUrl: null,
            lat: null,
            long: null,
            name: t('component.locationSelect.allLocationsSelectTitle'),
            shortName: null,
            phone: null,
            priority: 0,
            type: LocationType.All,
            favorite: true,
            locationTypes: [],
          },
        },
      ]
    }
  }

  return {
    id: GEOLOCATE_ITEM_ID,
    name: geolocateResult
      ? t('component.locationSelect.geolocateResultTitle')
      : t('component.locationSelect.geolocateItemTitle'),
    caption: t('component.locationSelect.geolocateItemCaption'),
    description:
      geolocateResult && geolocateResult.status === GeolocateStatus.OK
        ? t(`component.locationSelect.geolocateResultDescription.${geolocateResult.status}`)
        : undefined,
    error:
      geolocateResult && geolocateResult.status !== GeolocateStatus.OK
        ? t(`component.locationSelect.geolocateResultDescription.${geolocateResult.status}`)
        : undefined,
    item: {
      id: 0,
      uniqueId: GEOLOCATE_ITEM_ID,
      clinicIDs: [GEOLOCATE_ITEM_ID],
      assisDentBranchIDs: [GEOLOCATE_ITEM_ID],
      address: null,
      clinicType: LocationType.All,
      imageUrl: null,
      lat: null,
      long: null,
      name: t('component.locationSelect.geolocateItemTitle'),
      shortName: null,
      phone: null,
      priority: 0,
      type: LocationType.All,
      favorite: true,
      locationTypes: [],
    },
    children:
      geolocateResult && geolocateResult.status !== GeolocateStatus.OK ? undefined : children,
  }
}

export const isAllGeolocateLocation = (location?: LocationResult): boolean => {
  return location?.uniqueId === ALL_GEOLOCATE_LOCATIONS_ID
}

export const isGeolocateLocation = (location?: LocationResult): boolean => {
  return location?.uniqueId === GEOLOCATE_ITEM_ID
}
