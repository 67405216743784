import React from 'react'
import { useTranslation } from 'react-i18next'

import Toggle from '../Toggle/Toggle'

export type AppointmentListVariant = 'specialist' | 'time'

interface Props {
  value: AppointmentListVariant
  onToggle(value: AppointmentListVariant): void
}

const AppointmentListToggle: React.FC<React.PropsWithChildren<Props>> = ({ value, onToggle }) => {
  const { t } = useTranslation()

  return (
    <Toggle
      value={value === 'time'}
      ariaLabel={t('component.appointmentListToggle.ariaLabel')}
      mobileTrueLabel={t('component.appointmentListToggle.timeMobile')}
      trueLabel={t('component.appointmentListToggle.time')}
      mobileFalseLabel={t('component.appointmentListToggle.specialistMobile')}
      falseLabel={t('component.appointmentListToggle.specialistMobile')}
      onToggle={(val) => onToggle(val ? 'time' : 'specialist')}
      trueDaPath="acc-free-rec-time"
      falseDaPath="acc-person"
    />
  )
}

export default AppointmentListToggle
