import { useEffect, useMemo, useCallback } from 'react'
import { useSetRecoilState } from 'recoil'

import { PublicNode } from '../../__generated__/api'
import { searchNodeRedirectModal } from '../../state/search/atoms'

import useTranslation from './useTranslation'

const useSearchRedirect = (node?: PublicNode | null) => {
  const memoizedNode = useMemo(() => node, [node])
  const { i18n } = useTranslation()
  const setModalName = useSetRecoilState(searchNodeRedirectModal)

  const redirect = useCallback(
    (url: string) => {
      const avBaseUrl = new URL(
        process.env.REACT_APP_AV_BASE_URL ?? 'https://ajanvaraus.mehilainen.fi'
      )
      avBaseUrl.pathname = url
      avBaseUrl.searchParams.set('language', i18n.language)
      window.location.replace(avBaseUrl.toString())
    },
    [i18n.language]
  )

  const modalParam = 'modal'
  useEffect(() => {
    if (memoizedNode?.redirect) {
      const url = new URL(memoizedNode.redirect, window.location.origin)
      const params = new URLSearchParams(url.search)
      if (params.has(modalParam)) {
        const modalName = params.get(modalParam)!
        setModalName({ modalName, url: url.pathname })
      } else {
        redirect(memoizedNode.redirect)
      }
    }
  }, [memoizedNode, redirect, setModalName])

  return redirect
}

export default useSearchRedirect
