import styled from '@emotion/styled'
import { Gray400 } from '@mehilainen/design-system-tokens/colors'
import { MenuItem, Select } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { LanguageResult } from '../../../__generated__/api'
import { VisuallyHidden } from '../Layout/Layout'

import Fieldset from './Fieldset'

const StyledSelect = styled(Select)`
  .MuiSelect-root {
    background-color: white;
  }
  .MuiFormHelperText-root {
    display: none;
  }
  [class*='SelectIconContainer'] {
    border-left: none;
  }
  fieldset {
    border: 1px solid ${Gray400};
  }
`

interface Props {
  languages: LanguageResult[]
  name: string
  value: LanguageResult | null
  handleChange(event: React.ChangeEvent<{ value: LanguageResult | null }>): void
  hideAccordion?: boolean
}

const LanguageSelect: React.FC<React.PropsWithChildren<Props>> = ({
  languages,
  name,
  value,
  handleChange,
  hideAccordion,
}) => {
  const { t } = useTranslation()
  const options = [
    {
      value: t('common.select'),
      label: t('common.select'),
    },
    ...languages.map((language) => ({
      value: language.id.toString(),
      label: language.language,
    })),
  ]

  return (
    <Fieldset
      labelId={name}
      legend={t('component.filterOptions.languageSelect.legend')}
      chip={
        value ? (
          <>
            <VisuallyHidden as="span">, </VisuallyHidden>
            <span>1</span>
            <VisuallyHidden as="span">{t('component.filterOptions.aria.selection')}</VisuallyHidden>
          </>
        ) : undefined
      }
      hideAccordion={hideAccordion}
    >
      <StyledSelect
        labelId={name}
        value={value?.id.toString() ?? t('common.select')}
        onChange={(event) => {
          const selectedId = event.target.value as unknown as string
          const selection =
            languages.find((language) => language.id.toString() === selectedId) ?? null
          handleChange({ target: { name, value: selection } } as React.ChangeEvent<{
            name: string
            value: LanguageResult | null
          }>)
        }}
        fullWidth
        placeholder={t('component.filterOptions.languageSelect.label')}
        style={{ marginBottom: 0 }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </StyledSelect>
    </Fieldset>
  )
}

export default LanguageSelect
