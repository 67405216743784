import Cookies from 'js-cookie'
import { atom } from 'recoil'

import { isJwtValid } from '../../common/utils/utils'
import { jwtKey, mehiAvCookie } from '../../constants'

export const teliaJWTAtom = atom<string | null>({
  key: 'user-teliaJWTAtom',
  default: null,
})

export type LoginStatus = 'authenticated' | 'impersonated' | 'external' | undefined

const getInitialLoginStatus = (): LoginStatus => {
  const hasJwt =
    Boolean(sessionStorage.getItem(jwtKey)) && isJwtValid(sessionStorage.getItem(jwtKey))
  const hasImpersonationJwt =
    hasJwt &&
    !sessionStorage.getItem(jwtKey)?.startsWith('Test') &&
    !sessionStorage.getItem(jwtKey)?.startsWith('External') &&
    !sessionStorage.getItem(jwtKey)?.startsWith('Partner')
  const hasTestJwt = hasJwt && sessionStorage.getItem(jwtKey)?.startsWith('Test')
  const hasExtJwt =
    hasJwt &&
    (sessionStorage.getItem(jwtKey)?.startsWith('External') ||
      sessionStorage.getItem(jwtKey)?.startsWith('Partner'))
  const hasCookieAuth = Cookies.get(mehiAvCookie)

  if (hasCookieAuth || hasTestJwt) {
    return 'authenticated'
  } else if (hasImpersonationJwt) {
    return 'impersonated'
  } else if (hasExtJwt) {
    return 'external'
  }

  return undefined
}

export const loginStatusAtom = atom<LoginStatus>({
  key: 'user-isLoggedInAtom',
  default: getInitialLoginStatus(),
})

export type OHCAllowance = 'allowed' | 'disallowed' | 'online_reservation_denied'
export const ohcAllowedStatusAtom = atom<OHCAllowance | null>({
  key: 'user-ohcAllowedStatusAtom',
  default: null,
})

export const reservationConfirmationEmailAtom = atom<string | null>({
  key: 'user-reservationConfirmationEmailAtom',
  default: null,
})

export const reservationConfirmationContactByPhoneAtom = atom<boolean | undefined>({
  key: 'user-reservationConfirmationContactByPhoneAtom',
  default: undefined,
})
