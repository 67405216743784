import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { SupportedLanguage } from '../../__generated__/api'
import dayjs from '../utils/dayjs/dayjs'

type ChangeLanguageContextType = {
  currentLanguage: SupportedLanguage
  changeLanguage(lang: SupportedLanguage): void
  changeNamespace(namespace: string): void
}

const ChangeLanguageContext = createContext<ChangeLanguageContextType>({
  currentLanguage: SupportedLanguage.Fi,
  changeLanguage: () => {
    /* Placeholder */
  },
  changeNamespace: () => {
    /* Placeholder */
  },
})

export const ChangeLanguageProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const { i18n } = useTranslation()

  const changeLanguage = useCallback(
    (lang: SupportedLanguage) => {
      if (lang !== i18n.language) {
        i18n.changeLanguage(lang)
      }
    },
    [i18n]
  )

  const changeNamespace = useCallback(
    (namespace: string) => {
      i18n.setDefaultNamespace(namespace)
    },
    [i18n]
  )

  useEffect(() => {
    dayjs.locale(i18n.language)
    document.documentElement.setAttribute('lang', i18n.language)
  }, [i18n.language])

  const memoizedValue = useMemo(
    () => ({
      currentLanguage: i18n.language as SupportedLanguage,
      changeLanguage,
      changeNamespace,
    }),
    [i18n.language, changeLanguage, changeNamespace]
  )

  return (
    <ChangeLanguageContext.Provider value={memoizedValue}>
      {children}
    </ChangeLanguageContext.Provider>
  )
}

const useChangeLanguage = () => {
  return useContext(ChangeLanguageContext)
}

export default useChangeLanguage
