import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { CSSProperties } from 'react'

export const Flex = styled.div<{
  $gap?: CSSProperties['gap']
  $justifyContent?: CSSProperties['justifyContent']
  $alignItems?: CSSProperties['alignItems']
}>`
  display: flex;
  ${(props) =>
    props.$gap &&
    css`
      gap: ${props.$gap};
    `}
  ${(props) =>
    props.$justifyContent &&
    css`
      justify-content: ${props.$justifyContent};
    `}
  ${(props) =>
    props.$alignItems &&
    css`
      align-items: ${props.$alignItems};
    `}
`

export const RowFlex = styled(Flex)`
  flex-direction: row;
`

export const ColumnFlex = styled(Flex)`
  flex-direction: column;
`

export const CenteredRowFlex = styled(RowFlex)`
  align-items: center;
`

export const CenteredColumnFlex = styled(ColumnFlex)`
  align-items: center;
`

export const GappedRowFlex = styled(RowFlex)<{ gap?: string }>`
  gap: ${(props) => props.gap ?? '16px'};
`

export const MinMaxDiv = styled.div<{ size?: string; gap?: string }>`
  display: grid;
  grid-template-columns: minmax(auto, ${(props) => (props.size ? props.size : '950px')});
  grid-row-gap: ${(props) => (props.gap ? props.gap : '20px')};
  justify-content: center;
`

/**
 * A visually hidden element,
 * i.e. the element appears in the DOM for screen readers,
 * but does not affect the visual appearance of the site.
 */
export const VisuallyHidden = styled.div`
  position: absolute;
  top: 0;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  border: 0;
`
