import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'
import { useRecoilValue } from 'recoil'

import { isFromRelayAppAtom } from '../../../../state/common/atoms'
import useMobileClose from '../../../hooks/useMobileClose'
import useTranslation from '../../../hooks/useTranslation'
import { scale } from '../../../utils/scale'
import { CenteredRowFlex } from '../../Layout/Layout'

import BackButton from './BackButton'
import CloseButton from './CloseButton'

const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding-top: 16px;
  padding-right: 16px;

  > ${CloseButton} {
    position: fixed;
    z-index: 1;
  }
`

const Container = styled(CenteredRowFlex)<{ staticHeader: boolean; noHeading: boolean }>`
  position: absolute;
  width: 100%;
  height: 0%;
  top: 0;
  left: 0;

  ${({ staticHeader, noHeading }) =>
    staticHeader &&
    css`
      position: relative;
      display: grid;
      grid-template-columns: ${noHeading ? '4fr 1fr' : '1fr 1fr 1fr'};
      margin-bottom: ${scale(2)};
      column-gap: ${scale(2)};
      height: ${scale(5.5)};

      ${CloseButtonContainer} {
        padding: 0;
      }
    `}
`

const Heading = styled.span`
  justify-self: center;
`

const Empty = styled.div`
  height: 39px;
`

export interface Props {
  open: boolean
  heading?: string
  onBack?(): void
  onClose?(): void
  enableMobileClose?: boolean
  backLabel?: string
}

const Header: React.FC<React.PropsWithChildren<Props>> = ({
  open,
  heading,
  onBack,
  onClose,
  enableMobileClose,
  backLabel,
}) => {
  const { t } = useTranslation()
  const isFromRelayApp = useRecoilValue(isFromRelayAppAtom)
  const useStaticHeader = !isFromRelayApp && (Boolean(heading) || Boolean(onBack))

  useMobileClose({
    enabled: Boolean(enableMobileClose),
    onClose: onClose,
    onBack: onBack,
    open,
  })

  return (
    <Container staticHeader={useStaticHeader} noHeading={!Boolean(heading)}>
      {!isFromRelayApp && onBack ? <BackButton onBack={onBack} label={backLabel} /> : <Empty />}
      {heading ? <Heading>{heading}</Heading> : null}
      {!isFromRelayApp && onClose ? (
        <CloseButtonContainer>
          <CloseButton onClose={onClose} label={t('common.close')} />
        </CloseButtonContainer>
      ) : (
        <Empty />
      )}
    </Container>
  )
}

export default Header
