import { PublicNode } from '../../__generated__/api'

export const flattenNodes = (nodes: PublicNode[]): PublicNode[] =>
  nodes.reduce((acc: PublicNode[], node: PublicNode) => {
    if (node.children) {
      const childrenResults = flattenNodes(node.children)
      childrenResults.forEach((childNode) => acc.push(childNode))
    }
    acc.push(node)
    return acc
  }, [])
