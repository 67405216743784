import styled from '@emotion/styled'
import dayjs, { Dayjs } from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'

import { isFromAppAtom } from '../../../state/common/atoms'
import { scale } from '../../utils/scale'
import { DefaultButton } from '../DefaultButton/DefaultButton'
import Modal from '../Modal/Modal'

import DatePickerCalendar, { CalendarAvailability } from './DatePickerCalendar'

const Container = styled.div<{ isFromApp?: boolean }>`
  padding-top: ${(props) => (props.isFromApp ? 0 : scale(3))};
  display: flex;
  flex-direction: column;
  align-items: center;

  > ${DefaultButton} {
    margin-top: ${scale(2)};
  }
`
interface Props {
  open: boolean
  selectedDate: Dayjs
  setSelectedDate(date: Dayjs): void
  calendarDataFetcher(date: Dayjs): Promise<CalendarAvailability[]>
  handleClose(): void
}

const DateModal: React.FC<React.PropsWithChildren<Props>> = ({
  open,
  handleClose,
  selectedDate,
  setSelectedDate,
  calendarDataFetcher,
}) => {
  const { t } = useTranslation()
  const isFromApp = useRecoilValue(isFromAppAtom)

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-label={t('component.dateSelect.placeholder')}
      fullWidth
      maxWidth="md"
    >
      <Container data-cy="dateModal" isFromApp={isFromApp}>
        <DatePickerCalendar
          calendarDataFetcher={calendarDataFetcher}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          onDateSelect={() => handleClose()}
        />
        <DefaultButton
          onClick={() => {
            setSelectedDate(dayjs())
            handleClose()
          }}
          data-cy="dateModal-todayButton"
        >
          {t('common.time.today')}
        </DefaultButton>
      </Container>
    </Modal>
  )
}

export default DateModal
