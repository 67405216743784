import styled from '@emotion/styled'
import { CircleInfo } from '@mehilainen/mds-customer/icons'
import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { PractitionerDetails } from '../../../__generated__/api'
import { scale } from '../../utils/scale'
import BlueNote from '../BlueNote/BlueNote'
import { CenteredRowFlex } from '../Layout/Layout'
import Modal from '../Modal/Modal'
import PractitionerHeader from '../PractitionerDetails/PractitionerHeader'
import { Text } from '../Typography/Typography'

const StyledBlueNote = styled(BlueNote)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const HeadingContainer = styled(CenteredRowFlex)`
  svg {
    margin-right: ${scale(2)};
  }
`

const StyledUl = styled.ul`
  margin-top: ${scale(1)};
  list-style: disc inside;
`

interface Props {
  practitionerDetails?: PractitionerDetails | null
}

const PractitionerRestrictions: React.FC<React.PropsWithChildren<Props>> = ({
  practitionerDetails,
}) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)

  if (!practitionerDetails || practitionerDetails.patientRestrictions.length === 0) {
    return null
  }

  return (
    <>
      <StyledBlueNote>
        <HeadingContainer data-cy="practitionerRestrictions-heading">
          <CircleInfo />
          <Text $size={300} $height="Large" $weight="Regular" aria-live="polite" aria-atomic="true">
            {t('component.practitionerRestrictions.heading')}
          </Text>
        </HeadingContainer>
        <Button
          size="small"
          variant="outlined"
          onClick={() => setOpen(true)}
          data-cy="practitionerRestrictions-modalButton"
        >
          {t('component.practitionerRestrictions.buttonText')}
        </Button>
      </StyledBlueNote>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        primaryButtonText={t('common.close')}
        onPrimaryButtonClick={() => setOpen(false)}
      >
        <PractitionerHeader
          practitioner={practitionerDetails}
          showDisplayAppointmentsButton={false}
        />
        <Text as="h2" $size={400} $weight="Regular" $height="Medium">
          {t('component.practitionerRestrictions.modalHeading')}
        </Text>
        <StyledUl data-cy="practitionerRestrictions-restrictionsList">
          {practitionerDetails.patientRestrictions.map((restriction, index) => (
            <li key={`${restriction.id}-${index}`}>
              <Text $size={300} $height="Large" $weight="Regular">
                {restriction.patientRestriction}
              </Text>
            </li>
          ))}
        </StyledUl>
      </Modal>
    </>
  )
}

export default PractitionerRestrictions
