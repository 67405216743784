import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  Error100,
  Error500,
  Error800,
  Gray900,
  Gray800,
} from '@mehilainen/design-system-tokens/colors'
import { CircleTimes } from '@mehilainen/mds-customer/icons'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Error200 } from '../../../constants'
import { getCustomerServiceNumber } from '../../services/customerService'
import { Text } from '../Typography/Typography'

const Container = styled.div<{ variant: Props['variant'] }>`
  display: flex;
  gap: 16px;

  ${({ variant }) =>
    variant === 'full' &&
    css`
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 16px;
    `}

  ${({ variant }) =>
    variant === 'nested' &&
    css`
      background-color: ${Error100};
      border: 1px solid ${Error200};
      border-radius: 8px;
      padding: 12px;

      a:link {
        color: ${Error800};
      }
    `}
`

const TextContainer = styled.div<{ variant: Props['variant'] }>`
  ${({ variant }) =>
    variant === 'full' &&
    css`
      display: flex;
      flex-direction: column;
      gap: 16px;
    `}
`

const NestedHeaderParams = {
  as: 'h2',
  $height: 'Large',
  $weight: 'Medium',
  $size: 300,
  $color: Error800,
} as const

const FullHeaderParams = {
  as: 'h1',
  $height: 'Medium',
  $weight: 'Medium',
  $size: 500,
  $color: Gray900,
} as const

interface Props {
  variant: 'nested' | 'full'
  displayCancellation?: boolean
}

export const DentalMaintenance: React.VFC<Props> = ({ variant, displayCancellation }) => {
  const { t, i18n } = useTranslation()
  const link = getCustomerServiceNumber('general', i18n.language)
  const { label: telNumber } = link

  return (
    <Container aria-live="polite" aria-atomic="true" variant={variant}>
      <CircleTimes sx={{ color: Error500 }} fontSize={variant === 'nested' ? 'medium' : 'large'} />
      <TextContainer variant={variant}>
        <Text {...(variant === 'nested' ? NestedHeaderParams : FullHeaderParams)}>
          {t(
            `component.dentalMaintenance.${displayCancellation ? 'cancellationHeading' : 'heading'}`
          )}
        </Text>
        <Text
          as="p"
          $height="Large"
          $weight="Regular"
          $size={300}
          $color={variant === 'nested' ? Error800 : Gray800}
        >
          {t('component.dentalMaintenance.subHeading')}
        </Text>
        <Text
          as="p"
          $height="Large"
          $weight="Regular"
          $size={300}
          $color={variant === 'nested' ? Error800 : Gray800}
        >
          <Trans i18nKey={'component.dentalMaintenance.footnote'} values={{ telNumber }}>
            <Text as="a" $height="Large" $weight="Medium" $size={300} href={link.link} />
          </Trans>
        </Text>
      </TextContainer>
    </Container>
  )
}
