import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from './translations/en.json'
import fi from './translations/fi.json'
import sv from './translations/sv.json'

export const translationData = { en, fi, sv }

export enum ValidLanguage {
  FI = 'fi',
  EN = 'en',
  SV = 'sv',
}

export const getLanguage = (): ValidLanguage => {
  return i18n.language as ValidLanguage
}

i18n.use(initReactI18next).init({
  resources: {
    fi: {
      translation: fi,
    },
    sv: {
      translation: sv,
    },
    en: {
      translation: en,
    },
  },
  lng: 'fi',
  fallbackLng: 'fi',
  interpolation: {
    escapeValue: false,
  },
  react: {
    bindI18nStore: 'added',
  },
})

export default i18n
