import { FlexibleScheduleOption } from '../../__generated__/api'
import { AppointmentLengthOption } from '../../common/components/AppointmentLengthSelect/AppointmentLengthSelect'
import { flatten } from '../../common/utils/utils'

export const GetReqularWorkListOptions = (
  options: AppointmentLengthOption[],
  availableForSelectedAppointment: AppointmentLengthOption[],
  defaultLength: number
) => {
  const defaultItem = {
    duration: 'default',
    description: [],
    available: true,
  } as AppointmentLengthOption

  const filteredOptions = [defaultItem, ...options].filter(
    (option) => option.duration !== defaultLength
  )
  return getOptionsWithAvailability(filteredOptions, availableForSelectedAppointment)
}

export const GetFlexibleWorklistLengthOptions = (
  flexibleScheduleOptions: FlexibleScheduleOption[],
  serviceTerms: string[],
  availableForSelectedAppointment: AppointmentLengthOption[],
  locations: string[]
) => {
  const locationFilteredOptions = flexibleScheduleOptions.filter((i) =>
    locations.includes(i.locationDHId)
  )
  const options = getFlexibleOptions(locationFilteredOptions, serviceTerms)
  const filteredAvailableForSelectedAppointment = availableForSelectedAppointment.filter(
    (option) => (serviceTerms.length > 0 ? serviceTerms.includes(String(option.serviceId)) : true)
  )
  return combineDuplicateOptions(
    getOptionsWithAvailability(options, filteredAvailableForSelectedAppointment)
  ).sort((a, b) => (a.duration as number) - (b.duration as number))
}

const getOptionsWithAvailability = (
  options: AppointmentLengthOption[],
  availableForSelectedAppointment: AppointmentLengthOption[]
): AppointmentLengthOption[] => {
  const listOfOptions = flatten([
    options.map(
      (option) =>
        ({
          duration: option.duration,
          description: option.description,
          serviceName: option.serviceName,
          serviceId: option.serviceId,

          available:
            option.available ||
            availableForSelectedAppointment
              .map((opt) => opt.duration)
              .includes(option.duration as number),
        }) as AppointmentLengthOption
    ),
  ])
  return listOfOptions
}

const getFlexibleOptions = (
  flexibleScheduleOptions: FlexibleScheduleOption[],
  serviceTerms: string[]
) => {
  const practitionerOptions = flatten(
    flexibleScheduleOptions
      .filter((i) => (serviceTerms.length > 0 ? serviceTerms.includes(String(i.serviceId)) : true))
      .map((i) => {
        return i.availableDurations.map((duration) => {
          return {
            duration,
            description: [],
            serviceName: i.serviceName,
            serviceId: i.serviceId,
          }
        })
      })
  )
  return practitionerOptions
}

const combineDuplicateOptions = (options: AppointmentLengthOption[]): AppointmentLengthOption[] => {
  return options.reduce<AppointmentLengthOption[]>((acc, curr) => {
    if (
      acc.find((option) => option.duration === curr.duration && option.serviceId === curr.serviceId)
    ) {
      return acc
    }
    acc.push(curr)
    return acc
  }, [])
}
