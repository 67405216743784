import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Gray100 } from '@mehilainen/design-system-tokens/colors'
import React from 'react'

import { scale } from '../../../utils/scale'
import { DefaultButton } from '../../DefaultButton/DefaultButton'
import { ColumnFlex } from '../../Layout/Layout'
import { Text } from '../../Typography/Typography'

const Container = styled(ColumnFlex)<{ sticky?: boolean }>`
  margin-top: ${scale(3)};
  gap: ${scale(1.5)};
  align-items: center;
  ${(props) =>
    props.sticky &&
    css`
      position: sticky;
      z-index: 1;
      background: ${Gray100};
      bottom: -24px;
      margin-left: -24px;
      margin-right: -24px;
      margin-bottom: -24px;
      padding: 24px;
      flex-direction: row-reverse;

      .MuiButton-root {
        flex: 1;
        height: 56px;
      }
    `};
`

export interface Props {
  primaryButtonText?: string
  secondaryButtonText?: string
  footerText?: string
  onPrimaryButtonClick?(): void
  onSecondaryButtonClick?(): void
  stickyFooter?: boolean
  primaryButtonDisabled?: boolean
  primaryButtonDaPath?: string
  secondaryButtonDaPath?: string
  primaryButtonCy?: string
}

const ModalFooter: React.FC<React.PropsWithChildren<Props>> = ({
  primaryButtonText,
  secondaryButtonText,
  footerText,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  stickyFooter,
  primaryButtonDisabled,
  primaryButtonDaPath,
  secondaryButtonDaPath,
  primaryButtonCy,
}) => {
  return (
    <Container sticky={stickyFooter}>
      {primaryButtonText && onPrimaryButtonClick && (
        <DefaultButton
          onClick={onPrimaryButtonClick}
          disabled={primaryButtonDisabled}
          data-cy={primaryButtonCy ?? 'modalFooter-primaryButton'}
          data-analytics-path={primaryButtonDaPath}
        >
          <Text $size={400} $weight="Medium" $height="Small">
            {primaryButtonText}
          </Text>
        </DefaultButton>
      )}
      {secondaryButtonText && onSecondaryButtonClick && (
        <DefaultButton
          variant="text"
          onClick={onSecondaryButtonClick}
          data-cy="modalFooter-secondaryButton"
          data-analytics-path={secondaryButtonDaPath}
        >
          <Text $size={400} $weight="Medium" $height="Small">
            {secondaryButtonText}
          </Text>
        </DefaultButton>
      )}
      {footerText && (
        <Text $size={200} $weight="Regular" $height="Large">
          {footerText}
        </Text>
      )}
    </Container>
  )
}

export default ModalFooter
