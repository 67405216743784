import {
  OccupationalAccidentInformation,
  TrafficAccidentInformation,
} from '../../../__generated__/api'
import { LahiTapiolaInformation } from '../components/DirectCompensationInsurance/LahiTapiolaForm'
import { InsuranceAdditionalDetailsType } from '../components/InsuranceAdditionalDetails'
import { OccupationalAccidentInformation as OccupationalAccidentInformationFormType } from '../components/OccupationalAccidentForm'
import { TrafficAccidentInformation as TrafficAccidentInformationFormType } from '../components/TrafficAccidentForm'

export const isOccupationalAccidentInformation = (
  details?: InsuranceAdditionalDetailsType
): details is OccupationalAccidentInformationFormType => {
  return (details as OccupationalAccidentInformationFormType)?.employer !== undefined
}

export const isTrafficAccidentInformation = (
  details?: InsuranceAdditionalDetailsType
): details is TrafficAccidentInformationFormType => {
  return (details as TrafficAccidentInformationFormType)?.vehicleRegistrationNumber !== undefined
}

export const isLahiTapiolaInformation = (
  details?: InsuranceAdditionalDetailsType
): details is LahiTapiolaInformation => {
  return (details as LahiTapiolaInformation)?.permitId !== undefined
}

export const mapOccupationalAccidentInformation = (
  details: OccupationalAccidentInformationFormType
): OccupationalAccidentInformation => {
  return {
    incidentDate: details.incidentDate ? details.incidentDate.toJSON() : new Date().toJSON(),
    incidentDescription: details.incidentDescription,
    employer: details.employer,
  }
}

export const mapTrafficAccidentInformation = (
  details: TrafficAccidentInformationFormType
): TrafficAccidentInformation => {
  return {
    incidentDate: details.incidentDate ? details.incidentDate.toJSON() : new Date().toJSON(),
    incidentDescription: details.incidentDescription,
    vehicleRegistrationNumber: details.vehicleRegistrationNumber,
  }
}
