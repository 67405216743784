import { LocationAutocompleteResult } from '../../../__generated__/api'
import i18n from '../../i18n/i18n'
import api from '../api'

import AVSearch from './AVSearch'

export class LocationSearch extends AVSearch<LocationAutocompleteResult> {
  isOhc = false
  nodeId = ''
  setIsOhc(isOhc: boolean) {
    this.isOhc = isOhc
  }
  setNodeId(nodeId: string) {
    this.nodeId = nodeId
  }
  search(searchInput: string, limit: number): Promise<LocationAutocompleteResult[]> {
    return api.v1
      .autocompleteLocation({
        searchString: searchInput,
        limit,
        lang: i18n.language,
        ohc: this.isOhc,
        nodeId: this.nodeId,
      })
      .then((res) => res.data)
  }
}
