import styled from '@emotion/styled'
import { Primary500 } from '@mehilainen/design-system-tokens/colors'
import { Button } from '@mui/material'
import type React from 'react'

import {
  NotificationLinkType,
  NotificationStyle,
  SimpleNotification,
} from '../../../__generated__/api'
import { ColumnFlex } from '../../../common/components/Layout/Layout'
import Notification, {
  mapNotificationStyles,
} from '../../../common/components/Notification/Notification'
import { Text } from '../../../common/components/Typography/Typography'
import { useIsMobile } from '../../../common/hooks/useBreakpoint'
import { locationParamKey } from '../hooks/useSearchHistory'

interface Props {
  notification: SimpleNotification
  replaceLocation: string[]
}

const ExternalIcon = styled.div<{ size: string; url: string }>`
  min-width: ${({ size }) => size};
  min-height: ${({ size }) => size};
  background: url(${({ url }) => url}) no-repeat 50% 50%;
  background-size: cover;
`

const ContentContainer = styled(ColumnFlex)`
  gap: 2px;
  flex: 1;
  justify-content: space-between;

  a {
    text-decoration: none;
  }

  ${Text} {
    a {
      color: inherit;
    }
  }
`

const NotificationBox: React.FC<React.PropsWithChildren<Props>> = ({
  notification,
  replaceLocation,
}) => {
  const isMobile = useIsMobile()
  const hasInlineLink =
    notification.link &&
    notification.linkText &&
    notification.linkType === NotificationLinkType.InlineLink
  const hasButtonLink =
    notification.link &&
    notification.linkText &&
    notification.linkType === NotificationLinkType.Button

  return (
    <Notification
      style={notification.style ?? NotificationStyle.Info}
      icon={
        notification.icon &&
        !isMobile && (
          <ExternalIcon
            role="presentation"
            url={`${process.env.REACT_APP_NODE_ICON_URL}${notification.icon}.svg`}
            size="24px"
          />
        )
      }
      action={
        hasButtonLink &&
        notification.link && (
          <Button
            onClick={() => {
              notification.isInternalLink
                ? (window.location.href = replaceLink(notification.link!, replaceLocation))
                : window.open(replaceLink(notification.link!, replaceLocation), '_blank')
            }}
            size="small"
            variant="outlined"
            color={mapNotificationStyles(notification.style ?? NotificationStyle.Info)}
            sx={{ whiteSpace: 'nowrap' }}
          >
            {notification.linkText}
          </Button>
        )
      }
      title={notification.title ?? undefined}
    >
      <ContentContainer>
        {notification.content && (
          <Text $size={300} $height="Large">
            {notification.content}
            {hasInlineLink && notification.link && (
              <Text
                as="a"
                $size={300}
                $height="Large"
                href={replaceLink(notification.link, replaceLocation)}
                target={notification.isInternalLink ? '_self' : '_blank'}
              >
                {' '}
                {notification.linkText}
              </Text>
            )}
          </Text>
        )}
        {notification.linkList?.length && (
          <>
            {notification.linkList.map((link, idx) => (
              <Text
                as="a"
                key={`link-${link}-${idx}`}
                href={link.link}
                target="_blank"
                $size={300}
                $height="Large"
                $weight="Medium"
                $color={Primary500}
              >
                {link.linkText}
              </Text>
            ))}
          </>
        )}
      </ContentContainer>
    </Notification>
  )
}

export function replaceLink(link: string, location: string[]): string {
  const url = new URL(link)
  if (url.searchParams.has(`${locationParamKey}[0]`)) {
  } else {
    location.forEach((value, index) => {
      url.searchParams.set(`${locationParamKey}[${index}]`, value)
    })
  }
  return url.toString()
}

export default NotificationBox
