import { useRecoilValue } from 'recoil'

import {
  selectedAppointmentNodeIdAtom,
  selectedAppointmentServiceIdAtom,
} from '../../state/common/atoms'
import { getAppointmentTitle } from '../utils/text'

import { useNode } from './useNode'
import { useServiceName } from './useServiceName'

export const useAppointmentTitle = (): string | undefined => {
  const selectedAppointmentNodeId = useRecoilValue(selectedAppointmentNodeIdAtom)
  const selectedAppointmentServiceId = useRecoilValue(selectedAppointmentServiceIdAtom)

  const { node } = useNode(selectedAppointmentNodeId)
  const { name: serviceName } = useServiceName(selectedAppointmentServiceId)

  return getAppointmentTitle(node, serviceName)
}
