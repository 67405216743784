import equal from 'fast-deep-equal'
import { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import { AppointmentType } from '../../__generated__/api'
import {
  allLocationsSelection,
  defaultSelectedLocation,
  selectedAppointmentTypesAtom,
  userSelectedAppointmentTypesAtom,
  selectedNodeLocationAtom,
  searchTargetRestoredAtom,
} from '../../state/search/atoms'
import { getSelectedNodeOrDefault } from '../../state/search/selectors'

import { useNode } from './useNode'

export const useNodeSideEffects = () => {
  const selectedNodeId = useRecoilValue(getSelectedNodeOrDefault)
  const { node: selectedNode } = useNode(selectedNodeId)
  const setSelectedAppointmentTypes = useSetRecoilState(selectedAppointmentTypesAtom)
  const setUserSelectedAppointmentTypes = useSetRecoilState(userSelectedAppointmentTypesAtom)
  const [selectedLocation, setSelectedLocation] = useRecoilState(selectedNodeLocationAtom)
  const searchTargetRestored = useRecoilValue(searchTargetRestoredAtom)

  const [nodeRestored, setNodeRestored] = useState(false)

  useEffect(() => {
    // 'setNodeRestored' tracks whether the node has been restored from the URL
    if (searchTargetRestored && selectedNodeId === selectedNode?.id) {
      setNodeRestored(true)
    }
  }, [selectedNodeId, selectedNode, searchTargetRestored])

  useEffect(() => {
    if (nodeRestored) {
      // Previous search was a node without remote support
      if (
        selectedNode &&
        selectedNodeId !== selectedNode.id &&
        !selectedNode.video &&
        !selectedNode.phone
      ) {
        setSelectedAppointmentTypes([])
      } else if (selectedNode && selectedNodeId === selectedNode.id) {
        setUserSelectedAppointmentTypes([])
        const allowedTypes = [AppointmentType.Clinic]
        if (selectedNode.video) {
          allowedTypes.push(AppointmentType.Video)
        }
        if (selectedNode.phone) {
          allowedTypes.push(AppointmentType.Phone)
        }
        if (allowedTypes.length === 3) {
          setSelectedAppointmentTypes([])
        } else {
          setSelectedAppointmentTypes(allowedTypes)
        }
        if (
          allowedTypes.length === 1 &&
          allowedTypes[0] === AppointmentType.Clinic &&
          equal(selectedLocation, allLocationsSelection)
        ) {
          // Reset location if remote location is set
          setSelectedLocation(defaultSelectedLocation)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // We don't want 'nodeRestored' here as we don't want
    // the effect to run the exact time when it changes from false to true
    selectedNodeId,
    selectedNode,
    setSelectedAppointmentTypes,
    setUserSelectedAppointmentTypes,
    setSelectedLocation,
    selectedLocation,
  ])
}
