import styled from '@emotion/styled'
import { Black } from '@mehilainen/design-system-tokens/colors'
import { AngleBottom } from '@mehilainen/mds-customer/icons'
import React, { useRef, useState } from 'react'
import { useSize } from 'react-hook-size'

import { ColumnFlex } from '../Layout/Layout'

const Container = styled(ColumnFlex)``

const ContentContainer = styled.div<{
  height: number
  collapsed: boolean
  isCollapsible: boolean
}>`
  max-height: ${(props) => `${props.height}px`};
  overflow: hidden;
  mask-image: ${(props) =>
    props.isCollapsible && props.collapsed
      ? `linear-gradient(to top, transparent 0%, ${Black} 30%)`
      : 'none'};
  transition: max-height 0.25s ease-out;
`

const CollapseButton = styled.button<{ collapsed: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  border: none;
  background-color: transparent;
  color: inherit;
  font-size: 0.938rem;
  padding: 0;
  cursor: pointer;

  .MuiSvgIcon-root {
    margin-left: 10px;
    color: inherit;
    transform: ${(props) => (props.collapsed ? 'rotate(0deg)' : 'rotate(180deg)')};
    transition: transform 0.25s ease-out;
  }
`

interface Props {
  maxHeight: number
  moreLabel: string
  lessLabel: string
  open?: boolean
  className?: string
}

const ToggleArea: React.FC<React.PropsWithChildren<Props>> = ({
  maxHeight,
  open = false,
  moreLabel,
  lessLabel,
  children,
  className,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const { height: contentHeight } = useSize(ref)
  const [collapsed, setCollapsed] = useState<boolean>(!open)

  const isCollapsible = Boolean(contentHeight && contentHeight > maxHeight)

  return (
    <Container className={className}>
      <ContentContainer
        height={collapsed ? maxHeight : contentHeight ?? 0}
        collapsed={collapsed}
        isCollapsible={isCollapsible}
      >
        <div ref={ref}>{children}</div>
      </ContentContainer>
      {isCollapsible && (
        <div>
          <CollapseButton onClick={() => setCollapsed(!collapsed)} collapsed={collapsed}>
            {collapsed ? moreLabel : lessLabel}
            <AngleBottom />
          </CollapseButton>
        </div>
      )}
    </Container>
  )
}

export default ToggleArea
