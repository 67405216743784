import useBreakpoint from 'use-breakpoint'

import { breakpoint } from '../utils/breakpoint'

// In CSS the breakpoint definitions are used eg. 'max-width: ${breakpoint.tablet}px' which translates to '<= ${breakpoint.tablet}px'
// but the use-breakpoint library uses '<' so we need to add 1px to the breakpoint values
const config = {
  xs: 0,
  sm: breakpoint.sm + 1,
  md: breakpoint.md + 1,
  lg: breakpoint.lg + 1,
  xl: breakpoint.xl + 1,
}

export const useIsMobile = (): boolean => {
  const bp = useBreakpoint(config)
  return bp.breakpoint === 'xs'
}

export const useIsTablet = (): boolean => {
  const bp = useBreakpoint(config)
  return bp.breakpoint === 'md'
}

export const useIsDesktop = (): boolean => {
  const bp = useBreakpoint(config)
  return ['lg', 'xl'].includes(bp.breakpoint ?? '')
}

export const useIsMaxWidth = (): boolean => {
  const bp = useBreakpoint(config)
  return bp.breakpoint === 'xl'
}

export default useBreakpoint
