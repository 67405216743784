// Implementation copied from https://paja.mehilainen.local/sovelluskehitys/design-system/-/blob/master/src/utils/scale.ts

const createScale =
  (base: number) =>
  (factor = 0) =>
    (base * factor).toString() + 'px'

const major = createScale(8)
const minor = createScale(4)

// Use major as default scale()
// also assign named scale.major() and scale.minor()
export const scale = Object.assign(major, {
  major,
  minor,
})
