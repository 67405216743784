import { useTranslation } from 'react-i18next'

import { SupportedLanguage, Modal } from '../../__generated__/api'
import api from '../services/api'

import { useApi } from './useApi'
import { useOHC } from './useOHC'

export const useModals = (): {
  modals: Modal[]
  getModal: (id: string) => Modal | undefined
} => {
  const { i18n } = useTranslation()
  const { isOHCSide, ohcAllowedStatus } = useOHC()

  const { data: modals } = useApi(
    api.v1.getModals,
    {
      lang: i18n.language as SupportedLanguage,
      isOHC: isOHCSide,
    },
    [],
    (isOHCSide && ohcAllowedStatus !== null && ohcAllowedStatus !== 'disallowed') || !isOHCSide
  )
  const getModal = (id: string): Modal | undefined => {
    return modals.find((modal: Modal) => modal.id === id)
  }

  return { modals, getModal }
}
