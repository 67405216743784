import styled from '@emotion/styled'
import { Calendar, Times } from '@mehilainen/mds-customer/icons'
import equal from 'fast-deep-equal'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import Chip from '../../../common/components/Chip/Chip'
import { Gender } from '../../../common/components/FilterOptions/types'
import { RowFlex } from '../../../common/components/Layout/Layout'
import { useIsDesktop, useIsTablet } from '../../../common/hooks/useBreakpoint'
import { capitalizeFirstLetter } from '../../../common/utils/text'
import {
  defaultSelectedDurations,
  defaultSelectedTimeRanges,
  filterOptionsOpenAtom,
  selectedDateAtom,
  selectedAppointmentTypesAtom,
  selectedDurationsAtom,
  selectedGenderAtom,
  selectedLanguageAtom,
  selectedPatientAgeGroupAtom,
  selectedTimeRangesAtom,
  isUserSelectedDateAtom,
  userSelectedAppointmentTypesAtom,
} from '../../../state/search/atoms'
import SearchFilterInsuranceChip from '../components/SearchFilterInsuranceChip'
import useSearchCalendar from '../hooks/useSearchCalendar'

import filterIcon from './img/filter.svg'

const ChipsContainer = styled(RowFlex)`
  gap: 10px;
  flex-wrap: wrap;
`

interface Props {
  hideAddFiltersButton?: boolean
  hideInsuranceButton?: boolean
}

const SearchFilterChips: React.FC<React.PropsWithChildren<Props>> = ({
  hideAddFiltersButton,
  hideInsuranceButton,
}) => {
  const { t } = useTranslation()
  const isDesktop = useIsDesktop()
  const isTablet = useIsTablet()
  const { setCalendarModalOpen } = useSearchCalendar()

  const [selectedTimeRanges, setSelectedTimeRanges] = useRecoilState(selectedTimeRangesAtom)
  const [selectedAppointmentTypes, setSelectedAppointmentTypes] = useRecoilState(
    selectedAppointmentTypesAtom
  )
  const [userSelectedAppointmentTypes, setUserSelectedAppointmentTypes] = useRecoilState(
    userSelectedAppointmentTypesAtom
  )
  const [selectedLanguage, setSelectedLanguage] = useRecoilState(selectedLanguageAtom)
  const [selectedGender, setSelectedGender] = useRecoilState(selectedGenderAtom)
  const [selectedPatientAgeGroup, setSelectedPatientAgeGroup] = useRecoilState(
    selectedPatientAgeGroupAtom
  )
  const [selectedDurations, setSelectedDurations] = useRecoilState(selectedDurationsAtom)

  const setFilterOptionsOpen = useSetRecoilState(filterOptionsOpenAtom)
  const selectedDate = useRecoilValue(selectedDateAtom)
  const userHasSelectedDate = useRecoilValue(isUserSelectedDateAtom)

  const showTimeRangeChip = !equal(
    [...defaultSelectedTimeRanges].sort(),
    [...selectedTimeRanges].sort()
  )
  const showAppointmentTypeChip = true

  const showDurationsChip = selectedDurations.length > 0
  const showLanguageChip = selectedLanguage !== null
  const showGenderChip = selectedGender !== Gender.UNSPECIFIED
  const showPatientAgeGroupChip = selectedPatientAgeGroup !== null

  if (
    hideAddFiltersButton &&
    !showTimeRangeChip &&
    !showAppointmentTypeChip &&
    !showLanguageChip &&
    !showGenderChip &&
    !showPatientAgeGroupChip &&
    !showDurationsChip
  ) {
    return null
  }

  return (
    <ChipsContainer data-cy="searchFilterChip-container">
      {!hideAddFiltersButton && (
        <Chip
          variant="important"
          label={t('component.searchFilterChips.addFiltersText')}
          onClick={() => setFilterOptionsOpen(true)}
          icon={filterIcon}
          order="icon-first"
        />
      )}

      {!hideInsuranceButton && <SearchFilterInsuranceChip />}

      {showTimeRangeChip &&
        selectedTimeRanges.map((timeRange, idx) => (
          <Chip
            variant="important"
            key={idx}
            ariaLabel={t('common.removeFilter')}
            label={t(`component.searchFilterChips.label.timeRange.${timeRange}`)}
            onClick={() => setSelectedTimeRanges(defaultSelectedTimeRanges)}
            icon={<Times />}
          />
        ))}

      {Boolean(userSelectedAppointmentTypes.length) &&
        showAppointmentTypeChip &&
        selectedAppointmentTypes.map((appointmentType, idx) => (
          <Chip
            variant="important"
            key={idx}
            ariaLabel={t('common.removeFilter')}
            label={t(`component.searchFilterChips.label.appointmentType.${appointmentType}`)}
            onClick={() => {
              if (selectedAppointmentTypes.length === 1) {
                setSelectedAppointmentTypes([])
                setUserSelectedAppointmentTypes([])
                return
              }
              const newSelectedAppointmentTypes = selectedAppointmentTypes.filter(
                (selected) => selected !== appointmentType
              )
              setSelectedAppointmentTypes(newSelectedAppointmentTypes)
              setUserSelectedAppointmentTypes(newSelectedAppointmentTypes)
            }}
            icon={<Times />}
          />
        ))}

      {showLanguageChip && (
        <Chip
          variant="important"
          ariaLabel={t('common.removeFilter')}
          label={t(`component.searchFilterChips.label.language`, {
            language: selectedLanguage.language,
          })}
          onClick={() => setSelectedLanguage(null)}
          icon={<Times />}
        />
      )}

      {showGenderChip && (
        <Chip
          variant="important"
          ariaLabel={t('common.removeFilter')}
          label={t(`component.searchFilterChips.label.gender.${selectedGender}`)}
          onClick={() => setSelectedGender(Gender.UNSPECIFIED)}
          icon={<Times />}
        />
      )}

      {showPatientAgeGroupChip && (
        <Chip
          variant="important"
          ariaLabel={t('common.removeFilter')}
          label={selectedPatientAgeGroup.patientAgeGroup}
          onClick={() => setSelectedPatientAgeGroup(null)}
          icon={<Times />}
        />
      )}
      {showDurationsChip &&
        selectedDurations.map((duration, idx) => (
          <Chip
            variant="important"
            key={idx}
            ariaLabel={t('common.removeFilter')}
            label={duration.toString() + ' ' + t(`component.searchFilterChips.label.duration`)}
            onClick={() => {
              if (selectedDurations.length === 1) {
                setSelectedDurations(defaultSelectedDurations)
                return
              }
              setSelectedDurations(selectedDurations.filter((d) => d !== duration))
            }}
            icon={<Times />}
          />
        ))}

      {!isDesktop && !isTablet && (
        <Chip
          variant="important"
          ariaLabel={t('common.calendar')}
          label={
            userHasSelectedDate
              ? capitalizeFirstLetter(selectedDate.format('dd D.M.'))
              : t('common.calendar')
          }
          onClick={() => setCalendarModalOpen(true)}
          icon={<Calendar />}
          order="icon-first"
        />
      )}
    </ChipsContainer>
  )
}

export default SearchFilterChips
