interface CustomerServiceLink {
  link: string
  label: string
}

export const getCustomerServiceNumber = (
  type: 'general' | 'dental',
  lang: string
): CustomerServiceLink => {
  switch (type) {
    case 'general':
      switch (lang) {
        case 'en':
          return { link: 'tel:+358104140200', label: '010 414 0200' }
        case 'sv':
          return { link: 'tel:+358104140100', label: '010 414 0100' }
        default:
          return { link: 'tel:+3581041400', label: '010 414 00' }
      }
    case 'dental':
      switch (lang) {
        default:
          return { link: 'tel:+358102738000', label: '010 273 8000' }
      }
  }
}
