import { useEffect, useState } from 'react'

import api from '../services/api'

let cache: Record<string, boolean> | null = null

export const useFeatureFlags = (): Record<string, boolean> | null => {
  const [data, setData] = useState<Record<string, boolean> | null>(cache)

  useEffect(() => {
    if (!cache) {
      api.v1.featureFlags().then((res) => {
        const record = res.data.reduce<Record<string, boolean>>((prev, cur) => {
          return { ...prev, [cur.name]: cur.value }
        }, {})

        cache = record
        setData(cache)
      })
    }
  }, [])

  return data
}
