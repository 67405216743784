import type { Dayjs } from 'dayjs'
import { useRecoilValue } from 'recoil'

import {
  AppointmentInfoResult,
  AppointmentReservationInfo,
  AppointmentSearchResult,
  AppointmentType,
  LanguageResult,
  LocationResult,
  PatientAgeGroup,
  PractitionerDetails,
  ReservationPaymentType,
  PublicNode,
  TimeRange,
} from '../../__generated__/api'
import { useDeepCompareEffect } from '../../common/hooks/useDeepCompare'
import dayjs from '../../common/utils/dayjs/dayjs'
import { isFromAppAtom } from '../../state/common/atoms'
import { Gender } from '../components/FilterOptions/types'
import useLoginState from '../hooks/useLoginState'

interface DataLayerSearchResults {
  slotsAvailable: number
  nextAvailableSlot: number | null
}

interface DataLayerAppointment {
  date: string
  time?: string
  type?: string
  paymentMethod?: 'Myself'
  bookedFor?: 'Myself' | 'Other'
  duration?: 'regular' | 'extended'
}

interface DataLayerPractitioner {
  name: string
  id: string
}

interface DataLayerLocation {
  name: string
  id: string
}

interface DataLayerService {
  area: 'LK' | 'TEP' | 'STH'
  name?: string
  id?: string
}

interface BasicEvent {
  event:
    | 'search_slot'
    | 'confirm_slot'
    | 'select_patient'
    | 'appointment_details'
    | 'appointment_confirmed'
    | 'final_verify' /* Reserved from OM App */
  appointment: DataLayerAppointment
  service: DataLayerService
  searchResults?: DataLayerSearchResults
  practitioner?: DataLayerPractitioner
  location?: DataLayerLocation
  searchFilters?: string
  mobile: boolean
  impersonated?: boolean
  isOccupational?: boolean
  isDental?: boolean
}

interface FreeSearchEvent {
  event: 'freeSearch'
  keyword: string
  results: number
}

export enum ServiceSelectionType {
  PRIVATE = 'private',
  INSURANCE = 'insurance',
  DENTAL = 'dental',
  OCCUPATIONAL = 'occupational',
  CALL = 'call',
}

interface ServiceSelectionEvent {
  event: 'service_selection'
  serviceSelectionName: ServiceSelectionType
}

interface AppointmentDurationSelectionEvent {
  event: 'appointment_duration_selection'
  selectedDuration: 'regular' | `${number}min` | `node-${string}` | `reason-${string}`
  appointmentDurationModal: 'continue' | 'cancel'
}

interface IdentificationSelectEvent {
  event: 'service_identification'
  identifyOption: 'identification' | 'no-identification'
}

interface PaymentTypeSelectEvent {
  event: 'service_payment_type'
  servicePaymentOption:
    | 'self'
    | 'contract'
    | 'gift card'
    | 'voucher'
    | 'synsam'
    | 'physiotherapy_referral'
    | 'occupational'
    | 'insurance'
}

interface InsuranceCompanySelectEvent {
  event: 'insCompanySelection'
  insCompanyModal: 'continue' | 'cancel'
  paymentType: 'patient' | 'insCompany'
  selectedInsCompany: string
}

interface InsuranceEventSelectEvent {
  event: 'insuranceEventSelection'
  insuranceEventModal: 'continue' | 'cancel'
  insuranceEvent: string
}

interface InsuranceRedirectParams {
  event: 'InsuranceRedirectParams'
  data: string
  dataRaw: string
}

type DataLayerEvent =
  | BasicEvent
  | FreeSearchEvent
  | ServiceSelectionEvent
  | AppointmentDurationSelectionEvent
  | IdentificationSelectEvent
  | PaymentTypeSelectEvent
  | InsuranceCompanySelectEvent
  | InsuranceEventSelectEvent
  | InsuranceRedirectParams

const pushToDataLayer = (event: DataLayerEvent) => {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer ?? []
    window.dataLayer.push(event)
  }
}

// APPOINTMENT DETAILS, USER SELECT & VERIFY

interface useTrackVerifyProps {
  appointmentInfo: AppointmentInfoResult | null
  node: PublicNode | null
  nodePending: boolean
  event: 'confirm_slot' | 'appointment_details' | 'select_patient'
  selectedAppointmentType?: AppointmentType
  duration?: 'regular' | 'extended'
  isOHC: boolean
  isDental: boolean
}

export const useTrackVerify = (props: useTrackVerifyProps) => {
  const isFromApp = useRecoilValue(isFromAppAtom)

  useDeepCompareEffect(() => {
    if (props.appointmentInfo) {
      pushToDataLayer({
        event: props.event,
        appointment: {
          date: dayjs(props.appointmentInfo.date).format('YYYY-MM-DD'),
          time: dayjs(props.appointmentInfo.date).format('HH:mm'),
          type: props.selectedAppointmentType
            ? props.selectedAppointmentType
            : mapAppointmentTypes(props.appointmentInfo),
          duration: props.duration,
        },
        service: {
          area: 'LK',
          ...(props.node && { name: props.node.name, id: props.node.id }),
        },
        practitioner: {
          name: props.appointmentInfo.practitionerName,
          id: `${props.appointmentInfo.practitionerId}`,
        },
        location: {
          name: props.appointmentInfo.locationName,
          id: props.appointmentInfo.locationId,
        },
        mobile: isFromApp,
        isOccupational: props.isOHC,
        isDental: props.isDental,
      })
    }
  }, [props])
}

const mapAppointmentTypes = (appointmentInfo: AppointmentInfoResult) =>
  `${appointmentInfo.isClinicAppointmentPermitted ? 'reception' : ''}${
    appointmentInfo.isPhoneAppointmentPermitted ? ', phone' : ''
  }${appointmentInfo.isVideoAppointmentPermitted ? ', video' : ''}`

// CONFIRMATION

interface TrackConfirmProps {
  appointmentInfo: Pick<
    AppointmentReservationInfo,
    'time' | 'type' | 'specialistName' | 'practitionerId' | 'locationName' | 'locationId'
  > | null
  bookedFor: 'Myself' | 'Other'
  duration: 'regular' | 'extended'
  isOHC: boolean
  isDental: boolean
}

const trackConfirmInternal = (
  event: 'appointment_confirmed' | 'final_verify',
  props: TrackConfirmProps,
  mobile: boolean,
  impersonated?: boolean
) =>
  props.appointmentInfo &&
  pushToDataLayer({
    event,
    appointment: {
      date: dayjs(props.appointmentInfo.time).format('YYYY-MM-DD'),
      time: dayjs(props.appointmentInfo.time).format('HH:mm'),
      type: props.appointmentInfo.type,
      paymentMethod: 'Myself',
      bookedFor: props.bookedFor,
      duration: props.duration,
    },
    service: {
      area: props.isOHC ? 'TEP' : 'LK',
    },
    practitioner: {
      name: props.appointmentInfo.specialistName,
      id: `${props.appointmentInfo.practitionerId}`,
    },
    location: {
      name: props.appointmentInfo.locationName,
      id: props.appointmentInfo.locationId,
    },
    mobile,
    impersonated,
    isOccupational: props.isOHC,
    isDental: props.isDental,
  })

// Use only for mobile app during handling of appointment reservation
export const trackMobileConfirm = (props: TrackConfirmProps) =>
  props.appointmentInfo && trackConfirmInternal('final_verify', props, true)

// Use only outside of mobile app
export const useTrackConfirm = (props: TrackConfirmProps) => {
  const isFromApp = useRecoilValue(isFromAppAtom)
  const { loginStatus } = useLoginState()

  useDeepCompareEffect(() => {
    if (props.appointmentInfo && !isFromApp) {
      trackConfirmInternal(
        'appointment_confirmed',
        props,
        false,
        loginStatus === 'impersonated' ? true : undefined
      )
    }
  }, [props])
}

// MAIN & PRACTITIONER SEARCH

interface useTrackSearchProps {
  appointments: AppointmentSearchResult[] | null
  selectedDate: Dayjs
  pending: boolean
  selectedNode?: PublicNode | null
  practitionerDetails?: PractitionerDetails
  selectedLocationResult?: LocationResult
  selectedTimeRanges?: TimeRange[]
  selectedAppointmentTypes?: AppointmentType[]
  selectedLanguage?: LanguageResult | null
  selectedGender?: Gender
  selectedPatientAgeGroup?: PatientAgeGroup | null
  selectedDurations?: number[]
  isOHC: boolean
  isDental: boolean
}

export const useTrackSearch = (props: useTrackSearchProps) => {
  const isFromApp = useRecoilValue(isFromAppAtom)

  useDeepCompareEffect(() => {
    if (!props.pending) {
      pushToDataLayer({
        event: 'search_slot',
        searchFilters: mapFilters(props),
        searchResults: {
          slotsAvailable: props.appointments?.length ?? 0,
          nextAvailableSlot: props.appointments?.length
            ? dayjs(props.appointments[0].time).valueOf()
            : null,
        },
        appointment: {
          date: props.selectedDate.format('YYYY-MM-DD'),
        },
        service: {
          area: 'LK',
          ...(props.selectedNode && { name: props.selectedNode.name, id: props.selectedNode.id }),
        },
        ...(props.selectedLocationResult && {
          location: {
            name: props.selectedLocationResult.name,
            id: props.selectedLocationResult.clinicIDs.join(', '),
          },
        }),
        ...(props.practitionerDetails && {
          practitioner: {
            name: props.practitionerDetails.name,
            id: `${props.practitionerDetails.id}`,
          },
        }),
        ...(props.selectedDurations && {
          durations: props.selectedDurations,
        }),
        mobile: isFromApp,
        isOccupational: props.isOHC,
        isDental: props.isDental,
      })
    }
  }, [props.appointments, props.pending])
}

const mapFilters = (props: useTrackSearchProps) => {
  const filterString = [
    ...(props.selectedTimeRanges?.length
      ? [props.selectedTimeRanges.map(mapTimeRange).join(', ')]
      : []),
    ...(props.selectedAppointmentTypes?.length
      ? [props.selectedAppointmentTypes.map(mapAppointmentType).join(', ')]
      : []),
    ...(props.selectedLanguage ? [`asiointikieli: ${props.selectedLanguage.language}`] : []),
    ...(props.selectedGender && props.selectedGender !== Gender.UNSPECIFIED
      ? [`asiantuntijan sukupuoli: ${mapGender(props.selectedGender)}`]
      : []),
    ...(props.selectedPatientAgeGroup
      ? [props.selectedPatientAgeGroup.patientAgeGroup.toLowerCase()]
      : []),
  ].join(', ')

  return filterString.length ? filterString : undefined
}

const mapTimeRange = (timeRange: TimeRange) => {
  switch (timeRange) {
    case TimeRange.Morning:
      return 'aamupäivä'
    case TimeRange.Afternoon:
      return 'iltapäivä'
    case TimeRange.Evening:
      return 'ilta'
  }
}

const mapAppointmentType = (type: AppointmentType) => {
  switch (type) {
    case AppointmentType.Clinic:
      return 'vastaanotto'
    case AppointmentType.Phone:
      return 'puhelinvastaanotto'
    case AppointmentType.Video:
      return 'videovastaanotto'
  }
}

const mapGender = (gender: Gender) => {
  switch (gender) {
    case Gender.FEMALE:
      return 'nainen'
    case Gender.MALE:
      return 'mies'
  }
}

// Autocomplete

export const trackAutocomplete = (keyword: string, results: number) =>
  pushToDataLayer({
    event: 'freeSearch',
    keyword,
    results,
  })

// Landing modal

export const trackServiceSelection = (serviceSelectionName: ServiceSelectionType) =>
  pushToDataLayer({
    serviceSelectionName,
    event: 'service_selection',
  })

// Appointment duration selection

export const trackAppointmentDurationSelect = (
  selectedDuration: AppointmentDurationSelectionEvent['selectedDuration'],
  appointmentDurationModal: AppointmentDurationSelectionEvent['appointmentDurationModal']
) =>
  pushToDataLayer({
    selectedDuration,
    appointmentDurationModal,
    event: 'appointment_duration_selection',
  })

// Identification select

export const trackIdenfiticationSelect = (
  identifyOption: IdentificationSelectEvent['identifyOption']
) =>
  pushToDataLayer({
    identifyOption,
    event: 'service_identification',
  })

// Payment type select

const mapPaymentType = (
  paymentType: ReservationPaymentType
): PaymentTypeSelectEvent['servicePaymentOption'] => {
  switch (paymentType) {
    case ReservationPaymentType.Self:
      return 'self'
    case ReservationPaymentType.GiftCard:
      return 'gift card'
    case ReservationPaymentType.HealthContract:
      return 'contract'
    case ReservationPaymentType.ServiceVoucher:
      return 'voucher'
    case ReservationPaymentType.Synsam:
      return 'synsam'
    case ReservationPaymentType.PhysiotherapyReferral:
      return 'physiotherapy_referral'
    case ReservationPaymentType.Occupational:
      return 'occupational'
    case ReservationPaymentType.Insurance:
      return 'insurance'
  }
}

export const trackPaymentTypeSelect = (paymentType: ReservationPaymentType) =>
  pushToDataLayer({
    servicePaymentOption: mapPaymentType(paymentType),
    event: 'service_payment_type',
  })

export const trackInsuranceCompanySelect = (
  companyName: InsuranceCompanySelectEvent['selectedInsCompany'],
  paymentType: InsuranceCompanySelectEvent['paymentType'],
  action: InsuranceCompanySelectEvent['insCompanyModal']
) =>
  pushToDataLayer({
    selectedInsCompany: companyName,
    paymentType,
    insCompanyModal: action,
    event: 'insCompanySelection',
  })

export const trackInsuranceEventSelect = (
  insuranceEvent: string,
  action: InsuranceEventSelectEvent['insuranceEventModal']
) =>
  pushToDataLayer({
    insuranceEvent,
    insuranceEventModal: action,
    event: 'insuranceEventSelection',
  })

export const trackInsuranceRedirectParams = (data: string, dataRaw: string) =>
  pushToDataLayer({
    event: 'InsuranceRedirectParams',
    data,
    dataRaw,
  })
