import styled from '@emotion/styled'
import { DialogProps } from '@mui/material'
import React from 'react'

import { ColumnFlex } from '../Layout/Layout'

import ModalFooter, { Props as ModalFooterProps } from './ModalFooter/ModalFooter'
import ModalHeader, { Props as ModalHeaderProps } from './ModalHeader/ModalHeader'
import ResponsiveModal from './ResponsiveModal/ResponsiveModal'

const Container = styled(ColumnFlex)``

interface Props extends DialogProps, ModalFooterProps, ModalHeaderProps {
  open: boolean
  onClose?(): void
  showHeader?: boolean
}

const Modal: React.FC<React.PropsWithChildren<Props>> = ({
  heading,
  onBack,
  open,
  onClose,
  primaryButtonText,
  secondaryButtonText,
  footerText,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  children,
  stickyFooter,
  primaryButtonDisabled,
  primaryButtonDaPath,
  primaryButtonCy,
  secondaryButtonDaPath,
  showHeader = true,
  ...rest
}) => {
  return (
    <ResponsiveModal open={open} onClose={onClose} {...rest} data-cy="modal">
      <Container>
        {showHeader && (
          <ModalHeader open={open} heading={heading} onBack={onBack} onClose={onClose} />
        )}
        {children}
        <ModalFooter
          primaryButtonText={primaryButtonText}
          secondaryButtonText={secondaryButtonText}
          footerText={footerText}
          onPrimaryButtonClick={onPrimaryButtonClick}
          onSecondaryButtonClick={onSecondaryButtonClick}
          stickyFooter={stickyFooter}
          primaryButtonDisabled={primaryButtonDisabled}
          primaryButtonDaPath={primaryButtonDaPath}
          primaryButtonCy={primaryButtonCy}
          secondaryButtonDaPath={secondaryButtonDaPath}
        />
      </Container>
    </ResponsiveModal>
  )
}

export default Modal
