import styled from '@emotion/styled'
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import type React from 'react'
import { useTranslation } from 'react-i18next'

import { TimeRange } from '../../../__generated__/api'
import { VisuallyHidden } from '../Layout/Layout'

import Fieldset from './Fieldset'

const StyledFormGroup = styled(FormGroup)`
  display: flex;
  flex-direction: column;
`

interface Props {
  name: string
  value: TimeRange[]
  handleChange: (event: React.ChangeEvent<{ value: TimeRange[] }>) => void
  hideAccordion?: boolean
}

const TimeRangeSelect: React.FC<React.PropsWithChildren<Props>> = ({
  name,
  value,
  handleChange,
  hideAccordion,
}) => {
  const { t } = useTranslation()
  return (
    <Fieldset
      legend={t('component.filterOptions.timeRangeSelect.legend')}
      chip={
        value.length > 0 ? (
          <>
            <VisuallyHidden as="span">, </VisuallyHidden>
            <span>{value.length}</span>
            <VisuallyHidden as="span">
              {t('component.filterOptions.aria.selection', { count: value.length })}
            </VisuallyHidden>
          </>
        ) : undefined
      }
      hideAccordion={hideAccordion}
    >
      <StyledFormGroup>
        {[TimeRange.Morning, TimeRange.Afternoon, TimeRange.Evening].map((timeRange) => (
          <FormControlLabel
            key={`timeRangeSelect-timeRange-${timeRange}`}
            control={
              <Checkbox
                id={`timeRangeSelect-timeRange-${timeRange}-checkbox`}
                checked={value.includes(timeRange)}
              />
            }
            label={t(`component.filterOptions.timeRangeSelect.label.${timeRange}`)}
            onChange={() =>
              handleChange({
                target: {
                  name,
                  value: !value.includes(timeRange)
                    ? [...value, timeRange]
                    : value.filter((type) => type !== timeRange),
                },
              } as unknown as React.ChangeEvent<{ value: TimeRange[] }>)
            }
          />
        ))}
      </StyledFormGroup>
    </Fieldset>
  )
}

export default TimeRangeSelect
