import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import DentalMaintenanceView from '../../../domain/maintenance/container/DentalMaintenanceView'
import MaintenanceView from '../../../domain/maintenance/container/MaintenanceView'

import MaintenanceError from './MaintenanceError'
import { isDentalMaintenanceError } from './utils'

interface Props extends RouteComponentProps {
  children: React.ReactNode
}
interface State {
  error?: Error
}

class MaintenanceErrorBoundary extends React.PureComponent<Props, State> {
  public state: State = {}

  constructor(props: Props) {
    super(props)

    this.resetError = this.resetError.bind(this)
  }

  public static getDerivedStateFromError(error: Error): State {
    return { error }
  }

  private resetError(): void {
    this.setState({ error: undefined }, () => {
      this.props.history.push('/')
    })
  }

  public render(): React.ReactNode {
    if (this.state.error) {
      if (isDentalMaintenanceError(this.state.error)) {
        return <DentalMaintenanceView onBack={this.resetError} />
      }

      // If error is not of the correct type, rethrow to be caught by a boundary higher up in the tree
      if (!(this.state.error instanceof MaintenanceError)) {
        throw this.state.error
      }

      return <MaintenanceView message={this.state.error.message} />
    }

    return this.props.children
  }
}

export default withRouter(MaintenanceErrorBoundary)
