import { useEffect, useCallback } from 'react'

import { useDebouncedValue } from '../utils/debounce'

import useMobileRelay, { RelayEvent } from './useMobileRelay'

const useMobileSearchHeight = (active: boolean, height?: number) => {
  const { postMessage } = useMobileRelay()

  const debouncedHeight = useDebouncedValue(height, 1500)

  const postHeight = useCallback(
    (localHeight: any) => {
      if (localHeight !== undefined) {
        postMessage(RelayEvent.SearchHeight, localHeight)
      }
    },
    [postMessage]
  )

  useEffect(() => {
    if (active) {
      postHeight(debouncedHeight)
    }
  }, [postHeight, debouncedHeight, active])
}

export default useMobileSearchHeight
