import { FormikProps } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ColumnFlex } from '../../../common/components/Layout/Layout'
import { Text } from '../../../common/components/Typography/Typography'
import { scale } from '../../../common/utils/scale'

import { ReserveCheckbox } from './FormComponents'
import ReserveFormFieldset from './ReserveFormFieldset'

interface Props
  extends Pick<
    FormikProps<{
      marketingPermitPhone?: boolean
      marketingPermitEmail?: boolean
    }>,
    'values' | 'handleChange' | 'errors'
  > {
  showEmailPermitOption: boolean
  showPhonePermitOption: boolean
}

const ReserveMarketingPermissions: React.FC<React.PropsWithChildren<Props>> = ({
  values,
  handleChange,
  showEmailPermitOption,
  showPhonePermitOption,
}) => {
  const { t } = useTranslation()
  return (
    <ReserveFormFieldset legend={t('component.reserveMarketingPermissions.heading')}>
      <ColumnFlex $gap={scale(2)}>
        <Text $size={300} $weight="Regular" $height="Medium">
          {t('component.reserveMarketingPermissions.text')}
        </Text>
        {showEmailPermitOption && (
          <ReserveCheckbox
            name="marketingPermitEmail"
            checked={values.marketingPermitEmail}
            onChange={handleChange}
            label={t('component.reserveMarketingPermissions.email')}
          />
        )}
        {showPhonePermitOption && (
          <ReserveCheckbox
            name="marketingPermitPhone"
            checked={values.marketingPermitPhone}
            onChange={handleChange}
            label={t('component.reserveMarketingPermissions.phone')}
          />
        )}
      </ColumnFlex>
    </ReserveFormFieldset>
  )
}

export default ReserveMarketingPermissions
