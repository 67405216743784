import { useCallback, useState } from 'react'

// Hack to trigger error boundaries from async callbacks.
export const useAsyncError = (): ((e: Error) => void) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setError] = useState()
  return useCallback(
    (e: any) => {
      setError(() => {
        throw e
      })
    },
    [setError]
  )
}
