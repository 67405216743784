import { Base64 } from 'js-base64'

import { User } from '../../../__generated__/api'

export const getUserFromToken = (jwtToken: string | null): User => {
  const dataFromToken = parseJwtToken(jwtToken)
  return {
    ...getEmptyUser(),
    firstName: dataFromToken?.firstNames || '',
    lastName: dataFromToken?.lastName || '',
    ssn: dataFromToken?.ssn || '',
  }
}

export const getEmptyUser = (): User => {
  return {
    id: 0,
    firstName: '',
    lastName: '',
    fullName: '',
    ssn: '',
    email: '',
    phone: '',
    marketingPermits: {
      permitEmail: false,
      permitPhone: false,
    },
    hasAddress: false,
    address: undefined,
    omUid: 0,
  }
}

const parseJwtToken = (
  token: string | null
): { lastName: string | undefined; firstNames: string; ssn: string } | null => {
  try {
    if (!token) return null
    const jwtData: { name: string; ssn: string } = JSON.parse(Base64.decode(token.split('.')[1]))
    const splittedNames = jwtData.name.split(' ')
    const lastName = splittedNames.shift()
    const firstNames = splittedNames.join(' ')
    return { lastName, firstNames, ssn: jwtData.ssn }
  } catch (error) {
    return null
  }
}
