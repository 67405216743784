import styled from '@emotion/styled'
import React from 'react'

import InputFieldContainerNew, { Props as InputFieldContainerProps } from './InputFieldContainerNew'
import { commonInputStylesNew } from './commonInputStylesNew'

const Input = styled.input<{ error: boolean }>`
  ${(props) => commonInputStylesNew(props.error)};
`

interface Props extends React.InputHTMLAttributes<HTMLInputElement>, InputFieldContainerProps {
  ariaDescribedBy?: string
}

const TextFieldNew: React.FC<React.PropsWithChildren<Props>> = React.forwardRef(
  ({ id, label, helperText, error, required, className, ariaDescribedBy, ...inputProps }, ref) => {
    return (
      <InputFieldContainerNew
        label={label}
        helperText={helperText}
        error={error}
        id={id}
        className={className}
      >
        <Input
          ref={ref as React.RefObject<HTMLInputElement>}
          id={id}
          error={Boolean(error)}
          aria-invalid={Boolean(error)}
          aria-describedby={`${ariaDescribedBy} helper-${id} error-${id}`}
          required={Boolean(required)}
          {...inputProps}
        />
      </InputFieldContainerNew>
    )
  }
)

export default TextFieldNew
