import styled from '@emotion/styled'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import type React from 'react'
import { useTranslation } from 'react-i18next'

import { PatientAgeGroup } from '../../../__generated__/api'
import { VisuallyHidden } from '../Layout/Layout'

import Fieldset from './Fieldset'

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: column;
`

interface Props {
  patientAgeGroups: PatientAgeGroup[]
  name: string
  value: PatientAgeGroup | null
  handleChange: (event: React.ChangeEvent<{ value: PatientAgeGroup | null }>) => void
  hideAccordion?: boolean
}

const PatientAgeGroupSelect: React.FC<React.PropsWithChildren<Props>> = ({
  patientAgeGroups,
  name,
  value,
  handleChange,
  hideAccordion,
}) => {
  const { t } = useTranslation()

  return (
    <Fieldset
      legend={t('component.filterOptions.patientAgeGroupSelect.legend')}
      tooltip={t('component.filterOptions.patientAgeGroupSelect.tooltip')}
      chip={
        value ? (
          <>
            <VisuallyHidden as="span">, </VisuallyHidden>
            <span>1</span>
            <VisuallyHidden as="span">{t('component.filterOptions.aria.selection')}</VisuallyHidden>
          </>
        ) : undefined
      }
      hideAccordion={hideAccordion}
      hideBorder
    >
      <StyledRadioGroup>
        {[...patientAgeGroups, { id: -1, patientAgeGroup: 'unspecified' }].map(
          ({ id, patientAgeGroup }) => (
            <FormControlLabel
              key={`patientAgeGroupSelect-RadioButton-${id}`}
              control={
                <Radio
                  id={`patientAgeGroupSelect-patientAgeGroup-${id}-radio`}
                  checked={patientAgeGroup === 'unspecified' ? value === null : id === value?.id}
                  onChange={() => {
                    handleChange({
                      target: {
                        name,
                        value: patientAgeGroup === 'unspecified' ? null : { id, patientAgeGroup },
                      },
                    } as unknown as React.ChangeEvent<{ value: PatientAgeGroup | null }>)
                  }}
                />
              }
              label={
                patientAgeGroup === 'unspecified'
                  ? t('component.filterOptions.patientAgeGroupSelect.label.unspecified')
                  : patientAgeGroup
              }
            />
          )
        )}
      </StyledRadioGroup>
    </Fieldset>
  )
}

export default PatientAgeGroupSelect
