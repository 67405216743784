/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ServiceAttachment {
  title: string;
  link: string;
}

export interface BookingInstructions {
  bookingInstructions: string;
  attachments?: ServiceAttachment[];
}

export enum LocationType {
  All = "all",
  City = "city",
  Region = "region",
  Clinic = "clinic",
}

export interface LocationResult {
  type: LocationType;
  id: number;
  uniqueId: string;
  /** DH clinic IDs */
  clinicIDs: string[];
  assisDentBranchIDs: string[];
  name: string;
  address: string | null;
  shortName: string | null;
  phone: string | null;
  imageUrl: string | null;
  lat: number | null;
  long: number | null;
  clinicType: string;
  priority: number;
  favorite: boolean;
  locationTypes: number[];
  defaultNode?: string | null;
}

export interface LanguageResult {
  /** ID of the language */
  id: number;
  /** @example "suomi" */
  language: string;
}

export interface PatientAgeGroup {
  /**
   * ID of the age group.
   * @example 1
   */
  id: number;
  /** Translated description of the age group. */
  patientAgeGroup: string;
}

/** @default "fi" */
export enum SupportedLanguage {
  Fi = "fi",
  En = "en",
  Sv = "sv",
}

export enum ElectronicHealthRecordSystem {
  Dh = "dh",
  Assisdent = "assisdent",
}

export interface PriceEstimate {
  /** Service id for which the price estimate is formed. */
  serviceId: number;
  /** Name of the service, translated. */
  name: string;
  /**
   * Minimum price estimate of the service.
   * @example 110.5
   */
  minPrice: number;
  /**
   * Maximum price estimate of the service.
   * @example 150
   */
  maxPrice: number;
  /** Whether the appointment duration affects the price estimate. */
  durationAffectsPrice: boolean;
}

export interface Competence {
  /**
   * Competence ID.
   * @example 124
   */
  id: number;
  /**
   * Competence type. Maps to CompetenceType enum.
   * @example 1
   */
  type: 1 | 2 | 3 | 4 | 5 | 6;
  /** Translated competence as a string. */
  competence: string;
  /**
   * Year when competence achieved, e.g. a completion year of a degree.
   * @example 1990
   */
  year?: number;
}

export interface PractitionerLocation {
  /**
   * Location identifier.
   * @example "T-25"
   */
  id: string;
  /**
   * Name of the location.
   * @example "Kielotie"
   */
  name: string;
}

export interface PractitionerLanguage {
  /**
   * Language name, translated.
   * @example "ruotsi"
   */
  language: string;
  /**
   * Language skill level, translated.
   * @example "työkieli"
   */
  level: string;
}

export interface PatientRestriction {
  /**
   * ID of the restriction.
   * @example 9
   */
  id: number;
  /** Translated description of the restriction. */
  patientRestriction: string;
}

export interface AppointmentLengthOption {
  /**
   * Duration of the option, in minutes.
   * @example 40
   */
  duration: number;
  /**
   * Translated descriptions of the option.
   * @example ["Ajokorttitodistukset ja muut todistukset","Kattavammat lausunnot"]
   */
  description: string[];
  /**
   * Name of the service.
   * @example "Urheiluhieronta"
   */
  serviceName?: string;
  /**
   * (Palveluhaku) ID of the service.
   * @example 1
   */
  serviceId?: number;
}

export interface FlexibleScheduleOption {
  /**
   * (Palveluhaku) ID of the service.
   * @example 563
   */
  serviceId: number;
  /**
   * ID of the location.
   * @example "clinic_443"
   */
  locationId: string;
  /**
   * (DH) ID of the location.
   * @example "M-24"
   */
  locationDHId: string;
  /**
   * Name of the service.
   * @example "Kinesioteippaus"
   */
  serviceName: string;
  /**
   * List of available durations for this service.
   * @example [45,60]
   */
  availableDurations: number[];
}

export interface PractitionerSpecialty {
  id: number;
  name: string;
  etns: string;
}

export enum NodeServiceType {
  Private = "private",
  Ohc = "ohc",
  Both = "both",
}

export interface PractitionerNode {
  serviceType: NodeServiceType;
  id: string;
  name: string;
}

export interface AssisDentReason {
  id: string;
  name: string;
  nodeId: string;
}

export interface PractitionerDetails {
  primarySource: ElectronicHealthRecordSystem;
  title: string;
  image?: string;
  rating?: number;
  introduction?: string;
  bookingInstructions?: string;
  testimonial?: string;
  hasFlexibleSchedule?: boolean;
  priceEstimates: PriceEstimate[];
  competencies: Competence[];
  locations: PractitionerLocation[];
  nearFutureLocations: PractitionerLocation[];
  languages: PractitionerLanguage[];
  patientRestrictions: PatientRestriction[];
  patientAgeGroups: PatientAgeGroup[];
  appointmentLengthOptions: AppointmentLengthOption[];
  flexibleScheduleOptions: FlexibleScheduleOption[];
  insuranceContractIds: number[];
  specialties: PractitionerSpecialty[];
  isEntrepreneur: boolean;
  isEmployee: boolean;
  allowsCallbackReservations: boolean;
  nodes: PractitionerNode[];
  assisDentEmployeeId?: string;
  assisDentReasons?: AssisDentReason[];
  isAssisDentMaintenance?: boolean;
  id: number;
  name: string;
}

export enum NotificationPlacement {
  TopOfSearch = "top_of_search",
  SearchResultsTop = "search_results_top",
  SearchResultsWithin = "search_results_within",
}

export enum NotificationStyle {
  Raise = "raise",
  Info = "info",
  WalkIn = "walk-in",
  Error = "error",
  Warning = "warning",
}

export enum NotificationLinkType {
  Button = "button",
  InlineLink = "inline-link",
}

export interface NotificationLinkList {
  link: string;
  linkText: string;
}

export interface SimpleNotification {
  placements: NotificationPlacement[];
  style: NotificationStyle | null;
  linkType: NotificationLinkType | null;
  isInternalLink: boolean;
  linkText: string | null;
  linkList?: NotificationLinkList[];
  id: string;
  title: string | null;
  content: string | null;
  icon: string | null;
  link: string | null;
  showRegularAppointments: boolean;
  showVideoAppointments: boolean;
  showPhoneAppointments: boolean;
  searchCountRestrictions: number | null;
}

export interface DCNotification {
  placements: NotificationPlacement[];
  queueLength: number;
  queueId: string;
  ctaText: string;
  id: string;
  title: string | null;
  content: string | null;
  icon: string | null;
  link: string | null;
  showRegularAppointments: boolean;
  showVideoAppointments: boolean;
  showPhoneAppointments: boolean;
  searchCountRestrictions: number | null;
}

export interface ServiceName {
  name: string;
  /** @example "Lääkäri" */
  title?: string;
}

export interface ServiceDetails {
  id: number;
  name: string;
  bookingInstructions?: string;
}

export interface SpecialtyDetails {
  id: number;
  name: string;
  dhId: string;
}

export interface DateRange {
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string | null;
}

export interface ContractUsePeriods {
  dates: DateRange[];
  /** @format date-time */
  employmentStarts: string;
  isPaymentCommitmentRequired: boolean;
  userPaymentCommitmentDates: DateRange[];
  namedCheckupOfficeCityId: number;
  isOnlineReservationAllowed: boolean;
}

export interface FeatureFlag {
  name: string;
  value: boolean;
}

export enum NodeType {
  Default = "default",
  ServicePackage = "service_package",
}

export interface SearchNodeTerm {
  id: string;
  type: "specialty" | "lkri" | "expertise" | "service" | "service_package" | "node" | "age_group" | "reason";
  term: string;
}

export interface SearchNodeConnectionBase {
  priority: number;
  type: "dh" | "assisdent";
  terms: SearchNodeTerm[];
  practitionerRestriction: "named_practitioners" | "all_practitioners" | null;
}

export interface PublicNode {
  type: NodeType;
  children?: PublicNode[];
  id: string;
  name: string;
  descriptionTitle?: string;
  description?: string;
  title?: string;
  caption?: string;
  icon?: string;
  parentId?: string;
  parentName?: string;
  locationTypes: number[];
  video: boolean;
  phone: boolean;
  redirect?: string;
  modalId?: string;
  priority: number | null;
  connections?: SearchNodeConnectionBase[];
}

/** Linking type (etns for now). */
export enum SearchNodeLinkingType {
  Etns = "etns",
  NEtns = "n_etns",
  Palv = "palv",
  Apalv = "apalv",
  Oa = "oa",
  Reason = "reason",
}

export interface SearchNodeLinking {
  /** Linking type (etns for now). */
  type: SearchNodeLinkingType;
  numericId: number | null;
  textualId: string | null;
  /** Guid of the node used for searching. */
  node: string;
  /** Guid for the OHC node used for mapping OHC requets */
  ohcNode: string | null;
}

export interface ModalAction {
  title: string;
  id: string;
  appearance: "primary" | "secondary" | "tertiary";
  type: "close" | "continue" | "digital_clinic" | "node" | "url";
  queue: string | null;
  node: string | null;
  url: string | null;
}

export interface Modal {
  title: string;
  content: string | null;
  actions: ModalAction[];
  id: string;
  name: string;
  type:
    | "referral"
    | "referral_tep"
    | "restriction"
    | "not_included"
    | "assessment_required"
    | "payment_commitment_invalid"
    | "payment_commitment_valid"
    | "company_insurance"
    | "own_team"
    | "node"
    | "dental_tep";
  default: boolean;
}

export enum ReservationPaymentType {
  Self = "self",
  HealthContract = "health_contract",
  Synsam = "synsam",
  ServiceVoucher = "service_voucher",
  GiftCard = "gift_card",
  PhysiotherapyReferral = "physiotherapy_referral",
  Occupational = "occupational",
  Insurance = "insurance",
}

export interface Address {
  postalCode: string;
  address: string;
  postOffice: string;
}

export interface MarketingPermits {
  permitPhone: boolean;
  permitEmail: boolean;
}

export interface OccupationalAccidentInformation {
  /** @format date-time */
  incidentDate: string;
  /** @maxLength 60 */
  incidentDescription: string;
  /** @maxLength 40 */
  employer: string;
}

export interface TrafficAccidentInformation {
  /** @format date-time */
  incidentDate: string;
  /** @maxLength 60 */
  incidentDescription: string;
  /** @maxLength 10 */
  vehicleRegistrationNumber: string;
}

export interface LahiTapiolaInformation {
  permitId: string;
}

export interface ReserveInsurance {
  insuranceId: number;
  paymentTypeId: string;
  occupationalAccidentInformation?: OccupationalAccidentInformation;
  trafficAccidentInformation?: TrafficAccidentInformation;
  lahiTapiolaInformation?: LahiTapiolaInformation;
}

export interface ReserveParams {
  lang?: SupportedLanguage;
  /**
   * The appointment ID. Either DH PTR as a number or internal AssisDent id as a string.
   * @example 1234
   */
  appointmentId: string | number;
  paymentType?: ReservationPaymentType;
  /** @maxLength 35 */
  firstName: string;
  /** @maxLength 35 */
  lastName: string;
  ssn: string;
  /** @maxLength 100 */
  email: string;
  phone: string;
  nodeId?: string;
  connectionId?: string;
  serviceId?: number;
  specialtyId?: number;
  /**
   * @maxLength 400
   * @default ""
   */
  appointmentReason: string;
  appointmentType: "video" | "phone" | "clinic" | "callback";
  /** @default false */
  isFromApp?: boolean;
  /** @default false */
  isFromOM?: boolean;
  /** @default true */
  sendAttachments?: boolean;
  /** @default true */
  contactByEmail?: boolean;
  /** @default true */
  contactBySms?: boolean;
  minorDisclosureOfInformation?: boolean;
  duration?: number;
  registration?: Address;
  marketingPermits?: MarketingPermits;
  serviceMessagePermit?: boolean;
  insurance?: ReserveInsurance;
  reserveToOmUid?: number;
  discountCode?: string;
  isOhc?: boolean;
}

export interface ReserveResult {
  /**
   * The appointment ID. Either DH PTR as a number or AssisDent id as a string.
   * @example 1234
   */
  appointmentId: string | number;
  /** Identifier of the created reservation. */
  reservationId: string;
  /** Status message */
  message: string;
  /** Status message */
  videoAppointmentUrl?: string;
}

export interface CancelAppointmentParams {
  lang?: SupportedLanguage;
  reservationId: string;
}

/** Appointment types to return. */
export enum AppointmentType {
  Video = "video",
  Phone = "phone",
  Clinic = "clinic",
  Callback = "callback",
}

export interface CancellationInfo {
  /** Appointment types to return. */
  appointmentType: AppointmentType;
  locationName?: string;
  locationAddress?: string;
  locationPostalCode?: string;
  locationPostalDistrict?: string;
  /** @format date-time */
  time: string;
  duration: number;
}

export interface AppointmentReservationInfo {
  /** Appointment types to return. */
  type: AppointmentType;
  electronicHealthRecordSystem: "dh" | "assisdent";
  assisdentId?: string;
  /**
   * Date and time of the appointment.
   * @format date-time
   */
  time: string;
  /** Duration of the appointment in minutes. */
  duration: number;
  /** Translated name of the location. */
  locationName: string;
  /** Translated location address. */
  locationAddress: string;
  /** Postal code of the location. */
  locationPostalCode: string;
  /** Postal district of the location. */
  locationPostalDistrict: string;
  locationCoordinates: {
    lat: number | null;
    long: number | null;
  };
  /** Location id. */
  locationId: string;
  /** First and last name of the specialist. */
  specialistName: string;
  /** The translated specialist's title. */
  specialistTitle: string;
  /**
   * A media.mehilainen.fi resource.
   * @example "945979dbf6854066f9e2b19262e8909d.jpg"
   */
  image: string | null;
  /** The translated arrival info. */
  arrivalInfo: string | null;
  /** The translated parking info. */
  parkingInfo: string | null;
  /**
   * Title of the appointment, resolved from the node
   * through which the reservation was made.
   *
   * If no node was used during reservation, this is null.
   */
  appointmentTitle: string | null;
  /** Is the practitioner resource list (RNRO = 50). */
  isResourceList: boolean;
  practitionerId: number;
  minorDisclosureOfInformationForbidden: boolean;
}

export interface ReservationPaymentTypesResponse {
  paymentTypes: ReservationPaymentType[];
}

export interface CallbackReservationAllowedParams {
  /**
   * The appointment ID. Either DH PTR as a number or internal AssisDent id as a string.
   * @example 1234
   */
  appointmentId: string | number;
  ssn: string;
  reserveToOmUid?: number;
  isOhc?: boolean;
}

export interface CallbackAllowedResponse {
  callbackAllowed: boolean;
}

export enum AppointmentSource {
  DH = "DH",
  AssisDent = "AssisDent",
}

/** Is the appointment covered by the OHC contract */
export enum ContractRuleStatus {
  Allowed = "allowed",
  Disallowed = "disallowed",
  Restriction = "restriction",
  ReferralRequired = "referral_required",
  AssessmentRequired = "assessment_required",
  PaymentCommitmentAllowed = "payment_commitment_allowed",
  PaymentCommitmentDisallowed = "payment_commitment_disallowed",
  CompanyInsurance = "company_insurance",
  DentalRestriction = "dental_restriction",
}

export interface AppointmentInfoResult {
  /**
   * The appointment ID. Either DH PTR as a number or AssisDent id as a string.
   * @example 1234
   */
  id: string | number;
  appointmentSource: AppointmentSource;
  /** Is the appointment covered by the OHC contract */
  ohcClinicAppointmentRule?: ContractRuleStatus;
  /** Is the appointment covered by the OHC contract */
  ohcVideoAppointmentRule?: ContractRuleStatus;
  /** Is the appointment covered by the OHC contract */
  ohcPhoneAppointmentRule?: ContractRuleStatus;
  etns: string | null;
  etnsA: string | null;
  etnsB: string | null;
  practitionerDhId: string | null;
  practitionerHasFlexibleSchedule: boolean;
  isClinicAppointmentPermitted: boolean;
  isVideoAppointmentPermitted: boolean;
  isPhoneAppointmentPermitted: boolean;
  /** Is the practitioner resource list (RNRO = 50). */
  isResourceList: boolean;
  isCallbackAppointment: boolean;
  /** @format date-time */
  date: string;
  duration: number;
  practitionerId: number;
  practitionerName: string;
  practitionerTitle: string;
  practitionerImage: string;
  locationId: string;
  locationName: string;
  locationAddress: string;
  locationPostplace: string;
  locationPostnumber: string;
}

/** Time ranges to filter results by. */
export enum TimeRange {
  Morning = "morning",
  Afternoon = "afternoon",
  Evening = "evening",
}

/** Gender to filter specialists by. */
export enum Gender {
  M = "M",
  F = "F",
}

export interface SearchNodeAppointmentsParams {
  /** @example ["morning","afternoon","evening"] */
  timeRanges?: TimeRange[];
  lang?: SupportedLanguage;
  /** Gender to filter specialists by. */
  gender?: Gender;
  /** @example ["video","phone"] */
  appointmentTypes?: AppointmentType[];
  /** Guid of the node used for searching. */
  id: string;
  /**
   * Startdate for the search.
   * @format date-time
   */
  startDate: string;
  /**
   * @min 1
   * @max 6
   */
  nroOfMonths?: number;
  /**
   * Locations to filter results by.
   * @example ["city2","clinic338"]
   */
  locationIds: string[];
  durations?: number[];
  /** Patient age group ID to filter specialists by. */
  patientAgeGroupId?: number;
  /** Language ID to filter specialists by. */
  languageId?: number;
  /**
   * Insurance contract ID (Palveluhaku) to filter appointments by.
   * @example 1
   */
  insuranceContractId?: number;
  /** Is this OHC appointment search? */
  isOHC?: boolean;
}

export interface ModalContractCondition {
  id: string;
  numericValue: number | null;
  textualValue: string | null;
  type: "contract_row" | "other";
  action: "skip" | "redirect";
  redirect: string | null;
}

export interface ModalWithContractConditions {
  title: string;
  content: string | null;
  actions: ModalAction[];
  id: string;
  name: string;
  type:
    | "referral"
    | "referral_tep"
    | "restriction"
    | "not_included"
    | "assessment_required"
    | "payment_commitment_invalid"
    | "payment_commitment_valid"
    | "company_insurance"
    | "own_team"
    | "node"
    | "dental_tep";
  default: boolean;
  contractCondition: ModalContractCondition | null;
}

export interface AssisDentSearchResult {
  id: string;
  branchId: string;
  resourceId: string;
  reasonId: string;
  /** @format date-time */
  startTime: string;
  /** @format date-time */
  endTime: string;
  length: number;
}

export interface AppointmentSearchResult {
  /** Is the appointment covered by the OHC contract */
  isCoveredByOHC?: ContractRuleStatus;
  /**
   * Date and time of the appointment.
   * @format date-time
   */
  time: string;
  /** Duration of the appointment in minutes. */
  duration: number;
  /** Location of the appointment. */
  location: string;
  /** Translated name of the location. */
  locationName: string;
  locationShortName?: string;
  /** Translated location address. */
  locationAddress: string;
  /** Room identifier of the appointment. */
  locationRoomId?: string;
  /** ID of the specialist. */
  specialistId: number;
  /** First and last name of the specialist. */
  specialistName: string;
  /** The translated specialist's title. */
  specialistTitle: string;
  /** Rating of the specialist if permitted to show. */
  specialistRating?: number;
  /** Whether or not the specialist allows new Kela clients (defaults to true) */
  specialistAllowsNewKelaClients?: boolean;
  /** Is the practitioner resource list (RNRO = 50). */
  isResourceList?: boolean;
  /** Does the practitioner have flexible worklist. */
  hasFlexibleScheduleOptions?: boolean;
  /**
   * A media.mehilainen.fi resource.
   * @example "945979dbf6854066f9e2b19262e8909d.jpg"
   */
  image: string | null;
  /**
   * All possible types of the appointment
   * @example ["clinic","video","phone"]
   */
  appointmentTypes: ("video" | "phone" | "clinic" | "callback")[];
  /** Pointer to the appointment in the dx_aika table (DH). */
  appointmentId?: number;
  /** Appointment search result (AssisDent). */
  assisDentSearchResult?: AssisDentSearchResult;
  /** DEPRECATED: Is the specialist a team member of primary OHC team */
  isPrimaryOHCSpecialist?: boolean;
  /** Is the specialist a team member of primary OHC team, undefined if not */
  primarySpecialistRole?: "TTL" | "TTH" | "TFT" | "TPSY" | "YLEL" | "RATE" | "SOSSU";
  /** Id of the connection that found the appointment. */
  connectionId?: string;
  /** Id of the potential modal shown when clicking the appointment. */
  modalId?: string;
}

export interface SearchAppointmentSuggestion {
  type: "location" | "node" | "date";
  id: string;
  title: string;
  /** @format date-time */
  date?: string;
}

export interface SearchNodeAppointmentResults {
  daysInFuture?: number;
  modals?: ModalWithContractConditions[];
  appointments: AppointmentSearchResult[];
  suggestions: SearchAppointmentSuggestion[] | null;
}

/** Types of suggestions to search for. */
export enum SearchAppointmentSuggestionType {
  Location = "location",
  Node = "node",
  Date = "date",
}

export interface NodeSearchSuggestionsParams {
  types: SearchAppointmentSuggestionType[];
  /**
   * Dates after which to search for date suggestions.
   * @format date-time
   */
  datesAfter?: string;
  /** The params to search suggestions for. */
  nodeSearch: SearchNodeAppointmentsParams;
}

export interface CalendarSearchResult {
  /**
   * Date of appointment availability.
   * @format date-time
   * @example "2021-02-25"
   */
  date: string;
  /**
   * Status of availability for the given date.
   * @example true
   */
  isAvailable: boolean;
}

export interface AppointmentSearchByPractitionerParams {
  lang?: SupportedLanguage;
  /** @example ["video","phone"] */
  appointmentTypes: AppointmentType[];
  /** @example ["morning","afternoon","evening"] */
  timeRanges?: TimeRange[];
  /** Id of the practitioner for whom to search appointments for. */
  practitionerId: number;
  /**
   * Date for the search.
   * @format date-time
   */
  startDate: string;
  /**
   * Locations to filter results by.
   * @example ["clinic30","clinic338"]
   */
  location?: string[];
  /** Duration of appointments to search for. */
  duration?: number;
  /** Search by multiple durations. */
  durations?: number[];
  /**
   * Palveluhaku palvelu id.
   * Used to filter flexible schedule searches.
   */
  serviceId?: number;
  specialty?: number;
  isOhc?: boolean;
  nodeId?: string;
  reasonId?: string;
}

export interface PractitionerSearchResult {
  appointments: AppointmentSearchResult[];
  suggestions: SearchAppointmentSuggestion[] | null;
}

export interface CallbackSearchByPractitionerParams {
  lang?: SupportedLanguage;
  /** Practitioner id. */
  practitionerId: number;
  /**
   * Date for the search.
   * @format date-time
   */
  startDate: string;
  /**
   * Number of days in future to search for appointments.
   * Requires 'amount' to be set if over 31.
   * @min 0
   * @max 180
   */
  daysInFuture?: number;
}

export interface CallbackSearchResult {
  /** Is the appointment covered by the OHC contract */
  isCoveredByOHC?: ContractRuleStatus;
  /**
   * Date and time of the appointment.
   * @format date-time
   */
  time: string;
  /** Duration of the appointment in minutes. */
  duration: number;
  /** Location of the appointment. */
  location: string;
  /** Translated name of the location. */
  locationName: string;
  locationShortName?: string;
  /** Translated location address. */
  locationAddress: string;
  /** Room identifier of the appointment. */
  locationRoomId?: string;
  /** ID of the specialist. */
  specialistId: number;
  /** First and last name of the specialist. */
  specialistName: string;
  /** The translated specialist's title. */
  specialistTitle: string;
  /** Rating of the specialist if permitted to show. */
  specialistRating?: number;
  /** Whether or not the specialist allows new Kela clients (defaults to true) */
  specialistAllowsNewKelaClients?: boolean;
  /** Is the practitioner resource list (RNRO = 50). */
  isResourceList?: boolean;
  /** Does the practitioner have flexible worklist. */
  hasFlexibleScheduleOptions?: boolean;
  /**
   * A media.mehilainen.fi resource.
   * @example "945979dbf6854066f9e2b19262e8909d.jpg"
   */
  image: string | null;
  /**
   * All possible types of the appointment
   * @example ["clinic","video","phone"]
   */
  appointmentTypes: ("video" | "phone" | "clinic" | "callback")[];
  /** Pointer to the appointment in the dx_aika table (DH). */
  appointmentId?: number;
  /** Appointment search result (AssisDent). */
  assisDentSearchResult?: AssisDentSearchResult;
  /** DEPRECATED: Is the specialist a team member of primary OHC team */
  isPrimaryOHCSpecialist?: boolean;
  /** Is the specialist a team member of primary OHC team, undefined if not */
  primarySpecialistRole?: "TTL" | "TTH" | "TFT" | "TPSY" | "YLEL" | "RATE" | "SOSSU";
  /** Id of the connection that found the appointment. */
  connectionId?: string;
  /** Id of the potential modal shown when clicking the appointment. */
  modalId?: string;
}

export interface PractitionerCallbackSearchResult {
  appointments: CallbackSearchResult[];
}

export interface AppointmentPriceParams {
  lang?: SupportedLanguage;
  /**
   * The appointment ID. Either DH PTR as a number or internal AssisDent id as a string.
   * @example 1234
   */
  appointmentId: string | number;
  /** @format date-time */
  date: string;
  nodeId?: string;
  connectionId?: string;
  /** @deprecated */
  specialtyId?: number;
  serviceId?: number;
  duration?: number;
  specialtyIds?: number[];
}

export interface AppointmentPrice {
  source: ElectronicHealthRecordSystem;
  appointmentType: "video" | "phone" | "clinic" | "callback";
  price: number;
  priceAfterKelaCompensation?: number;
  minPrice: number | null;
  maxPrice: number | null;
  minPriceAfterKelaCompensation: number | null;
  maxPriceAfterKelaCompensation: number | null;
  pricingInfo?: string;
}

export interface InsurancePayerOption {
  id: string;
  name: string;
  additionalInfoForm: string | null;
}

export interface InsuranceCompany {
  id: number;
  name: string;
  bookingInstructions: string | null;
  insurancePayerOptions: InsurancePayerOption[];
}

export interface GetDirectCompensationPermitsResult {
  id: string;
  i18n: {
    title?: {
      fi: string;
      en: string;
      sv: string;
    };
    subtitle?: {
      fi: string;
      en: string;
      sv: string;
    };
    subtitle2?: {
      fi: string;
      en: string;
      sv: string;
    };
  };
}

export interface GetCustomershipResult {
  hasCustomership: boolean;
}

export interface GetOptionsResult {
  checkCustomership: boolean;
}

export interface UserWithDelegateRole {
  isDelegateUser: boolean;
  id: number;
  fullName: string;
  firstName: string;
  lastName: string;
  ssn: string;
  email: string;
  phone: string;
  marketingPermits?: MarketingPermits;
  serviceMessagePermit?: boolean;
  hasAddress: boolean;
  address?: Address;
  omUid: number;
  employeeId?: number;
}

export interface SessionInitParams {
  token: string;
}

export interface Service {
  type: string;
  id: object;
}

export interface SessionInitParamsAV2 {
  userData?: string;
  service?: Service;
  locations?: string;
  language?: string;
  sourceService?: string;
}

export interface AuthorizedPerson {
  omUid: number;
  firstName: string;
  lastName: string;
}

export interface User {
  id: number;
  fullName: string;
  firstName: string;
  lastName: string;
  ssn: string;
  email: string;
  phone: string;
  marketingPermits?: MarketingPermits;
  serviceMessagePermit?: boolean;
  hasAddress: boolean;
  address?: Address;
  omUid: number;
  employeeId?: number;
}

export interface AppointmentLockParams {
  appointmentId: number;
  duration?: number;
  /**
   * @min 300
   * @max 1800
   */
  lockLength?: number;
  nodeId?: number;
}

export interface AppointmentLockResult {
  appointmentIds: number[];
  /** @format date-time */
  endDateTime: string;
}

export interface AppointmentUnlockParams {
  appointmentIds: number[];
}

export interface PractitionerData {
  id: number;
  title: string | null;
  image: string | null;
  privateOnly: boolean;
}

export interface SearchKeywordData {
  id: string;
  numNodes: number;
  nodes: string[];
}

export interface NodeData {
  id: string;
  numChildren: number;
  weight: number;
  serviceType: "private" | "ohc" | "both" | null;
}

export interface NodeAutocompleteResult {
  keyword: string;
  practitionerData?: PractitionerData;
  searchKeywordData?: SearchKeywordData;
  nodeData?: NodeData;
  groupData?: NodeAutocompleteResult[];
}

export interface LocationAutocompleteResult {
  type: "all" | "city" | "region" | "clinic";
  id: number;
  clinicIDs: string[];
  name: string;
  address: string | null;
  phone: string | null;
  imageUrl: string | null;
  lat: number | null;
  long: number | null;
  clinicType: string;
  priority: number;
  favorite: boolean;
  locationTypes: number[];
}

export interface ParseBodyParams {
  userData?: string;
  envelope?: string;
  sourceService?: "APP" | "OM";
  language?: "fi" | "en" | "sv";
  campaignString?: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Mehiläinen AV API
 * @version 41.5.1
 * @contact
 *
 * Mehiläinen Ajanvaraus API:n kuvaus
 */
export class Api<SecurityDataType extends unknown, IHttpClient extends HttpClient<SecurityDataType>> {
  http: IHttpClient;

  constructor(http: IHttpClient) {
    this.http = http;
  }

  v1 = {
    /**
     * No description
     *
     * @tags public
     * @name GetLocations
     * @request GET:/v1/public/locations
     */
    getLocations: (
      query: {
        lang: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<LocationResult[], any>({
        path: `/v1/public/locations`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags public
     * @name GetGeolocateResults
     * @request GET:/v1/public/locations/geolocate
     */
    getGeolocateResults: (
      query: {
        lang: "fi" | "en" | "sv";
        lat: number;
        long: number;
        /** @deprecated */
        acceptedLocationTypes?: number[];
        nodeId?: string;
        isOHC?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<LocationResult[], any>({
        path: `/v1/public/locations/geolocate`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags public
     * @name GetLanguages
     * @request GET:/v1/public/languages
     */
    getLanguages: (
      query: {
        lang: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<LanguageResult[], any>({
        path: `/v1/public/languages`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags public
     * @name GetAgeGroups
     * @request GET:/v1/public/age-groups
     */
    getAgeGroups: (
      query: {
        lang: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PatientAgeGroup[], any>({
        path: `/v1/public/age-groups`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags public
     * @name GetPractitionerDetails
     * @request GET:/v1/public/practitioner-details
     */
    getPractitionerDetails: (
      query: {
        lang?: SupportedLanguage;
        practitionerId: number;
        isOhc?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PractitionerDetails, any>({
        path: `/v1/public/practitioner-details`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags public
     * @name Translations
     * @request GET:/v1/public/translations
     */
    translations: (params: RequestParams = {}) =>
      this.http.request<object, any>({
        path: `/v1/public/translations`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags public
     * @name GetNotifications
     * @request GET:/v1/public/notifications
     */
    getNotifications: (
      query: {
        lang: string;
        locations: string[];
        nodeId: string;
        isOHC: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SimpleNotification[], any>({
        path: `/v1/public/notifications`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags public
     * @name GetDkNotifications
     * @request GET:/v1/public/digitalclinic-notifications
     */
    getDkNotifications: (
      query: {
        lang: string;
        locations: string[];
        nodeId: string;
        isOHC: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<DCNotification[], any>({
        path: `/v1/public/digitalclinic-notifications`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags public
     * @name GetServiceName
     * @request GET:/v1/public/service-name
     */
    getServiceName: (
      query: {
        lang: string;
        serviceId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<ServiceName, any>({
        path: `/v1/public/service-name`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags public
     * @name GetServiceDetails
     * @request GET:/v1/public/service-details
     */
    getServiceDetails: (
      query: {
        lang: string;
        serviceId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<ServiceDetails, any>({
        path: `/v1/public/service-details`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags public
     * @name GetSpecialtyDetails
     * @request GET:/v1/public/specialty-details
     */
    getSpecialtyDetails: (
      query: {
        lang: string;
        specialtyId: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SpecialtyDetails, any>({
        path: `/v1/public/specialty-details`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags public
     * @name GetBookingInstructions
     * @request GET:/v1/public/booking-instructions
     */
    getBookingInstructions: (
      query: {
        lang: string;
        serviceId?: number;
        nodeId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<BookingInstructions | null, any>({
        path: `/v1/public/booking-instructions`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ohc
     * @name GetContract
     * @request GET:/v1/ohc/contract
     */
    getContract: (params: RequestParams = {}) =>
      this.http.request<object, any>({
        path: `/v1/ohc/contract`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ohc
     * @name GetContractDates
     * @request GET:/v1/ohc/contract-dates
     */
    getContractDates: (params: RequestParams = {}) =>
      this.http.request<ContractUsePeriods, any>({
        path: `/v1/ohc/contract-dates`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ohc
     * @name GetContractDates2
     * @request GET:/v1/ohc/contract-info
     * @originalName getContractDates
     * @duplicate
     */
    getContractDates2: (params: RequestParams = {}) =>
      this.http.request<ContractUsePeriods, any>({
        path: `/v1/ohc/contract-info`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ohc
     * @name GetOhcLocations
     * @request GET:/v1/ohc/locations
     */
    getOhcLocations: (
      query: {
        lang: string;
        nodeId: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<LocationResult[], any>({
        path: `/v1/ohc/locations`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags settings
     * @name FeatureFlags
     * @request GET:/v1/settings/feature-flags
     */
    featureFlags: (params: RequestParams = {}) =>
      this.http.request<FeatureFlag[], any>({
        path: `/v1/settings/feature-flags`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags search-nodes
     * @name SearchNode
     * @request GET:/v1/search-nodes
     */
    searchNode: (
      query?: {
        lang?: SupportedLanguage;
        nodeId?: string;
        keywordId?: string;
        /**
         * @minLength 3
         * @maxLength 128
         */
        keyword?: string;
        isOhc?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PublicNode[], any>({
        path: `/v1/search-nodes`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags search-nodes
     * @name FavoriteNodes
     * @request GET:/v1/search-nodes/favorites
     */
    favoriteNodes: (
      query: {
        menu: string;
        lang: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PublicNode[], any>({
        path: `/v1/search-nodes/favorites`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags search-nodes
     * @name SearchNodes
     * @request GET:/v1/search-nodes/listed
     */
    searchNodes: (
      query: {
        lang: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PublicNode[], any>({
        path: `/v1/search-nodes/listed`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags search-nodes
     * @name AllNodes
     * @request GET:/v1/search-nodes/all
     */
    allNodes: (
      query: {
        lang: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PublicNode[], any>({
        path: `/v1/search-nodes/all`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags search-nodes
     * @name SearchNodeLinkings
     * @request GET:/v1/search-nodes/linkings
     */
    searchNodeLinkings: (params: RequestParams = {}) =>
      this.http.request<SearchNodeLinking[], any>({
        path: `/v1/search-nodes/linkings`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags search-nodes
     * @name GetOhcNodes
     * @request GET:/v1/search-nodes/ohc/listed
     */
    getOhcNodes: (
      query: {
        lang: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PublicNode[], any>({
        path: `/v1/search-nodes/ohc/listed`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags modals
     * @name GetModals
     * @request GET:/v1/modals
     */
    getModals: (
      query: {
        lang: string;
        isOHC: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<Modal[], any>({
        path: `/v1/modals`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags reservations
     * @name Reserve
     * @request POST:/v1/reservations
     */
    reserve: (data: ReserveParams, params: RequestParams = {}) =>
      this.http.request<ReserveResult, any>({
        path: `/v1/reservations`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags reservations
     * @name CancelAppointment
     * @request DELETE:/v1/reservations
     */
    cancelAppointment: (data: CancelAppointmentParams, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/v1/reservations`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags reservations
     * @name CancellationInfo
     * @request GET:/v1/reservations/cancellation-info
     */
    cancellationInfo: (
      query: {
        lang?: SupportedLanguage;
        reservationId: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<CancellationInfo, any>({
        path: `/v1/reservations/cancellation-info`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags reservations
     * @name ReservationInfo
     * @request GET:/v1/reservations/reservation-info
     */
    reservationInfo: (
      query: {
        reservationId: string;
        lang: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<AppointmentReservationInfo, any>({
        path: `/v1/reservations/reservation-info`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags reservations
     * @name ReservationPaymentTypes
     * @request GET:/v1/reservations/reservation-payment-types
     */
    reservationPaymentTypes: (
      query: {
        /** The appointment ID. Either DH PTR or internal AssisDent id as a string. */
        appointmentId: string;
        isOhc?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<ReservationPaymentTypesResponse, any>({
        path: `/v1/reservations/reservation-payment-types`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags reservations
     * @name CallbackReserveAllowed
     * @request POST:/v1/reservations/callback-reserve-allowed
     */
    callbackReserveAllowed: (data: CallbackReservationAllowedParams, params: RequestParams = {}) =>
      this.http.request<CallbackAllowedResponse, any>({
        path: `/v1/reservations/callback-reserve-allowed`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointments
     * @name AppointmentInfo
     * @request GET:/v1/appointments
     */
    appointmentInfo: (
      query: {
        lang?: SupportedLanguage;
        /** The appointment ID. Either DH PTR or internal AssisDent id as a string. */
        appointmentId: string;
        nodeId?: string;
        connectionId?: string;
        isOHC?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<AppointmentInfoResult, any>({
        path: `/v1/appointments`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointments
     * @name GetAppointmentLengthOptions
     * @request GET:/v1/appointments/appointment-length-options
     */
    getAppointmentLengthOptions: (
      query: {
        lang?: SupportedLanguage;
        appointmentId: number;
        nodeId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<AppointmentLengthOption[], any>({
        path: `/v1/appointments/appointment-length-options`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointments
     * @name NodeAppointmentSearch
     * @request POST:/v1/appointments/search
     */
    nodeAppointmentSearch: (data: SearchNodeAppointmentsParams, params: RequestParams = {}) =>
      this.http.request<SearchNodeAppointmentResults, any>({
        path: `/v1/appointments/search`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointments
     * @name NodeSearchSuggestions
     * @request POST:/v1/appointments/search/suggestions
     */
    nodeSearchSuggestions: (data: NodeSearchSuggestionsParams, params: RequestParams = {}) =>
      this.http.request<SearchAppointmentSuggestion[], any>({
        path: `/v1/appointments/search/suggestions`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointments
     * @name NodeSearchCalendar
     * @request POST:/v1/appointments/search/calendar
     */
    nodeSearchCalendar: (data: SearchNodeAppointmentsParams, params: RequestParams = {}) =>
      this.http.request<CalendarSearchResult[], any>({
        path: `/v1/appointments/search/calendar`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointments
     * @name AvailablePractitionersSearch
     * @request POST:/v1/appointments/search/available-practitioners
     */
    availablePractitionersSearch: (data: SearchNodeAppointmentsParams, params: RequestParams = {}) =>
      this.http.request<AppointmentSearchResult[], any>({
        path: `/v1/appointments/search/available-practitioners`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointments
     * @name PractitionerAppointmentSearch
     * @request POST:/v1/appointments/search/practitioner
     */
    practitionerAppointmentSearch: (data: AppointmentSearchByPractitionerParams, params: RequestParams = {}) =>
      this.http.request<PractitionerSearchResult, any>({
        path: `/v1/appointments/search/practitioner`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointments
     * @name PractitionerAppointmentSearchCalendar
     * @request POST:/v1/appointments/search/practitioner/calendar
     */
    practitionerAppointmentSearchCalendar: (data: AppointmentSearchByPractitionerParams, params: RequestParams = {}) =>
      this.http.request<CalendarSearchResult[], any>({
        path: `/v1/appointments/search/practitioner/calendar`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointments
     * @name PractitionerCallbackSearch
     * @request POST:/v1/appointments/search/practitioner/callback
     */
    practitionerCallbackSearch: (data: CallbackSearchByPractitionerParams, params: RequestParams = {}) =>
      this.http.request<PractitionerCallbackSearchResult, any>({
        path: `/v1/appointments/search/practitioner/callback`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointments
     * @name PractitionerCallbackSearchCalendar
     * @request POST:/v1/appointments/search/practitioner/callback/calendar
     */
    practitionerCallbackSearchCalendar: (data: CallbackSearchByPractitionerParams, params: RequestParams = {}) =>
      this.http.request<CalendarSearchResult[], any>({
        path: `/v1/appointments/search/practitioner/callback/calendar`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointments
     * @name GetNodeFlexibleSearchOptions
     * @request GET:/v1/appointments/flexible-search-options
     */
    getNodeFlexibleSearchOptions: (
      query: {
        nodeId: string;
        locations: string[];
      },
      params: RequestParams = {},
    ) =>
      this.http.request<number[], any>({
        path: `/v1/appointments/flexible-search-options`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointments
     * @name AppointmentPrice
     * @request POST:/v1/appointments/appointment-price
     */
    appointmentPrice: (data: AppointmentPriceParams, params: RequestParams = {}) =>
      this.http.request<AppointmentPrice[], any>({
        path: `/v1/appointments/appointment-price`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance
     * @name InsuranceCompanies
     * @request GET:/v1/insurance/companies
     */
    insuranceCompanies: (
      query: {
        lang: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<InsuranceCompany[], any>({
        path: `/v1/insurance/companies`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance
     * @name InsuranceDirectCompensationPermits
     * @request GET:/v1/insurance/direct-compensation-permits
     */
    insuranceDirectCompensationPermits: (
      query: {
        subjectOmUid: number;
        insuranceCompanyId: string;
        lang: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<GetDirectCompensationPermitsResult[], any>({
        path: `/v1/insurance/direct-compensation-permits`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance
     * @name InsuranceCustomership
     * @request GET:/v1/insurance/customership
     */
    insuranceCustomership: (
      query: {
        subjectOmUid: number;
        insuranceCompanyId: string;
        lang: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<GetCustomershipResult, any>({
        path: `/v1/insurance/customership`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags insurance
     * @name InsuranceOptions
     * @request GET:/v1/insurance/options
     */
    insuranceOptions: (
      query: {
        subjectOmUid: number;
        insuranceCompanyId: string;
        lang: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<GetOptionsResult, any>({
        path: `/v1/insurance/options`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name GetUser
     * @request GET:/v1/user
     */
    getUser: (params: RequestParams = {}) =>
      this.http.request<UserWithDelegateRole, any>({
        path: `/v1/user`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name InitsessionJwt
     * @request POST:/v1/user/init-session-jwt
     */
    initsessionJwt: (data: SessionInitParams, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/v1/user/init-session-jwt`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name InitSessionAv2
     * @request POST:/v1/user/init-session-av2
     */
    initSessionAv2: (data: SessionInitParamsAV2, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/v1/user/init-session-av2`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name Logout
     * @request POST:/v1/user/logout
     */
    logout: (params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/v1/user/logout`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name Heartbeat
     * @request POST:/v1/user/heartbeat
     */
    heartbeat: (params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/v1/user/heartbeat`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name Add
     * @request GET:/v1/user/authorizations/add
     */
    add: (
      query: {
        origin: string;
        lang: "fi" | "en" | "sv";
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, any>({
        path: `/v1/user/authorizations/add`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name GetAuthorizations
     * @request GET:/v1/user/authorizations
     */
    getAuthorizations: (params: RequestParams = {}) =>
      this.http.request<AuthorizedPerson[], any>({
        path: `/v1/user/authorizations`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name GetPrincipalUser
     * @request GET:/v1/user/authorizations/principal/{omUid}
     */
    getPrincipalUser: (omUid: number, params: RequestParams = {}) =>
      this.http.request<User, any>({
        path: `/v1/user/authorizations/principal/${omUid}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointment-locks
     * @name LockAppointment
     * @request POST:/v1/appointment-locks
     */
    lockAppointment: (data: AppointmentLockParams, params: RequestParams = {}) =>
      this.http.request<AppointmentLockResult, any>({
        path: `/v1/appointment-locks`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags appointment-locks
     * @name UnlockAppointment
     * @request DELETE:/v1/appointment-locks
     */
    unlockAppointment: (data: AppointmentUnlockParams, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/v1/appointment-locks`,
        method: "DELETE",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags autocomplete
     * @name AutocompleteNode
     * @request GET:/v1/autocomplete/node
     */
    autocompleteNode: (
      query: {
        searchString: string;
        lang: string;
        limit: number;
        ohc: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<NodeAutocompleteResult[], any>({
        path: `/v1/autocomplete/node`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags autocomplete
     * @name AutocompleteLocation
     * @request GET:/v1/autocomplete/location
     */
    autocompleteLocation: (
      query: {
        searchString: string;
        lang: string;
        limit: number;
        ohc: boolean;
        nodeId: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<LocationAutocompleteResult[], any>({
        path: `/v1/autocomplete/location`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  parse = {
    /**
     * No description
     *
     * @tags parse
     * @name ParseGet
     * @request GET:/parse/*
     */
    parseGet: (
      query: {
        language: string;
        isRedirected: boolean;
        noLanding: boolean;
        envelope: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<void, any>({
        path: `/parse/*`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags parse
     * @name ParsePost
     * @request POST:/parse/*
     */
    parsePost: (data: ParseBodyParams, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/parse/*`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
}
