import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Gray600 } from '@mehilainen/design-system-tokens/colors'
import { Ban, MapMarker, Video, Location } from '@mehilainen/mds-customer/icons'
import { Box, Divider } from '@mui/material'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'

import {
  LocationResult,
  LocationType,
  PublicNode,
  SupportedLanguage,
} from '../../../__generated__/api'
import { isOHCAtom } from '../../../state/common/atoms'
import { getSelectedNodeOrDefault } from '../../../state/search/selectors'
import { useIsMobile } from '../../hooks/useBreakpoint'
import { useLocationSearch } from '../../hooks/useSearch'
import { getGeolocateResults } from '../../services/geolocate'
import { scale } from '../../utils/scale'
import { CenteredRowFlex } from '../Layout/Layout'
import ResponsiveModal from '../Modal/ResponsiveModal/ResponsiveModal'
import Search from '../Search/Search'
import { SearchTreeItem } from '../Search/types'
import SelectInput from '../SelectInput/SelectInput'

import {
  convertGeolocateResultToSearchTreeItem,
  convertLocationsToSearchTreeItems,
  isAllGeolocateLocation,
  isGeolocateLocation,
  isLocationSupported,
} from './utils'

const LocationContainer = styled.div<{ isMobile?: boolean }>`
  padding: ${(props) => (props.isMobile ? '0 0 24px 0' : '32px 24px 32px 24px')};
`

const AutocompleteOptionContainer = styled(CenteredRowFlex)<{
  disabled: boolean
  hasIcon: boolean
}>`
  svg {
    color: black;
    width: 16px;
    height: 16px;
    margin-right: ${scale(0.75)};
  }

  ${(props) =>
    !props.hasIcon &&
    css`
      padding-left: ${scale(3)};
    `}

  ${(props) =>
    props.disabled &&
    css`
      color: ${Gray600};
    `}
`

const AutocompleteIconContainer = styled.div`
  width: ${scale(3)};
`

const AutocompleteGroupTitle = styled.div`
  font-size: 0.75rem;
  color: ${Gray600};
  padding: ${scale(0.5)} ${scale(5)};
`

interface Props {
  locations: LocationResult[]
  value: string[]
  selectedNode: PublicNode | null
  onSelect(location: string[]): void
}

const LocationSelect: React.FC<React.PropsWithChildren<Props>> = ({
  locations,
  value,
  selectedNode,
  onSelect,
}) => {
  const { t, i18n } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)
  const [searchResult, setSearchResult] = useState<Array<SearchTreeItem<LocationResult>> | null>([])
  const [breadcrumb, setBreadcrumb] = useState<Array<SearchTreeItem<LocationResult>>>([])
  const [searchPending, setSearchPending] = useState<boolean>(false)
  const isMobile = useIsMobile()
  const supportedLocationTypes = useMemo(
    () => selectedNode?.locationTypes ?? [],
    [selectedNode?.locationTypes]
  )

  const isOhc = useRecoilValue(isOHCAtom)
  const nodeId = useRecoilValue(getSelectedNodeOrDefault)
  const locationSearch = useLocationSearch(isOhc, nodeId)

  const closeCallback = useCallback(() => {
    setBreadcrumb([])
    setOpen(false)
  }, [])

  const allData = useMemo(
    () => [
      convertGeolocateResultToSearchTreeItem(t),
      ...convertLocationsToSearchTreeItems(
        locations,
        t('component.locationSelect.rootSearchItemTitle'),
        t('component.locationSelect.rootSearchItemSubtitle'),
        supportedLocationTypes
      ),
    ],
    [locations, t, supportedLocationTypes]
  )
  const popularData = useMemo(() => allData.filter((i) => i.item?.favorite === true), [allData])

  const selectAutocompleteOptionCallback = useCallback(
    (option: LocationResult) => {
      onSelect([option.uniqueId])
      closeCallback()
    },
    [onSelect, closeCallback]
  )

  const selectedName =
    value.length === 1
      ? locations.find((location) => value[0] === location.uniqueId)?.name
      : t('component.locationSelect.multipleSelected')

  return (
    <>
      <SelectInput
        label={t('component.locationSelect.label')}
        value={selectedName}
        onClick={() => setOpen(true)}
        dataCy="locationSelect-textField"
      />
      <ResponsiveModal
        open={open}
        onClose={closeCallback}
        fullWidth
        disableMobileClose
        omitDefaultPadding
        sx={{
          '& .MuiDialog-paper': {
            overflowY: 'visible',
          },
        }}
      >
        <LocationContainer isMobile={isMobile}>
          <Search
            searchFieldLabel={t('component.locationSelect.searchFieldLabel')}
            searchResult={searchResult}
            allData={allData}
            popularData={popularData}
            breadcrumb={breadcrumb}
            onBreadcrumbChange={(crumb) => {
              setBreadcrumb(crumb)
              setSearchResult([])
            }}
            searchPending={searchPending}
            onSelectAndClose={(location) => {
              if (isGeolocateLocation(location)) {
                setSearchPending(true)
                getGeolocateResults(
                  i18n.language as SupportedLanguage,
                  selectedNode?.id,
                  isOhc
                ).then((result) => {
                  setSearchPending(false)
                  setBreadcrumb((prev) => [
                    ...prev,
                    convertGeolocateResultToSearchTreeItem(t, result),
                  ])
                })
                return
              } else if (isAllGeolocateLocation(location)) {
                onSelect(location.clinicIDs)
              } else {
                onSelect([location.uniqueId])
              }
              closeCallback()
            }}
            onClose={closeCallback}
            iconProvider={(location) =>
              isGeolocateLocation(location.item) || isAllGeolocateLocation(location.item) ? (
                <Location />
              ) : location.item?.type === LocationType.Clinic ? (
                `${process.env.REACT_APP_NODE_ICON_URL}mehilainen.svg`
              ) : location.item?.type === LocationType.All ? (
                <Video />
              ) : (
                <MapMarker />
              )
            }
            placeholder={t('component.locationSelect.placeholder')}
            searchIndex={locationSearch}
            onAutocompleteOptionSelect={selectAutocompleteOptionCallback}
            renderAutocompleteOption={(props, option, state) => (
              <li {...props}>
                <Box {...state}>
                  {isLocationSupported(option, supportedLocationTypes) ? (
                    <AutocompleteOptionContainer
                      hasIcon={option.type !== LocationType.Clinic}
                      disabled={false}
                    >
                      {option.type !== LocationType.Clinic && (
                        <AutocompleteIconContainer>
                          <MapMarker />
                        </AutocompleteIconContainer>
                      )}
                      <span>{option.name}</span>
                    </AutocompleteOptionContainer>
                  ) : (
                    <AutocompleteOptionContainer disabled={true} hasIcon={true}>
                      <AutocompleteIconContainer>
                        <Ban htmlColor={Gray600} />
                      </AutocompleteIconContainer>
                      <span>{option.name}</span>
                    </AutocompleteOptionContainer>
                  )}
                </Box>
              </li>
            )}
            getOptionLabel={(option) => option.name}
            popularHeading={t('component.locationSelect.popularHeading')}
            popularLabel={t('component.locationSelect.popularLabel')}
            allDataHeading={t('component.locationSelect.allDataHeading')}
            allDataLabel={t('component.locationSelect.allDataLabel')}
            optionListModifier={(options) =>
              options
                .map((option) => ({
                  ...option,
                  supported: isLocationSupported(option, supportedLocationTypes),
                }))
                .sort(
                  (a, b) => Number(b.supported) - Number(a.supported) || a.priority - b.priority
                )
            }
            groupBy={(option) =>
              isLocationSupported(option, supportedLocationTypes)
                ? ''
                : t('component.locationSelect.unsupportedLocationsTitle')
            }
            renderGroup={(params) => (
              <div key={params.key}>
                {params.group && <Divider color={Gray600} />}
                <AutocompleteGroupTitle>{params.group}</AutocompleteGroupTitle>
                {params.children}
              </div>
            )}
            open={open}
          />
        </LocationContainer>
      </ResponsiveModal>
    </>
  )
}

export default LocationSelect
