import { FlexibleScheduleOption, PractitionerDetails } from '../../__generated__/api'
import { GroupedFlexibleScheduleOption } from '../../common/components/AppointmentLengthSelect/FlexibleScheduleAppointmentLengthSelect'

export const groupFlexibleScheduleOptions = (
  practitionerDetails: PractitionerDetails,
  language: string
): GroupedFlexibleScheduleOption[] => {
  const groupedByService = practitionerDetails.flexibleScheduleOptions.reduce<
    Map<number, FlexibleScheduleOption[]>
  >((acc, option) => {
    return acc.set(option.serviceId, [...(acc.get(option.serviceId) ?? []), option])
  }, new Map())

  return Array.from(groupedByService)
    .reduce<GroupedFlexibleScheduleOption[]>((acc, [serviceId, options]) => {
      const locations = Array.from(
        options.reduce<Set<string>>((locationsAcc, option) => {
          return new Set([...locationsAcc, option.locationId])
        }, new Set())
      )

      const availableDurations = Array.from(
        options.reduce<Set<number>>((durationsAcc, option) => {
          return new Set([...durationsAcc, ...option.availableDurations].sort((a, b) => a - b))
        }, new Set())
      )

      const serviceName = options[0].serviceName

      acc.push({ serviceId, locations, availableDurations, serviceName })
      return acc
    }, [])
    .sort((a, b) => a.serviceName.localeCompare(b.serviceName, language))
}
