import api from './api'

const dialogProps = 'left=100,top=100,width=600,height=600'

const createAuthUrl = (options: { provider: string; locale: string }) => {
  if (options.provider) {
    const authUrl = new URL(process.env.REACT_APP_AUTH_URL || '')
    authUrl.pathname = `/identity/jwt/${options.provider}`
    authUrl.searchParams.append('origin', location.protocol + '//' + location.host)
    authUrl.searchParams.append('locale', options.locale)
    return authUrl.toString()
  }

  throw new Error('No provider nor hetu')
}

const receiveTokenFromMessage = () =>
  new Promise<string>((resolve, reject) => {
    window.addEventListener('message', function parseTokenFromMessage(event: MessageEvent) {
      if (event.origin === process.env.REACT_APP_AUTH_URL) {
        try {
          window.removeEventListener('message', parseTokenFromMessage)
          const token: string | null = JSON.parse(event.data).token || null
          if (token) {
            resolve(token)
          } else {
            reject(new Error('Did not receive token from API'))
          }
        } catch (error) {
          reject(error)
        }
      }
    })
  })

const isIE =
  typeof navigator !== 'undefined' &&
  (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1)

class AuthenticationService {
  public logout = async () => {
    await api.v1.logout({})
  }

  public login = async (
    options: { provider: string; locale: string },
    onSuccessCallback?: (token: string) => void,
    userNotFoundCallback?: () => void
  ) => {
    try {
      const authUrl = createAuthUrl(options)

      let dialog
      if (isIE) {
        dialog = window.open('/', 'Login', dialogProps)
        dialog!.location.href = 'about:blank'
        dialog!.location.href = authUrl
      } else {
        dialog = window.open(authUrl, 'Login', dialogProps)
      }
      const token = await receiveTokenFromMessage()
      const createSessionUrl = new URL(process.env.REACT_APP_API + '/v1/user/init-session-jwt')

      await fetch(createSessionUrl.toString(), {
        method: 'POST',
        body: JSON.stringify({ token }),
        mode: 'cors',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          if (res.status === 404) {
            userNotFoundCallback?.()
          } else {
            onSuccessCallback?.(token)
          }
        })
        .catch(() => {
          throw new Error('Failed to initialize session')
        })
    } catch (error) {
      throw new Error('Login failed')
    }
  }
}

export default new AuthenticationService()
