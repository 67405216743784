import * as qs from 'qs'
import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'

import { AppointmentType, SupportedLanguage } from '../../../__generated__/api'
import useChangeLanguage from '../../../common/hooks/useChangeLanguage'
import {
  marketingCampaignCodeAtom,
  selectedAppointmentLengthAtom,
  selectedAppointmentNodeConnectionIdAtom,
  selectedAppointmentNodeIdAtom,
  selectedAppointmentServiceIdAtom,
  selectedAppointmentTypeAtom,
} from '../../../state/common/atoms'
import { initialReserveHistoryRestoredAtom } from '../../../state/reserve/atoms'
import { AppointmentSearchMode, appointmentSearchModeAtom } from '../../../state/search/atoms'

const useReserveHistory = (): void => {
  const history = useHistory()

  const [selectedAppointmentType, setSelectedAppointmentType] = useRecoilState(
    selectedAppointmentTypeAtom
  )
  const [selectedAppointmentLength, setSelectedAppointmentLength] = useRecoilState(
    selectedAppointmentLengthAtom
  )

  const [selectedConnectionId, setSelectedConnectionId] = useRecoilState(
    selectedAppointmentNodeConnectionIdAtom
  )

  const [selectedNodeId, setSelectedNodeId] = useRecoilState(selectedAppointmentNodeIdAtom)

  const [selectedService, setSelectedService] = useRecoilState(selectedAppointmentServiceIdAtom)
  const setInitialReserveHistoryRestored = useSetRecoilState(initialReserveHistoryRestoredAtom)

  const { currentLanguage, changeLanguage } = useChangeLanguage()

  const [appointmentSearchMode, setAppointmentSearchMode] =
    useRecoilState(appointmentSearchModeAtom)

  const [marketingCampaignCode, setMarketingCampaignCode] =
    useRecoilState(marketingCampaignCodeAtom)

  // Deserialization fn to restore state from url
  const restoreFromHistory = useCallback(
    (searchString: string) => {
      if (searchString !== '') {
        const parsed = qs.parse(searchString)

        if (parsed.length && typeof parsed.length === 'string') {
          if (parsed.length === 'default') {
            setSelectedAppointmentLength('default')
          } else {
            const parsedAppointmentLength = parseInt(parsed.length, 10)
            if (!isNaN(parsedAppointmentLength)) {
              setSelectedAppointmentLength(parsedAppointmentLength)
            }
          }
        }

        parsed.lang && changeLanguage(String(parsed.lang) as SupportedLanguage)

        if (parsed.node && typeof parsed.node === 'string') {
          const parsedNode = parsed.node
          setSelectedNodeId(parsedNode)
        }

        if (parsed.conn && typeof parsed.conn === 'string') {
          const parsedConn = parsed.conn
          setSelectedConnectionId(parsedConn)
        }

        if (
          parsed.service &&
          typeof parsed.service === 'string' &&
          parsed.service !== 'undefined'
        ) {
          const parsedService = parsed.service
          setSelectedService(parseInt(parsedService, 10))
        }

        if (parsed.mode && typeof parsed.mode === 'string') {
          const parsedMode = parsed.mode
          if (parsedMode === AppointmentSearchMode.CALLBACK) {
            setAppointmentSearchMode(AppointmentSearchMode.CALLBACK)
            setSelectedAppointmentType(AppointmentType.Callback)
          } else {
            setAppointmentSearchMode(AppointmentSearchMode.REGULAR)
            setSelectedAppointmentType(undefined)
          }
        }

        if (parsed.type && typeof parsed.type === 'string') {
          const parsedAppointmentType = parsed.type

          if (Object.values(AppointmentType).includes(parsedAppointmentType as AppointmentType)) {
            setSelectedAppointmentType(parsedAppointmentType as AppointmentType)
          }
        }

        if (parsed.marketingCampaignCode && typeof parsed.marketingCampaignCode === 'string') {
          setMarketingCampaignCode(parsed.marketingCampaignCode)
        }
      }
      setInitialReserveHistoryRestored(true)
    },
    [
      changeLanguage,
      setInitialReserveHistoryRestored,
      setSelectedAppointmentType,
      setSelectedAppointmentLength,
      setSelectedService,
      setSelectedNodeId,
      setAppointmentSearchMode,
      setSelectedConnectionId,
      setMarketingCampaignCode,
    ]
  )

  useEffect(() => {
    restoreFromHistory(history.location.search.slice(1))
    return history.listen((location, action) => {
      if (action !== 'REPLACE') {
        restoreFromHistory(history.location.search.slice(1))
      }
    })
  }, [restoreFromHistory, history])

  // Serialize relevant state to url query string
  useEffect(() => {
    history.replace({
      search: qs.stringify({
        type: selectedAppointmentType,
        length: selectedAppointmentLength,
        service: selectedService || undefined,
        lang: currentLanguage,
        node: selectedNodeId,
        conn: selectedConnectionId,
        marketingCampaignCodeAtom: marketingCampaignCode,
      }),
    })
  }, [
    history,
    selectedAppointmentType,
    selectedAppointmentLength,
    currentLanguage,
    selectedService,
    selectedNodeId,
    selectedConnectionId,
    appointmentSearchMode,
    marketingCampaignCode,
  ])
}

export default useReserveHistory
