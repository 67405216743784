import { useState, useEffect, useCallback } from 'react'

export type AuthProvider = {
  id: string
  name: string
  img: string
  url: string
}

export const useAuth = () => {
  const [authProviders, setAuthProviders] = useState<AuthProvider[]>([])

  const fetchAuthProviders = useCallback(async () => {
    const result = await fetch(`${process.env.REACT_APP_AUTH_URL}/identity/providers`)
    setAuthProviders(await result.json())
  }, [])

  useEffect(() => {
    fetchAuthProviders()
  }, [fetchAuthProviders])

  return {
    authProviders,
  }
}
