import styled from '@emotion/styled'
import { QuoteRight } from '@mehilainen/mds-customer/icons'
import React from 'react'

import { scale } from '../../utils/scale'
import { RowFlex } from '../Layout/Layout'
import { Text } from '../Typography/Typography'

const Container = styled(RowFlex)`
  svg {
    margin-right: ${scale(2)};
    width: 24px;
    height: 24px;
  }
`

interface Props {
  text: string
}

const Quote: React.FC<React.PropsWithChildren<Props>> = ({ text }) => {
  return (
    <Container>
      <QuoteRight />
      <Text as="q" $size={500}>
        {text}
      </Text>
    </Container>
  )
}

export default Quote
