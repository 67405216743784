import { PublicNode } from '../../../__generated__/api'

import { SearchTreeItem } from './types'

export const convertSearchNode = (
  node: PublicNode,
  secondaryGroupLabel?: string,
  group?: string
): SearchTreeItem<PublicNode> => ({
  id: node.id,
  name: node.name,
  item: node,
  children: node.children ? convertSearchNodes(node.children, secondaryGroupLabel) : undefined,
  parentName: node.parentName,
  description: node.description,
  descriptionTitle: node.descriptionTitle,
  caption: node.caption,
  group: node.priority === 2 ? secondaryGroupLabel : group,
})

export const convertSearchNodes = (
  nodes: PublicNode[],
  secondaryGroupLabel?: string,
  group?: string
): Array<SearchTreeItem<PublicNode>> =>
  nodes.map((node) => convertSearchNode(node, secondaryGroupLabel, group))

export const searchTreeItemIconProvider = (item: SearchTreeItem<PublicNode>) =>
  item.item?.icon ? item.item.icon : `${process.env.REACT_APP_NODE_ICON_URL}mehilainen.svg`
