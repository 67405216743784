import { TFunction } from 'i18next'

import Yup from '../../../common/utils/yup'

export const additionalInformationMaxLength = 400

const OmitSecretEmailSuffixRegex = /^(?!.*\.s@).*/g

export const createValidationSchema = (t: TFunction) => {
  const basicSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(t('validation.required'))
      .min(1, t('validation.string.min'))
      .max(30, t('validation.string.max')),
    lastName: Yup.string()
      .required(t('validation.required'))
      .min(1, t('validation.string.min'))
      .max(30, t('validation.string.max')),
    ssn: Yup.string().required(t('validation.required')).ssn(t('validation.string.ssn')),
    email: Yup.string()
      .required(t('validation.required'))
      .email(t('validation.string.email'))
      .matches(OmitSecretEmailSuffixRegex, t('validation.string.secretEmail')),
    phone: Yup.string()
      .required(t('validation.required'))
      .phone('FI', t('validation.string.phone')),
    additionalInformation: Yup.string().max(
      additionalInformationMaxLength,
      t('validation.string.max')
    ),
    address: Yup.string().when('showAddressFields', {
      is: true,
      then: Yup.string().required(t('validation.required')),
    }),
    city: Yup.string()
      .min(2, t('validation.string.min'))
      .max(30, t('validation.string.max'))
      .when('showAddressFields', {
        is: true,
        then: Yup.string().required(t('validation.required')),
      }),
    postalCode: Yup.string().when('showAddressFields', {
      is: true,
      then: Yup.string()
        .required(t('validation.required'))
        .matches(/^\d{5}$/, t('validation.string.postalCode')),
    }),
  })
  return basicSchema
}

export const createTrafficAccidentFormValidationSchema = (t: TFunction) => {
  return Yup.object().shape({
    incidentDate: Yup.date().required(t('validation.required')),
    incidentDescription: Yup.string()
      .required(t('validation.required'))
      .max(60, t('validation.string.max')),
    vehicleRegistrationNumber: Yup.string()
      .required(t('validation.required'))
      .max(10, t('validation.string.max')),
  })
}

export const createOccupationalAccidentFormValidationSchema = (t: TFunction) => {
  return Yup.object().shape({
    incidentDate: Yup.date().required(t('validation.required')),
    incidentDescription: Yup.string()
      .required(t('validation.required'))
      .max(60, t('validation.string.max')),
    employer: Yup.string().required(t('validation.required')).max(40, t('validation.string.max')),
  })
}

export const createAddressEditorValidationSchema = (t: TFunction) => {
  return Yup.object().shape({
    address: Yup.string().required(t('validation.required')),
    city: Yup.string()
      .min(2, t('validation.string.min'))
      .max(30, t('validation.string.max'))
      .required(t('validation.required')),
    postalCode: Yup.string()
      .required(t('validation.required'))
      .matches(/^\d{5}$/, t('validation.string.postalCode')),
  })
}
