import styled from '@emotion/styled'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { CenteredColumnFlex } from '../../../common/components/Layout/Layout'
import { Text } from '../../../common/components/Typography/Typography'
import dayjs from '../../../common/utils/dayjs/dayjs'
import { scale } from '../../../common/utils/scale'
import { formatCallbackTime, formatDate4, formatDateAndTime2 } from '../../../common/utils/text'

const AppointmentTypeContainer = styled(CenteredColumnFlex)`
  margin-top: ${scale(2)};
`

interface Props {
  appointmentTitle?: string
  typeTitle?: string
  locationAddress?: string
  locationPostPlace?: string
  time: string
  duration: number
  isCallbackAppointment?: boolean
  className?: string
}

const SimpleAppointmentDetails: React.FC<React.PropsWithChildren<Props>> = ({
  appointmentTitle,
  typeTitle,
  locationAddress,
  locationPostPlace,
  time,
  duration,
  isCallbackAppointment,
  className,
}) => {
  const { t } = useTranslation()
  return (
    <CenteredColumnFlex className={className} data-cy="simpleAppointmentDetails">
      <Text $size={300} $weight="Medium" $height="Large">
        {isCallbackAppointment ? (
          <div>
            <p>{formatDate4(dayjs(time))}</p>
            <p>
              {time && formatCallbackTime(dayjs(time)) !== ''
                ? formatCallbackTime(dayjs(time))
                : t('component.simpleAppointmentDetails.timeframeSubstitute')}
            </p>
          </div>
        ) : (
          <>{formatDateAndTime2(dayjs(time))}</>
        )}
      </Text>
      {appointmentTitle ? (
        <Text $size={300} $weight="Regular" $height="Large" data-cy="appointment-duration">
          {t('component.simpleAppointmentDetails.durationAndAppointment', {
            appointmentTitle,
            duration: Math.round(duration),
          })}
        </Text>
      ) : (
        <Text $size={300} $weight="Regular" $height="Large" data-cy="appointment-duration">
          {`${Math.round(duration)} min`}
        </Text>
      )}
      {typeTitle && (
        <AppointmentTypeContainer>
          {typeTitle && (
            <Text $size={300} $weight="Medium" $height="Large">
              {typeTitle}
            </Text>
          )}
          {locationAddress && (
            <Text $size={300} $weight="Regular" $height="Large">
              {locationAddress}
            </Text>
          )}
          {locationPostPlace && (
            <Text $size={300} $weight="Regular" $height="Large">
              {locationPostPlace}
            </Text>
          )}
        </AppointmentTypeContainer>
      )}
    </CenteredColumnFlex>
  )
}

export default styled(SimpleAppointmentDetails)``
