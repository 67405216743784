import { Alert } from '@mui/material'
import React, { ErrorInfo } from 'react'
import { Translation } from 'react-i18next'

import Sentry from '../../services/sentry'

interface Props {
  children: React.ReactNode
}

interface State {
  hasError: boolean
}

class GenericErrorBoundary extends React.PureComponent<Props, State> {
  public state: State = {
    hasError: false,
  }

  public static getDerivedStateFromError(): State {
    return {
      hasError: true,
    }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    Sentry.captureErrorBoundaryError?.(error, errorInfo, { tags: { boundary: 'generic' } })
  }

  public render(): React.ReactNode {
    if (this.state.hasError) {
      return <Translation>{(t) => <Alert severity="error">{t('common.error')}</Alert>}</Translation>
    }

    return this.props.children
  }
}

export default GenericErrorBoundary
