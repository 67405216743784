import { css } from '@emotion/react'
import { Primary500 } from '@mehilainen/design-system-tokens/colors'

import { breakpoint } from '../../utils/breakpoint'

import curve from './img/curve.svg'

// ${curve} needs to be surrounded by double quotes to work with url() in Vite
// prettier-ignore
const Pattern = css`
  background: ${Primary500} url("${curve}") top right no-repeat;
  background-size: cover;

  @media (max-width: ${breakpoint.sm}px) {
    background-image: url("${curve}");
  }

  > h1 {
    color: white;
  }

  // Autocomplete from the design system does not play nice with pattern():
  // The following selector defaults to inheriting the background color if empty,
  // thus turning the same color as the pattern.
  .MuiAutocomplete-inputRoot {
    background-color: white !important;
  }
`

export default Pattern
