import styled from '@emotion/styled'
import { Gray700 } from '@mehilainen/design-system-tokens/colors'
import React from 'react'

import InputFieldContainerNew, { Props as InputFieldContainerProps } from './InputFieldContainerNew'
import { commonInputStylesNew } from './commonInputStylesNew'

const Input = styled.input<{ error: boolean }>`
  ${(props) => commonInputStylesNew(props.error)};

  font-family: inherit;

  :invalid {
    color: ${Gray700};
  }
`

interface Props
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'>,
    InputFieldContainerProps {
  id: string
  onChange(date: React.ChangeEvent<{ name: string; value: Date }>): void
  value?: Date
  ariaDescribedBy?: string
}

const DatePickerNew: React.FC<React.PropsWithChildren<Props>> = React.forwardRef(
  (
    {
      onChange,
      value,
      id,
      label,
      helperText,
      error,
      required,
      className,
      ariaDescribedBy,
      ...inputProps
    },
    ref
  ) => {
    return (
      <InputFieldContainerNew
        label={label}
        helperText={helperText}
        error={error}
        id={id}
        className={className}
      >
        <Input
          type="date"
          ref={ref as React.RefObject<HTMLInputElement>}
          id={id}
          error={Boolean(error)}
          aria-invalid={Boolean(error)}
          aria-describedby={`${ariaDescribedBy} helper-${id} error-${id}`}
          required={Boolean(required)}
          value={value?.toISOString().split('T')[0] ?? ''}
          onChange={(event) => {
            const newDate = new Date(event.target.value)
            if (newDate.getDate()) {
              onChange({
                target: { name: id, value: newDate },
              } as React.ChangeEvent<{ name: string; value: Date }>)
            }
          }}
          {...inputProps}
        />
      </InputFieldContainerNew>
    )
  }
)

export default DatePickerNew
