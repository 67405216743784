import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import api from '../../common/services/api'
import { ValidLanguage } from '../i18n/i18n'

import { useApi } from './useApi'

export const useBackendTranslations = (): void => {
  const { i18n } = useTranslation()
  const { data } = useApi(api.v1.translations, {}, undefined)

  useEffect(() => {
    if (data) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const translations = data as unknown as Record<string, any>
      Object.values(ValidLanguage).forEach((lang) => {
        if (translations[lang]) {
          i18n.addResourceBundle(lang, 'translation', translations[lang], true, true)
        }
      })
    }
  }, [i18n, data])
}
