import { t } from 'i18next'
import React, { useEffect } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import { LocationType } from '../../../__generated__/api'
import AuthenticationModal from '../../../domain/reserve/components/AuthenticationModal'
import { authenticationModalOpenAtom } from '../../../state/common/atoms'
import {
  isUserSelectedNodeAtom,
  searchServiceModalOpenAtom,
  selectedNodeLocationAtom,
  searchServiceModalAutoOpenAtom,
  landingModalOpenAtom,
} from '../../../state/search/atoms'
import { getSelectedNodeOrDefault } from '../../../state/search/selectors'
import { useOHC } from '../../hooks/useOHC'

interface Props {
  hasLoggedIn: boolean
  hasRedirected?: boolean
}

const OHCModal: React.FC<React.PropsWithChildren<Props>> = ({ hasLoggedIn, hasRedirected }) => {
  const [isModalOpen, setModalOpen] = useRecoilState(authenticationModalOpenAtom)
  const setServiceModalOpen = useSetRecoilState(searchServiceModalOpenAtom)
  const setSelectedSearchNodeId = useSetRecoilState(getSelectedNodeOrDefault)
  const setSelectedLocation = useSetRecoilState(selectedNodeLocationAtom)
  const setLandingModalOpen = useSetRecoilState(landingModalOpenAtom)
  const isUserSelectedNode = useRecoilValue(isUserSelectedNodeAtom)
  const isModalAutoOpen = useRecoilValue(searchServiceModalAutoOpenAtom)
  const { isOHCSide, ohcAllowedStatus, namedCheckupOfficeCityId, setOHC } = useOHC()
  const sessionStorageKey = 'ohc-service-modal-shown'
  const serviceModalShown = window.sessionStorage?.getItem(sessionStorageKey)

  useEffect(() => {
    setModalOpen(false)
  }, [setModalOpen])

  useEffect(() => {
    // reset modal state when user logs out
    if (!hasLoggedIn) {
      window.sessionStorage?.setItem(sessionStorageKey, '')
    }
  }, [hasLoggedIn])

  useEffect(() => {
    const interval = setInterval(() => {
      if (!hasLoggedIn) {
        setModalOpen(true)
      }
    }, 2000)

    return () => {
      clearInterval(interval)
    }
  }, [hasLoggedIn, setModalOpen])

  useEffect(() => {
    if (!isOHCSide || ohcAllowedStatus !== 'allowed' || isModalOpen) {
      return
    }
    // treat serviceModalShown as a ohc-initialization flag. This prevents to reset the location on page refresh
    if (namedCheckupOfficeCityId && serviceModalShown !== 'true') {
      setSelectedLocation([`${LocationType.City}${namedCheckupOfficeCityId}`])
    } else {
      // TODO: Add a warning modal when null
    }
    if (!serviceModalShown && isModalAutoOpen) {
      setServiceModalOpen(true)
    }
    if (serviceModalShown && !isModalAutoOpen) {
      setServiceModalOpen(false)
    }
    window.sessionStorage?.setItem(sessionStorageKey, 'true')
  }, [
    setSelectedSearchNodeId,
    isModalOpen,
    isOHCSide,
    ohcAllowedStatus,
    namedCheckupOfficeCityId,
    setSelectedLocation,
    setServiceModalOpen,
    isUserSelectedNode,
    serviceModalShown,
    isModalAutoOpen,
  ])

  const onAuthenticationClose = () => {
    window.sessionStorage?.setItem('landingModalShown', '')
    setOHC(false)
    setLandingModalOpen(true)
  }

  return (
    <AuthenticationModal
      authCompletedCallback={() => setModalOpen(false)}
      note={hasRedirected ? t('component.ohcModal.redirectedNote') : undefined}
      onClose={onAuthenticationClose}
    />
  )
}

export default OHCModal
