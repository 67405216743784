import styled from '@emotion/styled'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ExternalBannerBackground, jwtKey } from '../../../constants'
import { LoginStatus } from '../../../state/user/atoms'

const Container = styled.div`
  width: 100%;
  height: 50px;
  background: ${ExternalBannerBackground};
  color: white;
  text-align: center;
  font-size: 0.938rem;
`

interface Props {
  loginStatus?: LoginStatus
}

const ExternalBanner: React.FC<React.PropsWithChildren<Props>> = ({ loginStatus }) => {
  const { t } = useTranslation()
  const isPartner = useMemo(
    () => loginStatus === 'external' && sessionStorage.getItem(jwtKey)?.startsWith('Partner'),
    [loginStatus]
  )

  if (loginStatus !== 'external') {
    return null
  }

  return (
    <Container>
      {isPartner
        ? t('component.externalBanner.partnerHeading')
        : t('component.externalBanner.heading')}
    </Container>
  )
}

export default ExternalBanner
