import styled from '@emotion/styled'
import { Gray700 } from '@mehilainen/design-system-tokens/colors'
import { Alert, AlertTitle, Button } from '@mui/material'
import dayjs from 'dayjs'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  AppointmentSearchResult,
  CallbackSearchResult,
  DCNotification,
  SimpleNotification,
} from '../../../__generated__/api'
import { ApiError, isDentalMaintenanceError } from '../../utils/error/utils'
import { scale } from '../../utils/scale'
import { formatDate } from '../../utils/text'
import { DentalMaintenance } from '../DentalMaintenance/DentalMaintenance'
import { CenteredColumnFlex, ColumnFlex } from '../Layout/Layout'
import { isDCNotification, isWalkInNotification } from '../Tile/NotificationTile'
import Tiles, { LoadingTiles } from '../Tile/Tiles'
import { Text } from '../Typography/Typography'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Heading = styled(Text)`
  margin-bottom: 16px;
`

const StyledButton = styled(Button)`
  width: 300px;
  height: 56px;
  margin-top: ${scale(4)};
`

const TilesByDayContainer = styled(ColumnFlex)`
  gap: ${scale(4)};
`

const StyledAlert = styled(Alert)`
  margin-bottom: ${scale(2)};
`

interface Props {
  appointments: Array<AppointmentSearchResult | CallbackSearchResult>
  appointmentsPending: boolean
  appointmentsError: ApiError | null
  notifications: Array<DCNotification | SimpleNotification>
  toShow: number
  selectedDate: dayjs.Dayjs
  supportedNode: boolean
  isUserSelectedDate: boolean
  onAppointmentClick(appointment: AppointmentSearchResult): void
  onSpecialistClick(appointment: AppointmentSearchResult): void
  onDcNotificationClick(queue: string): void
  onNrOfAppointmentsVisibleChanged(count: number): void
  remoteSupported: boolean
}

const AppointmentList: React.FC<React.PropsWithChildren<Props>> = ({
  appointments,
  appointmentsPending,
  appointmentsError,
  notifications,
  toShow,
  selectedDate,
  supportedNode,
  isUserSelectedDate,
  onAppointmentClick,
  onSpecialistClick,
  onDcNotificationClick,
  onNrOfAppointmentsVisibleChanged,
  remoteSupported,
}) => {
  const { t } = useTranslation()
  const [visibleAppointments, setVisibleAppointments] = useState<AppointmentSearchResult[]>([])

  const appointmentsByDay = useMemo(() => {
    let res = visibleAppointments.reduce(
      (result, appointment) => {
        const item = result.find((r) => r.date.isSame(appointment.time, 'date'))
        if (item) {
          item.appointments.push(appointment)
        } else {
          result.push({
            date: dayjs(appointment.time),
            appointments: [appointment],
            notifications: [],
          })
        }
        return result
      },
      [] as Array<{
        date: dayjs.Dayjs
        appointments: AppointmentSearchResult[]
        notifications: Array<DCNotification | SimpleNotification>
      }>
    )

    if (notifications.length > 0) {
      if (res.length === 0) {
        res = [{ date: dayjs(), appointments: [], notifications }]
      } else if (res.length > 0 && !res[0].date.isSame(dayjs(), 'date')) {
        const walkInOrDCNotifications = notifications.filter(
          (notification) => isDCNotification(notification) || isWalkInNotification(notification)
        )
        const standardNotifications = notifications.filter(
          (notification) => !isDCNotification(notification) && !isWalkInNotification(notification)
        )
        if (walkInOrDCNotifications.length > 0) {
          res = [
            { date: dayjs(), appointments: [], notifications: walkInOrDCNotifications },
            ...res,
          ]
          res[1].notifications = standardNotifications
        } else {
          res[0].notifications = standardNotifications
        }
      } else {
        res[0].notifications = notifications
      }
    }

    return res
  }, [visibleAppointments, notifications])

  useEffect(() => {
    setVisibleAppointments(appointments.slice(0, toShow))
  }, [appointments, toShow])

  useEffect(() => {
    onNrOfAppointmentsVisibleChanged(visibleAppointments.length)
  }, [onNrOfAppointmentsVisibleChanged, visibleAppointments])

  if (appointmentsPending) {
    return <LoadingTiles variant="timeslot" />
  }

  const selectedDateHasAppointments = appointments.some((appointment) =>
    selectedDate.isSame(appointment.time, 'date')
  )

  const displayNoSupportedAppointments =
    !supportedNode && !appointmentsPending && appointments.length === 0

  const displayNoAppointments =
    // The calendar hasn't been touched and there are no appointments
    (!isUserSelectedDate && supportedNode && !appointmentsPending && appointments.length === 0) ||
    // The user has explicitly selected a date and there are no appointments on the date
    (isUserSelectedDate && supportedNode && !appointmentsPending && !selectedDateHasAppointments)

  const displayDentalMaintenance = isDentalMaintenanceError(appointmentsError)

  if (displayDentalMaintenance) {
    return <DentalMaintenance variant="nested" />
  }

  return (
    <Container data-cy="appointmentList">
      {displayNoSupportedAppointments && (
        <StyledAlert severity="info" aria-live="polite" aria-atomic="true">
          <AlertTitle>{t('component.appointmentList.noSupportedAppointments')}</AlertTitle>
        </StyledAlert>
      )}
      {displayNoAppointments && (
        <StyledAlert
          severity="info"
          aria-live="polite"
          aria-atomic="true"
          title={t('component.appointmentList.noAppointments')}
        >
          <AlertTitle>{t('component.appointmentList.noAppointments')}</AlertTitle>
        </StyledAlert>
      )}
      {appointmentsByDay.length > 0 && (
        <>
          <TilesByDayContainer>
            {appointmentsByDay.map((item, idx) => (
              <div key={idx}>
                <Heading as="h2" $weight="Regular" $size={300} $color={Gray700} $uppercase>
                  {formatDate(item.date, t, true)}
                </Heading>
                <Tiles
                  appointments={item.appointments}
                  variant="timeslot"
                  onSpecialistClick={onSpecialistClick}
                  onAppointmentClick={onAppointmentClick}
                  remoteSupported={remoteSupported}
                  onDcNotificationClick={onDcNotificationClick}
                  notifications={item.notifications}
                />
              </div>
            ))}
          </TilesByDayContainer>
        </>
      )}
      {visibleAppointments.length < appointments.length && (
        <CenteredColumnFlex>
          <StyledButton
            onClick={async () => {
              setVisibleAppointments(
                visibleAppointments.concat(
                  appointments.slice(
                    visibleAppointments.length,
                    visibleAppointments.length + toShow
                  )
                )
              )
            }}
            variant="outlined"
          >
            {t('common.showMore')}
          </StyledButton>
        </CenteredColumnFlex>
      )}
    </Container>
  )
}

export default AppointmentList
