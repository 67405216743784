import React from 'react'
import { useRecoilValue } from 'recoil'

import ServiceSelect from '../../../common/components/SearchSelect/SearchSelect'
import { useNode, useNodes } from '../../../common/hooks/useNode'
import { selectedSearchPractitionerAtom } from '../../../state/search/atoms'
import { getSelectedNodeOrDefault } from '../../../state/search/selectors'
import useSearchTarget, { SearchTargetValue } from '../hooks/useSearchTarget'

const SearchServiceSelect: React.FC<React.PropsWithChildren<unknown>> = () => {
  const selectedSearchNodeId = useRecoilValue(getSelectedNodeOrDefault)
  const selectedPractitioner = useRecoilValue(selectedSearchPractitionerAtom)
  const { setSearchTarget } = useSearchTarget()
  const { node: selectedSearchNode } = useNode(selectedSearchNodeId)
  const { listedNodes, favoriteNodes } = useNodes()

  return (
    <ServiceSelect
      listedNodes={listedNodes}
      favoriteNodes={favoriteNodes}
      inputValue={selectedPractitioner?.name ?? selectedSearchNode?.name}
      selected={selectedSearchNode}
      onSelect={(node) => {
        setSearchTarget({ id: node.id, value: SearchTargetValue.Node })
      }}
      onPractitionerSelect={(id) => {
        setSearchTarget({ id, value: SearchTargetValue.Practitioner })
      }}
    />
  )
}

export default SearchServiceSelect
