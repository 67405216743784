import React, { ErrorInfo } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { reserveRoutePath } from '../../../Routes'
import AppointmentNotFoundView from '../../../domain/appointmentNotFound/components/appointmentNotFoundView'
import Sentry from '../../services/sentry'

import { ReserveAppointmentNotFoundError } from './ReserveAppointmentNotFoundError'

interface Props extends RouteComponentProps {
  children: React.ReactNode
}

interface State {
  error?: Error
}

class ReserveAppointmentNotFoundErrorBoundary extends React.PureComponent<Props, State> {
  public state: State = {}

  constructor(props: Props) {
    super(props)
    this.resetError = this.resetError.bind(this)
  }

  public static getDerivedStateFromError(error: Error): State {
    return {
      error,
    }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    if (!(error instanceof ReserveAppointmentNotFoundError)) {
      Sentry.captureErrorBoundaryError?.(error, errorInfo, { tags: { boundary: 'reserve' } })
    }
  }

  resetError(): void {
    this.setState({ error: undefined })
    if (this.props.history.location.pathname.includes(reserveRoutePath)) {
      this.props.history.go(-2)
    } else {
      this.props.history.goBack()
    }
  }

  public render(): React.ReactNode {
    if (this.state.error) {
      // If error is not of the correct type, rethrow to be caught by a boundary higher up in the tree
      if (!(this.state.error instanceof ReserveAppointmentNotFoundError)) {
        throw this.state.error
      }
      return <AppointmentNotFoundView onBackClick={this.resetError} />
    }

    return this.props.children
  }
}

export default withRouter(ReserveAppointmentNotFoundErrorBoundary)
