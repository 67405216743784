import { Base64 } from 'js-base64'

export const flatten = <T>(arr: T[][]): T[] => arr.reduce((prev, cur) => prev.concat(cur), [])
export const isJwtValid = (token?: string | null): token is string => {
  let tokenToValidate = token
  if (tokenToValidate?.startsWith('Partner')) {
    return true
  }
  if (tokenToValidate?.startsWith('External') || token?.startsWith('Test')) {
    tokenToValidate = tokenToValidate?.split(' ')[1]
  }
  try {
    if (!tokenToValidate) return false
    const jwtData: { exp: number } = JSON.parse(Base64.decode(tokenToValidate.split('.')[1]))
    return jwtData.exp > new Date().getTime() / 1000
  } catch (error) {
    return false
  }
}
