import styled from '@emotion/styled'
import { Primary500, White } from '@mehilainen/design-system-tokens/colors'
import { CalendarDay, LongArrowUp } from '@mehilainen/mds-customer/icons'
import { transparentize } from 'polished'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import Chip from '../../../common/components/Chip/Chip'
import { ColumnFlex, MinMaxDiv, RowFlex } from '../../../common/components/Layout/Layout'
import { Text } from '../../../common/components/Typography/Typography'
import { useIsMobile } from '../../../common/hooks/useBreakpoint'
import { maxWidth } from '../../../common/utils/breakpoint'
import debounce from '../../../common/utils/debounce'
import { scale } from '../../../common/utils/scale'
import { capitalizeFirstLetter } from '../../../common/utils/text'
import {
  filterOptionsOpenAtom,
  dateModalOpenAtom,
  selectedDateAtom,
  isUserSelectedDateAtom,
} from '../../../state/search/atoms'
import filterIcon from '../components/img/filter.svg'

import { MIN_MAX_WIDTH } from './SearchViewContainers'

const StickyMinMaxDiv = styled(MinMaxDiv)`
  position: fixed;
  top: 0;
  z-index: 1;
  color: ${White};
  background-color: ${transparentize(0.05, Primary500)};
  width: 100%;
  transform: translateY(-100%);

  @media (min-width: ${maxWidth.body}px) {
    width: ${maxWidth.body}px;
    margin: auto;
  }
`

const Container = styled(RowFlex)`
  padding: ${scale(2)};
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem;
`

const TextLink = styled(Text)`
  &:link {
    text-decoration: none;
  }
`

const MobileTextLink = styled(TextLink)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const StyledArrowUpMobile = styled(LongArrowUp)`
  margin-right: 8px;
`

const StyledArrowUp = styled(LongArrowUp)`
  margin-right: 4px;
  transform: translateY(7px);
`

const StyledMobileContainer = styled(ColumnFlex)`
  display: flex;
  flex-direction: column;
  text-align: left;
`

const ChipContainer = styled(RowFlex)`
  gap: ${scale(1)};
`

const TRANSITION_DURATION = 500 // MS
const SCROLL_DEBOUNCE_DELAY = 100 // MS

interface Props {
  offset: number
  name: string
  locationName?: string
}

const SearchBar: React.FC<React.PropsWithChildren<Props>> = ({ name, locationName, offset }) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const [hidden, setHidden] = useState(false)
  const setFilterOptionsOpen = useSetRecoilState(filterOptionsOpenAtom)
  const setDateModalOpen = useSetRecoilState(dateModalOpenAtom)
  const selectedDate = useRecoilValue(selectedDateAtom)
  const userHasSelectedDate = useRecoilValue(isUserSelectedDateAtom)

  const onBackToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    const onScroll = debounce(() => {
      if (!hidden && window.pageYOffset > offset) {
        setHidden(true)
      } else if (hidden && window.pageYOffset < offset) {
        setHidden(false)
      }
    }, SCROLL_DEBOUNCE_DELAY)

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', onScroll)
    }
    return () => window.removeEventListener('scroll', onScroll)
  }, [hidden, offset])

  return (
    <StickyMinMaxDiv
      size={MIN_MAX_WIDTH}
      style={{
        transform: hidden ? 'translateY(0%)' : undefined,
        transition: `transform ${TRANSITION_DURATION}ms`,
      }}
    >
      <Container data-cy="searchBar-container">
        {isMobile ? (
          <MobileTextLink $size={300} as="button" onClick={onBackToTop}>
            <StyledArrowUpMobile />
            <StyledMobileContainer>
              <div>{name}</div>
              {locationName && <div>{locationName}</div>}
            </StyledMobileContainer>
          </MobileTextLink>
        ) : (
          <TextLink $size={300} as="button" onClick={onBackToTop}>
            <StyledArrowUp /> {locationName ? `${name} · ${locationName}` : name}
          </TextLink>
        )}
        <ChipContainer>
          <Chip
            icon={<CalendarDay />}
            label={
              userHasSelectedDate
                ? capitalizeFirstLetter(selectedDate.format('dd D.M.'))
                : t('common.calendar')
            }
            variant="important"
            onClick={() => setDateModalOpen(true)}
            order={isMobile ? 'icon-only' : 'icon-first'}
          />
          <Chip
            icon={filterIcon}
            label={t('component.searchFilterChips.addFiltersText')}
            variant="important"
            onClick={() => setFilterOptionsOpen(true)}
            order={isMobile ? 'icon-only' : 'icon-first'}
          />
        </ChipContainer>
      </Container>
    </StickyMinMaxDiv>
  )
}

export default SearchBar
