import styled from '@emotion/styled'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'

import { searchNodeRedirectModal } from '../../../state/search/atoms'
import { useIsMobile } from '../../hooks/useBreakpoint'
import useSearchRedirect from '../../hooks/useSearchRedirect'
import { DefaultButton } from '../DefaultButton/DefaultButton'
import { ColumnFlex } from '../Layout/Layout'
import Modal from '../Modal/Modal'
import { Text } from '../Typography/Typography'

const Container = styled.div``

const ContentContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  text-align: center;
  margin-top: ${(props) => (props.isMobile ? '36px' : '0px')};
`

const ButtonContainer = styled(ColumnFlex)`
  width: 100%;
  gap: 12px;
  align-items: center;
`
const NodeRedirectModal: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const [modal, setModalName] = useRecoilState(searchNodeRedirectModal)
  const closeModal = () => {
    setModalName(null)
  }
  const redirect = useSearchRedirect()

  const i18nBasePath = `component.nodeRedirectModal.${modal?.modalName}`

  return (
    <Modal open={validModal(modal)} onClose={() => closeModal()} fullWidth>
      <Container>
        <ContentContainer isMobile={isMobile}>
          <Text $weight="Medium" $size={500}>
            {t(i18nBasePath + '.title')}
          </Text>
          <Text $height="Large" $size={300}>
            {t(i18nBasePath + '.description')}
          </Text>
          <ButtonContainer>
            <DefaultButton size="large" onClick={() => redirect(modal!.url)}>
              {t(i18nBasePath + '.continueButtonText') ||
                t('component.nodeRedirectModal.continueButtonTextCommon')}
            </DefaultButton>
            <DefaultButton size="large" variant="outlined" onClick={() => closeModal()}>
              {t('common.cancel')}
            </DefaultButton>
          </ButtonContainer>
        </ContentContainer>
      </Container>
    </Modal>
  )
}

function validModal(modal: { url: string; modalName: string } | null): boolean {
  if (modal === null) {
    return false
  }
  return Boolean(modal.url) && Boolean(modal.modalName)
}

export default NodeRedirectModal
