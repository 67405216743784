import styled from '@emotion/styled'
import { Primary500 } from '@mehilainen/design-system-tokens/colors'
import React from 'react'

import { Text } from '../../Typography/Typography'

interface Props {
  onClose(): void
  label: string
  className?: string
}

const StyledButton = styled.button`
  border: none;
  padding: 12px 16px;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${Primary500};
  background: white;
  border-radius: 16px;
`

const CloseButton: React.FC<React.PropsWithChildren<Props>> = ({ onClose, label, className }) => (
  <StyledButton
    className={className}
    onClick={onClose}
    aria-label={label}
    data-cy="modalCloseButton"
  >
    <Text $weight="Medium" $size={300}>
      {label}
    </Text>
  </StyledButton>
)

export default styled(CloseButton)``
