import equal from 'fast-deep-equal'
import { DependencyList, EffectCallback, useRef, useEffect, useCallback } from 'react'

const useDeepCompareDeps = (deps?: DependencyList): DependencyList => {
  const depsRef = useRef<DependencyList | undefined>(undefined)
  if (!depsRef.current || !equal(depsRef.current, deps)) {
    depsRef.current = deps
  }
  return depsRef.current as DependencyList
}

export const useDeepCompareEffect = (effect: EffectCallback, deps?: DependencyList): void => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, useDeepCompareDeps(deps))
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDeepCompareCallback = <T extends (...args: any[]) => any>(
  callback: T,
  deps: DependencyList
): T => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(callback, useDeepCompareDeps(deps))
}
