import styled from '@emotion/styled'
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { FlexibleScheduleOption } from '../../../__generated__/api'
import { scale } from '../../utils/scale'
import RadioButtonGroup from '../RadioButtonGroup/RadioButtonGroup'
import { Text } from '../Typography/Typography'

export interface GroupedFlexibleScheduleOption
  extends Omit<FlexibleScheduleOption, 'locationId' | 'locationDHId'> {
  locations: string[]
}

const CheckboxesContainer = styled(FormGroup)`
  display: flex;
  flex-direction: column;
  margin-top: ${scale(1)};
`

interface FlexibleScheduleOptionComponentProps {
  option: GroupedFlexibleScheduleOption
  value?: GroupedFlexibleScheduleOption
  onSelect(option: GroupedFlexibleScheduleOption): void
}

const FlexibleScheduleOptionComponent: React.FC<
  React.PropsWithChildren<FlexibleScheduleOptionComponentProps>
> = ({ option, value, onSelect }) => {
  const { t } = useTranslation()

  if (value?.serviceId !== option.serviceId) {
    return <></>
  }

  const durations = [...option.availableDurations]
  const allDurationsSelected = [option.availableDurations.length, 0].includes(
    value?.availableDurations.length
  )

  const getAvailableDurations = (clickedDuration: number, isClickedSelected: boolean) => {
    if (allDurationsSelected) {
      return [clickedDuration]
    }
    return isClickedSelected
      ? value?.availableDurations.filter((d) => d !== clickedDuration)
      : [...value?.availableDurations, clickedDuration]
  }

  return (
    <CheckboxesContainer>
      <FormControlLabel
        control={
          <Checkbox
            id={`${option.serviceId}-all`}
            key={`duration-${option.serviceId}`}
            checked={allDurationsSelected}
            onChange={() => (!allDurationsSelected ? onSelect({ ...option }) : void 0)}
          />
        }
        label={t('component.practitionerAppointmentInstructions.allLengths')}
      />
      {durations.map((duration, idx) => {
        const checked = !allDurationsSelected && value?.availableDurations.includes(duration)

        return (
          <FormControlLabel
            key={`duration-${duration}-${idx}`}
            control={
              <Checkbox
                id={`${option.serviceId}-${duration}`}
                checked={checked}
                onChange={() =>
                  onSelect({
                    ...option,
                    availableDurations: getAvailableDurations(duration, checked),
                  })
                }
              />
            }
            label={t('common.minutes', { minutes: duration })}
          />
        )
      })}
    </CheckboxesContainer>
  )
}

interface Props {
  options: GroupedFlexibleScheduleOption[]
  value?: GroupedFlexibleScheduleOption
  onSelect(option: GroupedFlexibleScheduleOption): void
  className?: string
}

const FlexibleScheduleAppointmentLengthSelect: React.FC<React.PropsWithChildren<Props>> = ({
  options,
  value,
  onSelect,
  className,
}) => {
  return (
    <RadioButtonGroup
      options={options}
      value={value}
      onChange={onSelect}
      getKey={(option) => `flexibleScheduleLengthOption-${option.serviceId}`}
      isSelected={(selected, val) => selected.serviceId === val?.serviceId}
      renderOptionHeader={(option) => (
        <Text $size={400} $height="Medium" $weight="Regular">
          {option.serviceName}
        </Text>
      )}
      renderOptionDescription={(option) => (
        <FlexibleScheduleOptionComponent option={option} value={value} onSelect={onSelect} />
      )}
      className={className}
    />
  )
}

export default FlexibleScheduleAppointmentLengthSelect
