import styled from '@emotion/styled'
import React from 'react'
import { isAndroid, isDesktop, isIOS } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

import { ColumnFlex } from '../Layout/Layout'
import { Text } from '../Typography/Typography'

import AndroidImageEN from './img/android_en.png'
import AndroidImageFI from './img/android_fi.png'
import AndroidImageSV from './img/android_sv.png'
import IOSImageEN from './img/ios_en.svg'
import IOSImageFI from './img/ios_fi.svg'
import IOSImageSV from './img/ios_sv.svg'

const images = {
  android: {
    fi: AndroidImageFI,
    en: AndroidImageEN,
    sv: AndroidImageSV,
  },
  ios: {
    fi: IOSImageFI,
    en: IOSImageEN,
    sv: IOSImageSV,
  },
}

const Link = styled.a<{ image: string }>`
  height: 60px;
  width: 160px;
  background: url(${(props) => props.image}) no-repeat;
  background-size: contain;
`

const Container = styled(ColumnFlex)`
  align-items: center;

  ${Link}, a {
    margin-top: 12px;
  }
`

interface Props {
  text: string
}

const OmaMehilainenBanner: React.FC<React.PropsWithChildren<Props>> = ({ text }) => {
  const { t, i18n } = useTranslation()

  if (isDesktop || (!isIOS && !isAndroid)) {
    return (
      <Container>
        <Text $size={300} $height="Large" $weight="Regular">
          {text}
        </Text>
        <a
          href={t('component.omaMehilainenBanner.link.desktop.url')}
          target="_blank"
          rel="noreferrer"
        >
          {t('component.omaMehilainenBanner.link.desktop.text')}
        </a>
      </Container>
    )
  }

  const image = isIOS
    ? images.ios[i18n.language as 'fi' | 'en' | 'sv']
    : images.android[i18n.language as 'fi' | 'en' | 'sv']
  const url = isIOS
    ? t('component.omaMehilainenBanner.link.ios.url')
    : t('component.omaMehilainenBanner.link.android.url')

  return (
    <Container>
      <Text $size={300} $height="Large" $weight="Regular">
        {text}
      </Text>
      <Link image={image} href={url} target="_blank" className="no-external-icon" />
    </Container>
  )
}

export default OmaMehilainenBanner
