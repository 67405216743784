import styled from '@emotion/styled'
import { Gray100 } from '@mehilainen/design-system-tokens/colors'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { CenteredRowFlex } from '../../../common/components/Layout/Layout'
import { useInsuranceName } from '../../../common/hooks/useInsurance'
import { scale } from '../../../common/utils/scale'
import LahitapiolaCheck from '../images/LahiTapiolaCheck.svg?react'
import PohjolaCheck from '../images/PohjolaCheck.svg?react'

const Container = styled(CenteredRowFlex)`
  background: ${Gray100};
  padding: ${scale(2)} ${scale(3)};
  border-radius: ${scale(1)};
`

interface Props {
  insuranceId: number
}

const InsuranceIcon: React.FC<React.PropsWithChildren<Props>> = ({ insuranceId }) => {
  if (insuranceId === 56) {
    return <LahitapiolaCheck />
  }

  if (insuranceId === 804) {
    return <PohjolaCheck />
  }

  return null
}

const InsuranceCallout: React.FC<React.PropsWithChildren<Props>> = ({ insuranceId }) => {
  const { t } = useTranslation()
  const name = useInsuranceName(insuranceId)

  if (name.pending) {
    return null
  }

  return (
    <Container $gap="10px">
      <InsuranceIcon insuranceId={insuranceId} />
      {t('component.insuranceCallout.text', { name: name.name })}
    </Container>
  )
}

export default InsuranceCallout
