import { atom } from 'recoil'

export const modalTriggererAtom = atom<'appointmentSelect' | 'back' | undefined>({
  key: 'appointmentOptionsSelect-modalTriggerer',
  default: undefined,
})

export const selectedAppointmentIdAtom = atom<number | undefined>({
  key: 'appointmentOptionsSelect-selectedAppointmentIdAtom',
  default: undefined,
})

export const selectedPractitionerIdAtom = atom<number | undefined>({
  key: 'appointmentOptionsSelect-selectedPractitionerIdAtom',
  default: undefined,
})
