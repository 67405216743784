import equal from 'fast-deep-equal'
import { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { AppointmentType } from '../../__generated__/api'
import {
  selectedAppointmentTypesAtom,
  selectedNodeLocationAtom,
  allLocationsSelection,
  userSelectedAppointmentTypesAtom,
  defaultSelectedAppointmentTypes,
  defaultSelectedLocation,
} from '../../state/search/atoms'

export const useLocationSideEffects = () => {
  const [selectedAppointmentTypes, setSelectedAppointmentTypes] = useRecoilState(
    selectedAppointmentTypesAtom
  )
  const userSelectedAppointmentTypes = useRecoilValue(userSelectedAppointmentTypesAtom)
  const [selectedLocation, setSelectedLocation] = useRecoilState(selectedNodeLocationAtom)

  const [previousUserSelectedAppointmentTypes, setPreviousUserSelectedAppointmentTypes] = useState<
    AppointmentType[]
  >([])
  const [previousSelectedLocation, setPreviousSelectedLocation] = useState<string[]>([])

  // Update previous selected location
  useEffect(() => {
    if (!equal(previousSelectedLocation, selectedLocation)) {
      setPreviousSelectedLocation(selectedLocation)
    }
  }, [previousSelectedLocation, selectedLocation])

  // Update previous user-selected appointment types
  useEffect(() => {
    if (!equal(previousUserSelectedAppointmentTypes, userSelectedAppointmentTypes)) {
      setPreviousUserSelectedAppointmentTypes(userSelectedAppointmentTypes)
    }
  }, [previousUserSelectedAppointmentTypes, userSelectedAppointmentTypes])

  // Reset appointment types to previous user selection if location is changed from 'all locations'
  useEffect(() => {
    if (
      !equal(previousSelectedLocation, selectedLocation) &&
      equal(previousSelectedLocation, allLocationsSelection) &&
      !equal(selectedAppointmentTypes, userSelectedAppointmentTypes)
    ) {
      setSelectedAppointmentTypes(userSelectedAppointmentTypes)
    }
  }, [
    previousSelectedLocation,
    selectedLocation,
    selectedAppointmentTypes,
    userSelectedAppointmentTypes,
    setSelectedAppointmentTypes,
  ])

  // Reset location to default if the user removes remote appointment types and location is 'all locations'
  useEffect(() => {
    if (
      !equal(previousUserSelectedAppointmentTypes, userSelectedAppointmentTypes) &&
      equal(userSelectedAppointmentTypes, defaultSelectedAppointmentTypes) &&
      equal(selectedLocation, allLocationsSelection)
    ) {
      setSelectedLocation(defaultSelectedLocation)
    }
  }, [
    previousUserSelectedAppointmentTypes,
    userSelectedAppointmentTypes,
    selectedLocation,
    setSelectedLocation,
  ])
}
