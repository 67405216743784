import { AppointmentReservationInfo, AppointmentType } from '../../../__generated__/api'
import dayjs from '../../utils/dayjs/dayjs'
import { formatCallbackTimeRaw } from '../../utils/text'

const DATE_TIME_FORMAT = 'YYYYMMDDTHHmmss[Z]'
const DATE_FORMAT = 'YYYYMMDD'

export const createCalendarEvent = (uid: string, appointment: AppointmentReservationInfo) => {
  const location =
    appointment.type === AppointmentType.Clinic ? `LOCATION:${appointment.locationAddress}%0A` : ''
  const time = dayjs.tz(appointment.time, 'Europe/Helsinki')
  let dstart = ''
  let dend = ''
  if (appointment.type === AppointmentType.Callback) {
    const formattedTime = formatCallbackTimeRaw(time)
    if (formattedTime) {
      dstart = `DTSTART:${formattedTime.start.format(DATE_TIME_FORMAT)}%0A`
      dend = `DTEND:${formattedTime.end.format(DATE_TIME_FORMAT)}%0A`
    } else {
      // Full day event
      dstart = `DTSTART;VALUE=DATE:${time.format(DATE_FORMAT)}%0A`
    }
  } else {
    dstart = `DTSTART:${time.format(DATE_TIME_FORMAT)}%0A`
    dend = `DTEND:${time.add(appointment.duration, 'minutes').format(DATE_TIME_FORMAT)}%0A`
  }

  const icsData =
    `BEGIN:VCALENDAR%0A` +
    `VERSION:2.0%0A` +
    `METHOD:PUBLISH%0A` +
    `PRODID:-//hacksw/handcal//NONSGML v1.0//EN%0A` +
    `BEGIN:VEVENT%0A` +
    `UID:${uid}%0A` +
    `DTSTAMP:${dayjs().format(DATE_TIME_FORMAT)}%0A` +
    dstart +
    dend +
    `SUMMARY:Mehiläinen ajanvaraus%0A` +
    `CLASS:PRIVATE%0A` +
    `STATUS:CONFIRMED%0A` +
    location +
    `END:VEVENT%0A` +
    `END:VCALENDAR%0A`

  window.open(`data:text/calendar;charset=utf8,${icsData}`)
}
