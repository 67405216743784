import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Primary500, Gray600, Error400, Gray900 } from '@mehilainen/design-system-tokens/colors'
import React from 'react'

import { ColumnFlex, RowFlex } from '../Layout/Layout'
import { Text } from '../Typography/Typography'

const Label = styled(Text)`
  margin-bottom: 4px;
`

const HelperContainer = styled(RowFlex)`
  margin-top: 4px;
`

const HelperText = styled.p`
  color: ${Gray600};
  font-size: 0.688rem;
  font-weight: 400;
  line-height: 1;
`

const CharacterCount = styled(Text)`
  margin-left: auto;
  white-space: nowrap;
`

const Container = styled(ColumnFlex)<{ error: boolean }>`
  margin-bottom: 16px;
  ${(props) =>
    props.error
      ? css`
          ${Label}, ${HelperText} {
            color: ${Error400};
          }
        `
      : css`
          &:focus-within {
            ${Label}, ${HelperText} {
              color: ${Primary500};
            }
          }
        `}
`

export interface Props {
  id?: string
  label?: string
  helperText?: string | null | false
  error?: boolean
  characterCount?: string
  className?: string
}

const InputFieldContainerNew: React.FC<React.PropsWithChildren<Props>> = ({
  label,
  helperText,
  error,
  characterCount,
  id,
  children,
  className,
}) => {
  return (
    <Container error={Boolean(error)} className={className}>
      {label && label !== '' && (
        <Label $size={300} $color={Gray900} as="label" htmlFor={id}>
          {label}
        </Label>
      )}
      {children}
      {helperText || characterCount ? (
        <HelperContainer>
          <HelperText id={`helper-${id}`}>{helperText}</HelperText>
          {characterCount && (
            <CharacterCount $size={300} $color={Gray600}>
              {characterCount}
            </CharacterCount>
          )}
        </HelperContainer>
      ) : null}
    </Container>
  )
}

export default InputFieldContainerNew
