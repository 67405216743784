import { useEffect, useState } from 'react'
import { useSetRecoilState } from 'recoil'

import { SearchNodeLinking, SearchNodeLinkingType } from '../../../__generated__/api'
import api from '../../../common/services/api'
import { getSelectedNodeOrDefault } from '../../../state/search/selectors'

let cache: SearchNodeLinking[] | null = null

export const useNodeLinking = (id: string | number | null, type: SearchNodeLinkingType) => {
  const [linkings, setLinkings] = useState<SearchNodeLinking[] | null>(cache)
  const setSelectedNode = useSetRecoilState(getSelectedNodeOrDefault)

  useEffect(() => {
    if (!cache) {
      api.v1.searchNodeLinkings().then((res) => {
        cache = res.data
        setLinkings(cache)
      })
    }
  }, [])

  useEffect(() => {
    if (id && type) {
      const foundLinking = linkings?.find((linking) =>
        typeof id === 'string'
          ? linking.textualId?.toLocaleLowerCase() === id.toLocaleLowerCase() &&
            linking.type === type
          : typeof id === 'number'
          ? linking.numericId === id && linking.type === type
          : false
      )

      if (foundLinking) {
        setSelectedNode(foundLinking.node)
      }
    }
  }, [id, type, linkings, setSelectedNode])
}
