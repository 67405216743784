import * as Sentry from '@sentry/react'
import * as SentryTracing from '@sentry/tracing'
import {
  type CaptureContext as SentryCaptureContext,
  type CustomSamplingContext as SentryCustomSamplingContext,
  type TransactionContext as SentryTransactionContext,
} from '@sentry/types'
import { type ErrorInfo } from 'react'
import { useRecoilTransactionObserver_UNSTABLE } from 'recoil'
import { v4 as uuid } from 'uuid'

import { type HttpResponse } from '../../__generated__/api'
import type APIError from '../utils/error/APIError'

export const sessionId = uuid()

const SentryErrorSource = {
  ErrorEvent: 'error-event',
  UnhandledRejectionEvent: 'unhandledrejection-event',
  APIError: 'api-error',
  ErrorBoundary: 'error-boundary',
} as const

class SentryService {
  private currentBundle?: string
  private client?: Sentry.BrowserClient
  private hub?: Sentry.Hub
  private endSessionBound?: () => void
  private captureErrorEvent?: (event: ErrorEvent) => void
  private captureUnhandledRejectionEvent?: (event: PromiseRejectionEvent) => void

  public captureAPIError?: (errorResponse?: HttpResponse<unknown>, error?: Error | APIError) => void
  public captureErrorBoundaryError?: (
    error: Error,
    errorInfo: ErrorInfo,
    captureContext?: SentryCaptureContext
  ) => void

  // Determine if the error stack includes our bundle - this is used to distinguish our errors from www errors in case of global errors
  private stackIncludesCurrentBundle(error: unknown) {
    if (!this.currentBundle) {
      return false
    }

    try {
      if (!(error instanceof Error)) {
        return false
      }

      return !!error.stack?.includes(this.currentBundle)
    } catch (err) {
      return false
    }
  }

  public init(options?: Sentry.BrowserOptions & { subrelease?: string }) {
    // Check if the service is already initialized
    if (this.client) {
      return
    }

    if (typeof window === 'undefined') {
      // NO-OP if running in SSR
      return
    }

    this.client = new Sentry.BrowserClient({
      dsn:
        process.env.REACT_APP_ENV === 'local' &&
        process.env.REACT_APP_ENABLE_SENTRY_IN_DEVELOPMENT !== 'true'
          ? ''
          : process.env.REACT_APP_SENTRY_DSN,
      environment: process.env.REACT_APP_ENV,
      release: `av3-front${options?.subrelease ? `-${options.subrelease}` : ''}@${
        process.env.REACT_APP_VERSION ?? 'unversioned'
      }`,
      defaultIntegrations: false,
      normalizeDepth: 6,

      // @ts-expect-error: 'dsn' being required is actually a typing error - https://github.com/getsentry/sentry-javascript/blob/b122af8d7ac5b89444ebc88a88694fe5f1555d3c/packages/browser/src/backend.ts#L56-L67
      transportOptions: {
        fetchParameters: {
          // We set keepalive so that the request sent by endSession can finish even after the window unloads / closes
          keepalive: true as unknown as string,
        },
      },

      // DO NOT USE - use filterEvent (event processor) instead as it applies to both errors and transactions (beforeSend only applies to errors)
      beforeSend(event) {
        return event
      },

      tracesSampler() {
        return process.env.REACT_APP_ENV === 'local' ? 1 : 0.2
      },

      ...options,
    })

    this.hub = new Sentry.Hub(this.client)

    if (!this.hub.getScope()) {
      this.hub.pushScope()
    }

    // This is here JUST to force that @sentry/tracing is not optimized away (tree shaking)
    if (SentryTracing.Transaction === undefined) {
      // No-op
    }

    // Figure out the filename of our bundle - this simultaneously works as a kind of sanity check to make sure the browser supports 'stack' in errors etc.
    try {
      throw new Error()
    } catch (err: unknown) {
      try {
        if (err instanceof Error) {
          const stackLines = err.stack?.split('\n')
          const firstFileLine = stackLines?.length ? stackLines[1] : undefined
          const regExpMatch = firstFileLine?.match(/[a-zA-Z-_]+\.js/)

          if (regExpMatch) {
            this.currentBundle = regExpMatch[0]
          }
        }
      } catch (innErr) {
        // Empty on purpose
      }
    }

    this.hub.configureScope((scope) => {
      scope.setTag('session', sessionId)
      scope.setTag('bundle', this.currentBundle)

      if (options?.subrelease) {
        scope.setTag('subrelease', options.subrelease)
      }

      scope.addEventProcessor(this.filterEvent.bind(this))
      scope.addEventProcessor(this.enrichEvent.bind(this))
    })

    // if (this.currentBundle) {
    this.captureErrorEvent = this.captureErrorEventUnbound.bind(this)
    this.captureUnhandledRejectionEvent = this.captureUnhandledRejectionEventUnbound.bind(this)

    window.addEventListener('error', this.captureErrorEvent)
    window.addEventListener('unhandledrejection', this.captureUnhandledRejectionEvent)
    // }

    this.endSessionBound = this.endSession.bind(this)
    window.addEventListener('beforeunload', this.endSessionBound)

    this.captureAPIError = this.captureAPIErrorUnbound.bind(this)
    this.captureErrorBoundaryError = this.captureErrorBoundaryErrorUnbound.bind(this)

    this.hub.startSession()

    // Report time from page load / navigation start to here
    const navigationStartEpoch = new Date(performance.timeOrigin).getTime() / 1000
    const nowEpoch = new Date(performance.timeOrigin + performance.now()).getTime() / 1000

    const sentryServiceLoadTransaction = this.startTransaction({
      name: 'Sentry load',
      startTimestamp: navigationStartEpoch,
    })
    this.finishTransaction(sentryServiceLoadTransaction, nowEpoch)
  }

  private endSession() {
    this.hub?.endSession()
  }

  public async close() {
    if (this.captureErrorEvent) {
      window.removeEventListener('error', this.captureErrorEvent)
    }

    if (this.captureUnhandledRejectionEvent) {
      window.removeEventListener('unhandledrejection', this.captureUnhandledRejectionEvent)
    }

    if (this.endSessionBound) {
      window.removeEventListener('beforeunload', this.endSessionBound)
    }

    this.endSession()
    try {
      await this.client?.close(3000)
    } catch (err) {
      // Empty on purpose
    }
  }

  private filterEvent(event: Sentry.Event, hint?: Sentry.EventHint) {
    if (event.type === 'transaction') {
      if (
        event.contexts?.trace.op === 'http.client' &&
        event.contexts.trace.status === SentryTracing.SpanStatus.Cancelled
      ) {
        // Node Sentry does not finish the tracing on these so disable at least for now
        return null
      }

      if (event.breadcrumbs) {
        event.breadcrumbs = undefined
      }

      event.extra = undefined
    }

    const source = event.tags?.['source'] as
      | (typeof SentryErrorSource)[keyof typeof SentryErrorSource]
      | undefined
    if (
      source === 'unhandledrejection-event' &&
      hint?.originalException instanceof Error &&
      hint.originalException.stack?.includes('AvHttpClient')
    ) {
      // AvHttpClient causes unhandled rejection events even though the errors are captured by captureAPIError
      return null
    }

    return event
  }

  // We basically do what the default UserAgent & tracing integrations do plus some more
  private enrichEvent(event: Sentry.Event) {
    event.contexts = event.contexts ?? {}
    event.tags = event.tags ?? {}
    event.measurements = event.measurements ?? {}

    const request = event.request ?? {}

    // https://github.com/getsentry/sentry-javascript/blob/b47ceafbdac7f8b99093ce6023726ad4687edc48/packages/browser/src/integrations/useragent.ts#L30-L42
    request.url = request.url ?? window.location.href
    request.headers = {
      ...(request.headers ?? {}),
      ...(window.document.referrer ? { Referer: window.document.referrer } : {}),
      ...(window.navigator.userAgent ? { 'User-Agent': window.navigator.userAgent } : {}),
    }

    const perf = window.performance as Performance & {
      // Chromium API extension
      readonly memory?: {
        readonly jsHeapSizeLimit: number
        readonly totalJSHeapSize: number
        readonly usedJSHeapSize: number
      }
    }

    try {
      if (perf.memory) {
        const sizeToMb = (size: number) => (size / Math.pow(1000, 2)).toFixed(2)
        event.contexts.memory = {
          jsHeapSizeLimitMB: sizeToMb(perf.memory.jsHeapSizeLimit),
          totalJSHeapSizeMB: sizeToMb(perf.memory.totalJSHeapSize),
          usedJSHeapSizeMB: sizeToMb(perf.memory.usedJSHeapSize),
        }

        const usedHeapSizePercentage = perf.memory.usedJSHeapSize / perf.memory.jsHeapSizeLimit
        event.measurements['memory.usedheapsizepercentage'] = {
          value: Math.round(usedHeapSizePercentage * 100),
        }

        const floorPercentage = Math.floor(usedHeapSizePercentage * 10) * 10
        const ceilPercentage = Math.ceil(usedHeapSizePercentage * 10) * 10
        event.tags.usedHeapSizePercentageRange = `${floorPercentage}-${ceilPercentage}`
      }
    } catch (err) {
      // Empty on purpose
    }

    // https://github.com/getsentry/sentry-javascript/blob/8c77bb6a0b1602ffc7ad802938897cc63a26c67a/packages/tracing/src/browser/metrics.ts#L269-L295
    const isMeasurementValue = (value: unknown) => typeof value === 'number' && isFinite(value)
    const nav = window.navigator as Navigator & { deviceMemory?: number }

    try {
      event.contexts.hardware = {
        hardwareConcurrency: nav.hardwareConcurrency,
        deviceMemory: nav.deviceMemory,
      }

      if (isMeasurementValue(nav.deviceMemory)) {
        event.tags.deviceMemory = String(nav.deviceMemory)
      }

      if (isMeasurementValue(nav.hardwareConcurrency)) {
        event.tags.hardwareConcurrency = String(nav.hardwareConcurrency)
      }
    } catch (err) {
      // Empty on purpose
    }

    return { ...event, request }
  }

  // Basically counters https://github.com/getsentry/sentry-javascript/blob/c8b3691be67518c422eeb9067a90d01cb2bf1f4c/packages/utils/src/misc.ts#L250-L286
  // For more info see https://github.com/getsentry/sentry-javascript/issues/4525
  private removeAlreadyCaught(error: unknown) {
    if (typeof error !== 'object' || error == null) {
      return error
    }

    const errorObject = error as {
      __sentry_captured__?: boolean
    }

    try {
      if (errorObject.__sentry_captured__) {
        delete errorObject.__sentry_captured__
      }
    } catch (err) {
      // Empty on purpose
    }

    return errorObject
  }

  private captureErrorEventUnbound(event: ErrorEvent) {
    let error = event

    // Sometimes the actual error might be buried deeper
    try {
      while ('error' in error && error.error != null) {
        error = error.error
      }
    } catch (err) {
      // Empty on purpose
    }

    this.hub?.configureScope((scope) => {
      scope.setTag('stack_includes_current_bundle', this.stackIncludesCurrentBundle(error))
    })

    // TODO: Check if ~all relevant errors from error events include the tag above, and if so uncomment the line below (& the 'if (this.currentBundle)' on init)
    // See: https://paja.mehilainen.local/sovelluskehitys/ajanvaraus-front/-/merge_requests/142#note_120130
    // if (!this.stackIncludesCurrentBundle(error)) {
    //   return
    // }

    this.hub?.run((hub) => {
      hub.withScope((scope) => {
        scope.setTag('source', SentryErrorSource.ErrorEvent)
        scope.setContext('errorEvent', { ...event })

        hub.captureException(this.removeAlreadyCaught(error))
      })
    })
  }

  private captureUnhandledRejectionEventUnbound(event: PromiseRejectionEvent) {
    let error = event as unknown

    // https://github.com/getsentry/sentry-javascript/blob/86fa701fdb00ea9dca9d26ff9d40aab09605e633/packages/browser/src/integrations/globalhandlers.ts#L123-L140
    try {
      if ('reason' in event && event.reason != null) {
        error = event.reason
      } else if (
        'detail' in event &&
        'reason' in (event as unknown as { detail: { reason: Error } }).detail &&
        (event as unknown as { detail: { reason: Error } }).detail.reason != null
      ) {
        error = (event as unknown as { detail: { reason: Error } }).detail.reason
      }
    } catch (err) {
      // Empty on purpose
    }

    this.hub?.configureScope((scope) => {
      scope.setTag('stack_includes_current_bundle', this.stackIncludesCurrentBundle(error))
    })

    // TODO: Check if ~all relevant errors from unhandled rejection events include the tag above, and if so uncomment the line below (& the 'if (this.currentBundle)' on init)
    // See: https://paja.mehilainen.local/sovelluskehitys/ajanvaraus-front/-/merge_requests/142#note_120130
    // if (!this.stackIncludesCurrentBundle(error)) {
    //   return
    // }

    this.hub?.run((hub) => {
      hub.withScope((scope) => {
        scope.setTag('source', SentryErrorSource.UnhandledRejectionEvent)
        scope.setContext('promiseRejectionEvent', { ...event })

        hub.captureException(this.removeAlreadyCaught(error))
      })
    })
  }

  private captureAPIErrorUnbound(errorResponse?: HttpResponse<unknown>, error?: Error | APIError) {
    this.hub?.run((hub) => {
      hub.withScope((scope) => {
        scope.setTag('source', SentryErrorSource.APIError)
        if (errorResponse) {
          scope.setContext('errorResponse', { ...errorResponse })
        }

        if (errorResponse || error) {
          hub.captureException(
            this.removeAlreadyCaught(error) ?? this.removeAlreadyCaught(errorResponse!.error)
          )
        }
      })
    })
  }

  private captureErrorBoundaryErrorUnbound(
    error: Error,
    { componentStack }: ErrorInfo,
    captureContext?: SentryCaptureContext
  ) {
    this.hub?.run((hub) => {
      hub.withScope((scope) => {
        scope.setTag('source', SentryErrorSource.ErrorBoundary)
        scope.setContext('error', { ...error })

        scope.update({
          contexts: { react: { componentStack } },
          ...captureContext,
        })

        hub.captureException(this.removeAlreadyCaught(error))
      })
    })
  }

  public addBreadcrumb(breadcrumb: Sentry.Breadcrumb) {
    this.hub?.configureScope((scope) => {
      scope.addBreadcrumb(breadcrumb)
    })
  }

  public startTransaction(
    context: SentryTransactionContext,
    customSamplingContext?: SentryCustomSamplingContext
  ) {
    let transaction: SentryTracing.Transaction | undefined
    this.hub?.run((hub) => {
      transaction = hub.startTransaction(
        context,
        customSamplingContext
      ) as SentryTracing.Transaction

      hub.configureScope((scope) => {
        scope.setSpan(transaction)
      })
    })

    return transaction
  }

  public finishTransaction(transaction?: SentryTracing.Transaction, endTimestamp?: number) {
    this.hub?.run((hub) => {
      transaction?.finish(endTimestamp)

      hub.configureScope((scope) => {
        scope.setSpan()
      })
    })
  }

  public RecoilTransactionObserver = () => {
    // TODO: Get contents of all nodes on error & add them as error context (make sure not to include on transactions)
    useRecoilTransactionObserver_UNSTABLE(({ snapshot }) => {
      for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
        const loadable = snapshot.getLoadable(node)

        if (loadable.state === 'hasValue') {
          this.addBreadcrumb({
            category: 'atom.modification',
            message: node.key,
            level: Sentry.Severity.Info,
            data: {
              ...(typeof loadable.contents === 'object'
                ? loadable.contents
                : { value: loadable.contents }),
            },
          })
        }
      }
    })

    return null
  }
}

const sentryService = new SentryService()

export { sentryService as default }
