import styled from '@emotion/styled'
import equal from 'fast-deep-equal'
import { Formik, useFormikContext } from 'formik'
import type React from 'react'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useRecoilValue } from 'recoil'

import type {
  AppointmentType,
  LanguageResult,
  PatientAgeGroup,
  PublicNode,
  TimeRange,
} from '../../../__generated__/api'
import { searchTargetRestoredAtom } from '../../../state/search/atoms'
import { searchFilterOptionsSelector } from '../../../state/search/selectors'
import { useIsMobile } from '../../hooks/useBreakpoint'
import { useDeepCompareEffect } from '../../hooks/useDeepCompare'
import { ColumnFlex, RowFlex, VisuallyHidden } from '../Layout/Layout'
import { Text } from '../Typography/Typography'

import AppointmentTypeSelect from './AppointmentTypeSelect'
import DurationSelect from './DurationSelect'
import GenderSelect from './GenderSelect'
import LanguageSelect from './LanguageSelect'
import PatientAgeGroupSelect from './PatientAgeGroupSelect'
import TimeRangeSelect from './TimeRangeSelect'
import { Gender } from './types'

const Container = styled(ColumnFlex)<{ isMobile: boolean }>`
  min-width: 300px;
  padding-left: 5px;

  & > * {
    margin-bottom: 4px;
  }

  .MuiFormControlLabel-label {
    font-size: ${(props) => (props.isMobile ? '0.813rem' : '0.938rem')};
  }
`

const Heading = styled(Text)<{
  isMobile: boolean
}>`
  margin-bottom: ${(props) => (props.isMobile ? '28px' : '40px')};
`
Heading.defaultProps = { as: 'h1', $size: 600, $weight: 'Medium' }

const HeadingContentContainer = styled(RowFlex)`
  align-items: center;
  width: 100%;
`

type FilterOptions = {
  appointmentTypes: AppointmentType[]
  timeRanges: TimeRange[]
  gender: Gender
  language: LanguageResult | null
  patientAgeGroup: PatientAgeGroup | null
  durations: number[]
}

interface Props {
  languages: LanguageResult[]
  patientAgeGroups: PatientAgeGroup[]
  durations: number[]
  minimal?: boolean
  selectedNode: PublicNode | null
}

const FormikSync: React.VFC = () => {
  const filterOptions = useRecoilValue(searchFilterOptionsSelector)
  const { values, submitForm, dirty, submitCount, setValues } = useFormikContext()

  useDeepCompareEffect(() => {
    if (!equal(filterOptions, values)) {
      setValues(filterOptions)
    }
  }, [filterOptions])

  useDeepCompareEffect(() => {
    if (0 < submitCount || dirty) {
      void submitForm()
    }
  }, [values])

  return null
}

const FilterOptions: React.VFC<Props> = ({
  languages,
  patientAgeGroups,
  durations,
  minimal,
  selectedNode,
}) => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const headingRef = useRef<HTMLDivElement>(null)
  const [filterOptions, setFilterOptions] = useRecoilState(searchFilterOptionsSelector)
  const searchTargetRestored = useRecoilValue(searchTargetRestoredAtom)

  useEffect(() => {
    if (headingRef.current && searchTargetRestored) {
      headingRef.current.focus()
    }
  }, [searchTargetRestored])

  if (!searchTargetRestored) {
    return null
  }

  return (
    <Formik
      initialValues={filterOptions}
      onSubmit={(values) => {
        setFilterOptions(values)
      }}
    >
      {(formik) => (
        <Container as="aside" isMobile={isMobile}>
          <HeadingContentContainer>
            <Heading
              isMobile={isMobile}
              tabIndex={-1}
              ref={headingRef}
              aria-describedby="filter-options-info"
            >
              {t('component.filterOptions.heading')}
            </Heading>
            <VisuallyHidden id="filter-options-info">
              {t('component.filterOptions.aria.dynamicNote')}
            </VisuallyHidden>
          </HeadingContentContainer>
          <FormikSync />
          {!minimal && durations.length > 0 && (
            <DurationSelect
              durations={durations}
              name="durations"
              value={formik.values.durations}
              handleChange={formik.handleChange}
            />
          )}
          <AppointmentTypeSelect
            name="appointmentTypes"
            value={formik.values.appointmentTypes}
            handleChange={formik.handleChange}
            selectedNode={selectedNode}
          />
          {!minimal && (
            <PatientAgeGroupSelect
              patientAgeGroups={patientAgeGroups}
              name="patientAgeGroup"
              value={formik.values.patientAgeGroup}
              handleChange={formik.handleChange}
            />
          )}
          {!minimal && (
            <LanguageSelect
              languages={languages}
              name="language"
              value={formik.values.language}
              handleChange={formik.handleChange}
            />
          )}
          <TimeRangeSelect
            name="timeRanges"
            value={formik.values.timeRanges}
            handleChange={formik.handleChange}
          />
          {!minimal && (
            <GenderSelect
              name="gender"
              value={formik.values.gender}
              handleChange={formik.handleChange}
            />
          )}
        </Container>
      )}
    </Formik>
  )
}

export default FilterOptions
