import styled from '@emotion/styled'
import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { NotificationStyle } from '../../../__generated__/api'
import { scale } from '../../utils/scale'
import { ColumnFlex } from '../Layout/Layout'
import Modal from '../Modal/Modal'
import Notification from '../Notification/Notification'
import { Text } from '../Typography/Typography'

const ModalContainer = styled(ColumnFlex)`
  gap: ${scale(3)};
`

const InstructionsContainer = styled(Text)`
  white-space: pre-wrap;
`

const CallbackInstructions: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  return (
    <>
      <Notification
        style={NotificationStyle.Info}
        action={
          <Button onClick={() => setModalOpen(true)} variant="outlined" size="small" color="info">
            {t('common.readMore')}
          </Button>
        }
      >
        {t('component.callbackInstructions.text')}
      </Notification>
      {modalOpen && (
        <Modal open onClose={() => setModalOpen(false)}>
          <ModalContainer>
            <Text $size={400} $weight="Medium" $height="Medium">
              {t('component.callbackInstructions.modal.heading')}
            </Text>
            <Text $size={300} $weight="Regular">
              {t('component.callbackInstructions.modal.description')}
            </Text>
            <InstructionsContainer as="div" $size={300} $weight="Regular" $height="Large">
              <Text $size={300} $weight="Regular" $height="Large">
                {t('component.callbackInstructions.text')}
              </Text>
            </InstructionsContainer>
          </ModalContainer>
        </Modal>
      )}
    </>
  )
}

export default CallbackInstructions
