import styled from '@emotion/styled'
import React, { ReactNode, useState } from 'react'

import useTranslation from '../../hooks/useTranslation'
import { scale } from '../../utils/scale'
import { DefaultButton } from '../DefaultButton/DefaultButton'
import { CenteredColumnFlex, ColumnFlex, MinMaxDiv } from '../Layout/Layout'
import RadioButtonGroup from '../RadioButtonGroup/RadioButtonGroup'
import { Text } from '../Typography/Typography'

const ContentContainer = styled(MinMaxDiv)`
  margin: ${scale(2)};
`

const HeadingContainer = styled(ColumnFlex)`
  text-align: center;
  gap: ${scale(2)};
`

const ButtonContainer = styled(CenteredColumnFlex)`
  margin-top: ${scale(3)};
  gap: ${scale(1.5)};
`

interface Props<T> {
  options: T[]
  heading: string
  selectHeading?: string
  dataCyContinue?: string
  dataCyBack?: string
  dataAnalyticsPathContinue?: string
  dataAnalyticsPathBack?: string
  renderOptionHeader(option: T): ReactNode
  renderOptionDescription?(option: T): ReactNode
  onSelect(option: T): void
  onBack(): void
}

const InsuranceOptionsSelect = <T,>({
  options,
  heading,
  selectHeading,
  dataCyBack,
  dataCyContinue,
  dataAnalyticsPathBack,
  dataAnalyticsPathContinue,
  renderOptionHeader,
  renderOptionDescription,
  onSelect,
  onBack,
}: Props<T>) => {
  const { t } = useTranslation()
  const [selectedOption, setSelectedOption] = useState<T>()

  return (
    <ContentContainer size="740px">
      <HeadingContainer>
        <Text $size={500} $height="Medium" $weight="Medium">
          {heading}
        </Text>
        {selectHeading && (
          <Text $size={300} $height="Large" $weight="Regular">
            {selectHeading}
          </Text>
        )}
      </HeadingContainer>
      <MinMaxDiv size="540px">
        <RadioButtonGroup
          options={options}
          renderOptionHeader={renderOptionHeader}
          renderOptionDescription={renderOptionDescription}
          onChange={(option) => setSelectedOption(option)}
          value={selectedOption}
        />
      </MinMaxDiv>
      <ButtonContainer>
        <DefaultButton
          onClick={() => selectedOption && onSelect(selectedOption)}
          disabled={!Boolean(selectedOption)}
          data-cy={dataCyContinue}
          data-analytics-path={dataAnalyticsPathContinue}
        >
          {t('common.continue')}
        </DefaultButton>
        <DefaultButton
          variant="outlined"
          onClick={onBack}
          data-cy={dataCyBack}
          data-analytics-path={dataAnalyticsPathBack}
        >
          {t('common.back')}
        </DefaultButton>
      </ButtonContainer>
    </ContentContainer>
  )
}

export default InsuranceOptionsSelect
