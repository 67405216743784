import { FormikProps } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'

import TextAreaNew from '../../../common/components/InputFieldNew/TextAreaNew'
import TextFieldNew from '../../../common/components/InputFieldNew/TextFieldNew'

import ReserveFormFieldset from './ReserveFormFieldset'

interface Props
  extends Pick<
    FormikProps<{
      additionalInformation: string
      discountCode: string
    }>,
    'values' | 'handleChange' | 'handleBlur' | 'errors'
  > {
  additionalInformationMaxLength: number
  isOHC: boolean
}

const ReserveAdditionalInformationForm: React.FC<React.PropsWithChildren<Props>> = ({
  values,
  errors,
  handleChange,
  handleBlur,
  additionalInformationMaxLength,
  isOHC,
}) => {
  const { t, i18n } = useTranslation()
  return (
    <ReserveFormFieldset legend={t('component.reserveAdditionalInformationForm.subHeading')}>
      <TextAreaNew
        id="additionalInformation"
        rows={4}
        label={t('component.reserveAdditionalInformationForm.label.additionalInformation')}
        helperText={errors.additionalInformation}
        error={Boolean(errors.additionalInformation)}
        characterCount={`${values.additionalInformation.length} / ${additionalInformationMaxLength}`}
        value={values.additionalInformation}
        onChange={handleChange}
        onBlur={handleBlur}
        maxLength={additionalInformationMaxLength}
      />
      {!isOHC && (
        <TextFieldNew
          id="discountCode"
          label={t('component.reserveAdditionalInformationForm.label.discountCode')}
          helperText={
            errors.discountCode
              ? errors.discountCode
              : i18n.exists('component.reserveAdditionalInformationForm.helperText.discountCode')
              ? t('component.reserveAdditionalInformationForm.helperText.discountCode')
              : undefined
          }
          value={values.discountCode}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      )}
    </ReserveFormFieldset>
  )
}

export default ReserveAdditionalInformationForm
