import styled from '@emotion/styled'
import React from 'react'

import { ColumnFlex } from '../../../common/components/Layout/Layout'
import { Text } from '../../../common/components/Typography/Typography'
import { breakpoint } from '../../../common/utils/breakpoint'
import { scale } from '../../../common/utils/scale'

const StyledFieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  gap: ${scale(2)};

  @media (min-width: ${breakpoint.sm}px) {
    display: grid;
    grid-template-columns: 248px 1fr;
    grid-column-gap: ${scale(2)};
  }
`

const StyledLegend = styled(Text)`
  display: contents;
`
StyledLegend.defaultProps = { as: 'legend' }

interface Props {
  legend: string
}

const ReserveFormFieldset: React.FC<React.PropsWithChildren<Props>> = ({ legend, children }) => {
  return (
    <StyledFieldset>
      <StyledLegend as="legend" $size={500} $weight="Medium" $height="Medium">
        {legend}
      </StyledLegend>
      <ColumnFlex>{children}</ColumnFlex>
    </StyledFieldset>
  )
}

export default ReserveFormFieldset
