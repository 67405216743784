// This file is meant to include ONLY utilities that replicate 1:1 stuff that www codebase does

// <gitlab repository url>/-/blob/d0731e47dbc23b4ba4b456f821907a2ff5e607b9/gatsby-node/utils.js#L148-159
const normalize = (str: string) =>
  str
    .split('')
    .map((c) => c.normalize('NFKD')[0])
    .join('')

const slugify = (str: string) =>
  normalize(str)
    .toLocaleLowerCase()
    .replace(/[^a-z]/gi, ' ')
    .trim()
    .replace(/\s+/g, '-')

// <gitlab repository url>/-/blob/d0731e47dbc23b4ba4b456f821907a2ff5e607b9/gatsby-node/palveluhaku.js#L1300-1324
const createUniqueSlugify = () => {
  const slugs: { [lang: string]: Set<string> } = {}
  return (lang: string, str: string) => {
    if (!slugs[lang]) {
      slugs[lang] = new Set()
    }
    const set = slugs[lang]
    const base = slugify(str)

    let slug = base
    for (let i = 0; ; i++) {
      if (!set.has(slug)) {
        set.add(slug)
        return slug
      }
      slug = `${base}-${i}`
    }
  }
}

// <gitlab repository url>/-/blob/d0731e47dbc23b4ba4b456f821907a2ff5e607b9/gatsby-node/palveluhaku.js#L697-703
export const getPractitionerWWWPath = (language: string, fullName: string) => {
  const slugifySpecialist = createUniqueSlugify()

  const langPrefix = language === 'fi' ? '' : language === 'en' ? 'en/' : 'sv/'
  const pathPrefix =
    language === 'fi'
      ? 'laakarit-ja-asiantuntijat'
      : language === 'en'
      ? 'doctors-and-specialists'
      : 'lakare-och-specialister'

  const slug = slugifySpecialist(language, fullName)

  return process.env.REACT_APP_WWW_BASE_URL + `/${langPrefix}${pathPrefix}/${slug}`
}

// <gitlab repository url>/-/blob/d0731e47dbc23b4ba4b456f821907a2ff5e607b9/gatsby-node/palveluhaku.js#L848-855
export const getLocationWWWPath = (language: string, nameFi: string) => {
  const slugifyLocation = createUniqueSlugify()
  const langPrefix = language === 'fi' ? '' : language === 'en' ? 'en/' : 'sv/'

  const pathPrefix =
    language === 'fi' ? 'toimipisteet' : language === 'en' ? 'locations' : 'verksamhetsstallen'

  const slug = slugifyLocation(language, nameFi)

  return process.env.REACT_APP_WWW_BASE_URL + `/${langPrefix}${pathPrefix}/${slug}`
}
