import type { Dayjs } from 'dayjs'
import { DefaultValue, selector } from 'recoil'

import {
  SearchNodeAppointmentsParams,
  AppointmentType,
  LanguageResult,
  PatientAgeGroup,
  TimeRange,
  AppointmentSearchByPractitionerParams,
  Gender as ApiGender,
} from '../../__generated__/api'
import type FilterOptions from '../../common/components/FilterOptions/FilterOptions'
import { Gender } from '../../common/components/FilterOptions/types'
import dayjs from '../../common/utils/dayjs/dayjs'
import { selectedInsuranceAtom, isOHCAtom } from '../common/atoms'

import {
  defaultSelectedAppointmentTypes,
  defaultSelectedTimeRanges,
  selectedAppointmentTypesAtom,
  defaultSelectedSearchNodeId,
  selectedDateAtom,
  selectedGenderAtom,
  selectedLanguageAtom,
  selectedNodeLocationAtom,
  selectedPatientAgeGroupAtom,
  selectedSearchNodeIdAtom,
  selectedTimeRangesAtom,
  selectedDurationsAtom,
  selectedSearchPractitionerAtom,
  selectedFlexibleScheduleOptionAtom,
  selectedStandardScheduleOptionAtom,
  selectedPractitionerLocationAtom,
  userSelectedAppointmentTypesAtom,
  appointmentTypesNoCallback,
  defaultOhcSearchNodeId,
} from './atoms'

export const getDefaultNode = selector<string>({
  key: 'search-getDefaultNode',
  get: ({ get }) => {
    const isOHC = get(isOHCAtom)
    return isOHC ? defaultOhcSearchNodeId : defaultSelectedSearchNodeId
  },
})

export const getSelectedNodeOrDefault = selector<string>({
  key: 'search-getSelectedOrDefault',
  get: ({ get }) => {
    const selectedNode = get(selectedSearchNodeIdAtom)
    if (selectedNode) {
      return selectedNode
    }

    const defaultNode = get(getDefaultNode)
    return defaultNode
  },
  set: ({ set }, newValue) => {
    set(selectedSearchNodeIdAtom, newValue)
  },
})

export const searchFilterOptionsSelector = selector<FilterOptions>({
  key: 'search-searchFilterOptionsSelector',
  get: ({ get }) => {
    const appointmentTypes = get(selectedAppointmentTypesAtom)
    const gender = get(selectedGenderAtom)
    const language = get(selectedLanguageAtom)
    const timeRanges = get(selectedTimeRangesAtom)
    const patientAgeGroup = get(selectedPatientAgeGroupAtom)
    const durations = get(selectedDurationsAtom)
    return {
      appointmentTypes,
      timeRanges,
      patientAgeGroup,
      gender,
      language,
      durations,
    }
  },
  set: ({ set }, filterOptions) => {
    if (filterOptions instanceof DefaultValue) {
      set(selectedTimeRangesAtom, defaultSelectedTimeRanges)
      set(selectedLanguageAtom, null)
      set(selectedGenderAtom, Gender.UNSPECIFIED)
      set(userSelectedAppointmentTypesAtom, defaultSelectedAppointmentTypes)
      set(selectedAppointmentTypesAtom, defaultSelectedAppointmentTypes)
      set(selectedPatientAgeGroupAtom, null)
      set(selectedDurationsAtom, [])
      return
    }
    set(selectedTimeRangesAtom, filterOptions.timeRanges)
    set(selectedLanguageAtom, filterOptions.language)
    set(selectedGenderAtom, filterOptions.gender)
    set(userSelectedAppointmentTypesAtom, filterOptions.appointmentTypes)
    set(selectedAppointmentTypesAtom, filterOptions.appointmentTypes)
    set(selectedPatientAgeGroupAtom, filterOptions.patientAgeGroup)
    set(selectedDurationsAtom, filterOptions.durations)
  },
})

export const nodeSearchParamsSelector = selector<SearchNodeAppointmentsParams>({
  key: 'search-nodeSearchParamsSelector',
  get: ({ get }) => {
    const selectedDate = get(selectedDateAtom)
    const selectedTimeRanges = get(selectedTimeRangesAtom)
    const selectedPatientAgeGroup = get(selectedPatientAgeGroupAtom)
    const selectedLocation = get(selectedNodeLocationAtom)
    const selectedAppointmentTypes = get(selectedAppointmentTypesAtom)
    const selectedLanguage = get(selectedLanguageAtom)
    const selectedGender = get(selectedGenderAtom)
    const selectedNodeId = get(getSelectedNodeOrDefault)
    const selectedDurations = get(selectedDurationsAtom)
    const selectedInsurance = get(selectedInsuranceAtom)
    const isOHC = get(isOHCAtom)

    return mapToNodeSearchParams(
      selectedNodeId,
      selectedLocation,
      selectedAppointmentTypes.length ? selectedAppointmentTypes : appointmentTypesNoCallback,
      selectedLanguage,
      selectedGender,
      selectedPatientAgeGroup,
      selectedDate,
      selectedTimeRanges,
      selectedDurations,
      selectedInsurance === 'other' ? null : selectedInsurance,
      isOHC
    )
  },
})

export const nodeCalendarSearchParamsSelector = selector<
  Omit<SearchNodeAppointmentsParams, 'startDate'>
>({
  key: 'search-nodeCalendarSearchParamsSelector',
  get: ({ get }) => {
    const selectedLocation = get(selectedNodeLocationAtom)
    const selectedAppointmentTypes = get(selectedAppointmentTypesAtom)
    const selectedLanguage = get(selectedLanguageAtom)
    const selectedGender = get(selectedGenderAtom)
    const selectedTimeRanges = get(selectedTimeRangesAtom)
    const selectedNodeId = get(getSelectedNodeOrDefault)
    const selectedPatientAgeGroup = get(selectedPatientAgeGroupAtom)
    const selectedDurations = get(selectedDurationsAtom)
    const selectedInsurance = get(selectedInsuranceAtom)
    const isOHC = get(isOHCAtom)

    return mapToNodeCalendarSearchParams(
      selectedNodeId,
      selectedLocation,
      selectedAppointmentTypes.length ? selectedAppointmentTypes : appointmentTypesNoCallback,
      selectedLanguage,
      selectedGender,
      selectedPatientAgeGroup,
      selectedTimeRanges,
      selectedDurations,
      selectedInsurance === 'other' ? null : selectedInsurance,
      isOHC
    )
  },
})

const mapToNodeSearchParams = (
  id: string,
  selectedLocation: string[],
  selectedAppointmentTypes: AppointmentType[],
  selectedLanguage: LanguageResult | null,
  selectedGender: Gender,
  selectedPatientAgeGroup: PatientAgeGroup | null,
  selectedDate?: Dayjs,
  selectedTimeRanges?: TimeRange[],
  selectedDurations?: number[],
  selectedInsurance?: number | null,
  isOHC?: boolean
): SearchNodeAppointmentsParams => ({
  ...mapToNodeCalendarSearchParams(
    id,
    selectedLocation,
    selectedAppointmentTypes,
    selectedLanguage,
    selectedGender,
    selectedPatientAgeGroup,
    selectedTimeRanges,
    selectedDurations,
    selectedInsurance
  ),
  startDate: (selectedDate ?? dayjs()).toJSON(),
  isOHC: isOHC,
})

const mapToNodeCalendarSearchParams = (
  id: string,
  selectedLocation: string[],
  selectedAppointmentTypes: AppointmentType[],
  selectedLanguage: LanguageResult | null,
  selectedGender: Gender,
  selectedPatientAgeGroup: PatientAgeGroup | null,
  selectedTimeRanges?: TimeRange[],
  selectedDurations?: number[],
  selectedInsurance?: number | null,
  isOHC?: boolean
): Omit<SearchNodeAppointmentsParams, 'startDate'> => ({
  id: id,
  locationIds: selectedLocation,
  appointmentTypes: selectedAppointmentTypes,
  ...(selectedTimeRanges?.length !== defaultSelectedTimeRanges.length
    ? { timeRanges: selectedTimeRanges }
    : {}),
  ...(selectedLanguage ? { languageId: Number(selectedLanguage.id) } : {}),
  ...(selectedGender === Gender.FEMALE
    ? { gender: ApiGender.F }
    : selectedGender === Gender.MALE
    ? { gender: ApiGender.M }
    : {}),
  ...(selectedPatientAgeGroup ? { patientAgeGroupId: selectedPatientAgeGroup.id } : {}),
  ...(selectedDurations && selectedDurations.length > 0 ? { durations: selectedDurations } : {}),
  ...(selectedInsurance !== null ? { insuranceContractId: selectedInsurance } : {}),
  isOHC: isOHC,
})

export const practitionerSearchParamsSelector = selector<
  Omit<AppointmentSearchByPractitionerParams, 'lang'> | undefined
>({
  key: 'search-practitionerSearchParamsSelector',
  get: ({ get }) => {
    const selectedPractitioner = get(selectedSearchPractitionerAtom)
    const selectedFlexibleScheduleOption = get(selectedFlexibleScheduleOptionAtom)
    const selectedStandardScheduleOption = get(selectedStandardScheduleOptionAtom)
    const selectedAppointmentTypes = get(selectedAppointmentTypesAtom)
    const selectedLocation = get(selectedPractitionerLocationAtom)
    const selectedDate = get(selectedDateAtom)
    const selectedTimeRanges = get(selectedTimeRangesAtom)

    if (!selectedPractitioner) {
      return undefined
    }

    if (selectedPractitioner.hasFlexibleSchedule && !selectedFlexibleScheduleOption) {
      return undefined
    }

    return {
      practitionerId: selectedPractitioner.id,
      appointmentTypes:
        selectedAppointmentTypes.length === 0
          ? appointmentTypesNoCallback
          : selectedAppointmentTypes,
      startDate: (selectedDate ?? dayjs()).toJSON(),
      ...(selectedFlexibleScheduleOption
        ? {
            durations: selectedFlexibleScheduleOption.availableDurations,
            serviceId: selectedFlexibleScheduleOption.serviceId,
            location: selectedFlexibleScheduleOption.locations,
          }
        : typeof selectedStandardScheduleOption === 'number'
        ? { duration: selectedStandardScheduleOption }
        : selectedStandardScheduleOption.startsWith('node-')
        ? { nodeId: selectedStandardScheduleOption.substring('node-'.length) }
        : selectedStandardScheduleOption.startsWith('reason-')
        ? { reasonId: selectedStandardScheduleOption.substring('reason-'.length) }
        : {}),
      ...(selectedTimeRanges?.length !== defaultSelectedTimeRanges.length
        ? { timeRanges: selectedTimeRanges }
        : {}),
      ...(selectedLocation.length > 0 ? { location: selectedLocation } : {}),
    }
  },
})
