import styled from '@emotion/styled'
import { Primary500 } from '@mehilainen/design-system-tokens/colors'
import { ArrowLeft } from '@mehilainen/mds-customer/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Text } from '../../Typography/Typography'

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background: transparent;
  border: none;
  cursor: pointer;
  text-align: left;
  color: ${Primary500};
`

interface Props {
  onBack(): void
  className?: string
  label?: string
}

const BackButton: React.FC<React.PropsWithChildren<Props>> = ({ onBack, className, label }) => {
  const { t } = useTranslation()

  return (
    <StyledButton className={className} onClick={onBack}>
      <ArrowLeft />
      <Text $size={300} $weight="Regular" $height="Small" $color={Primary500}>
        {label ? label : t('common.back')}
      </Text>
    </StyledButton>
  )
}

export default styled(BackButton)``
