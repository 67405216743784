import { AppointmentType, DCNotification } from '../../../__generated__/api'

export const GetDCNotification = (
  notifications: DCNotification[],
  selectedAppointmentTypes: AppointmentType[],
  nrOfAppointments: number
): DCNotification | null => {
  const filtered = notifications.filter(
    (notification) =>
      (selectedAppointmentTypes.length === 0 ||
        (notification.showPhoneAppointments &&
          selectedAppointmentTypes.includes(AppointmentType.Phone)) ||
        (notification.showRegularAppointments &&
          selectedAppointmentTypes.includes(AppointmentType.Clinic)) ||
        (notification.showVideoAppointments &&
          selectedAppointmentTypes.includes(AppointmentType.Video))) &&
      nrOfAppointments <= (notification.searchCountRestrictions ?? Number.MAX_SAFE_INTEGER)
  )
  return filtered.length > 0 ? filtered[0] : null
}
