import { LocationResult, SupportedLanguage } from '../../__generated__/api'

import api from './api'

export enum GeolocateStatus {
  OK = 'ok',
  TIMEOUT = 'timeout',
  PERMISSION_DENIED = 'permissionDenied',
  POSITION_UNAVAILABLE = 'positionUnavailable',
  API_ERROR = 'apiError',
  NO_RESULTS = 'noResults',
}

const getCoords = async (): Promise<{ lat: number; long: number }> => {
  const position: { coords: { latitude: number; longitude: number } } = await new Promise(
    (resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, {
        timeout: /* 10s */ 10000,
        maximumAge: /* 30min */ 1000 * 60 * 30,
      })
    }
  )

  return {
    lat: position.coords.latitude,
    long: position.coords.longitude,
  }
}

export type GeolocateResult = { results: LocationResult[]; status: GeolocateStatus }

export const getGeolocateResults = async (
  lang: SupportedLanguage,
  nodeId?: string,
  isOHC?: boolean
): Promise<GeolocateResult> => {
  try {
    const coords = await getCoords()
    const res = await api.v1.getGeolocateResults({ ...coords, lang, nodeId, isOHC })
    return {
      results: res.data,
      status: res.data.length === 0 ? GeolocateStatus.NO_RESULTS : GeolocateStatus.OK,
    }
  } catch (error) {
    if (error instanceof GeolocationPositionError) {
      const status =
        error.code === GeolocationPositionError.PERMISSION_DENIED
          ? GeolocateStatus.PERMISSION_DENIED
          : error.code === GeolocationPositionError.POSITION_UNAVAILABLE
          ? GeolocateStatus.POSITION_UNAVAILABLE
          : error.code === GeolocationPositionError.TIMEOUT
          ? GeolocateStatus.TIMEOUT
          : GeolocateStatus.POSITION_UNAVAILABLE
      return { results: [], status }
    }
    return { results: [], status: GeolocateStatus.API_ERROR }
  }
}
