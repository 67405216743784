import styled from '@emotion/styled'
import React, { useState } from 'react'
import { useContext } from 'react'

import { TabTitleContext } from './TabTitleContext'

const ScreenReaderOnly = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
`

const TabTitle = (props: { value: string }) => {
  const defaultTitle = useContext(TabTitleContext)
  const divider = props.value && defaultTitle ? ' - ' : ''
  const parts = defaultTitle.split(' - ')
  document.title = props.value + divider + parts[parts.length - 1]
  const [titleAnnouncement, setTitleAnnouncement] = useState(defaultTitle)

  React.useEffect(() => {
    setTimeout(() => {
      setTitleAnnouncement(document.title)
    }, 1500)
  }, [])

  return (
    <>
      <ScreenReaderOnly id="titleAnnouncer" aria-live="polite">
        {titleAnnouncement}
      </ScreenReaderOnly>
    </>
  )
}
export default TabTitle
