import { FormikProps } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ColumnFlex } from '../../../common/components/Layout/Layout'
import { scale } from '../../../common/utils/scale'

import { ReserveCheckbox } from './FormComponents'
import ReserveFormFieldset from './ReserveFormFieldset'

interface Props
  extends Pick<
    FormikProps<{
      serviceMessagePermit?: boolean
    }>,
    'values' | 'handleChange' | 'errors'
  > {}

const ReserveServiceMessagePermissions: React.FC<React.PropsWithChildren<Props>> = ({
  values,
  handleChange,
}) => {
  const { t } = useTranslation()
  return (
    <ReserveFormFieldset legend={t('component.reserveServiceMessagePermissions.heading')}>
      <ColumnFlex $gap={scale(2)}>
        <ReserveCheckbox
          name={'serviceMessagePermit'}
          checked={values.serviceMessagePermit}
          onChange={handleChange}
          label={t('component.reserveServiceMessagePermissions.serviceMessagePermit')}
        />
      </ColumnFlex>
    </ReserveFormFieldset>
  )
}

export default ReserveServiceMessagePermissions
