import React from 'react'

import LahiTapiolaForm, {
  LahiTapiolaInformation,
} from './DirectCompensationInsurance/LahiTapiolaForm'
import PohjolaForm from './DirectCompensationInsurance/PohjolaForm'
import OccupationalAccidentForm, {
  OccupationalAccidentInformation,
} from './OccupationalAccidentForm'
import TrafficAccidentForm, { TrafficAccidentInformation } from './TrafficAccidentForm'

export type InsuranceAdditionalDetailsType =
  | TrafficAccidentInformation
  | OccupationalAccidentInformation
  | LahiTapiolaInformation
// extend type as needed

interface Props {
  additionalInfoForm: string
  onSubmitDetails(details?: InsuranceAdditionalDetailsType): void
  onContinueAsSelfPaying(): void
  onBack(): void
}

const InsuranceAdditionalDetails: React.FC<React.PropsWithChildren<Props>> = ({
  additionalInfoForm,
  onSubmitDetails,
  onBack,
  onContinueAsSelfPaying,
}) => {
  if (additionalInfoForm === 'traffic_accident') {
    return <TrafficAccidentForm onSubmit={onSubmitDetails} onBack={onBack} />
  }

  if (additionalInfoForm === 'occupational_accident') {
    return <OccupationalAccidentForm onSubmit={onSubmitDetails} onBack={onBack} />
  }

  if (additionalInfoForm === 'lahitapiola') {
    return (
      <LahiTapiolaForm
        onSubmit={onSubmitDetails}
        onContinueAsSelfPaying={onContinueAsSelfPaying}
        onBack={onBack}
      />
    )
  }

  if (additionalInfoForm === 'pohjola') {
    return (
      <PohjolaForm
        onSubmit={onSubmitDetails}
        onContinueAsSelfPaying={onContinueAsSelfPaying}
        onBack={onBack}
      />
    )
  }

  return null
}

export default InsuranceAdditionalDetails
