import styled from '@emotion/styled'
import { Primary100, Primary300, Primary700 } from '@mehilainen/design-system-tokens/colors'
import React from 'react'

import { scale } from '../../utils/scale'
import { Text } from '../Typography/Typography'

const StyledText = styled(Text)`
  border: 1px solid ${Primary300};
  border-radius: ${scale(0.5)};
  background-color: ${Primary100};
  color: ${Primary700};
  padding: ${scale(0.5)} ${scale(1)};
`

interface Props {
  text: string
}

const InfoChip: React.FC<React.PropsWithChildren<Props>> = ({ text }) => (
  <StyledText $size={300} $weight="Medium" $height="Small" role="listitem">
    {text}
  </StyledText>
)

export default InfoChip
