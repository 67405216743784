import styled from '@emotion/styled'
import { Gray600, Gray900, Primary500 } from '@mehilainen/design-system-tokens/colors'
import { Headset, MapMarker } from '@mehilainen/mds-customer/icons'
import dayjs, { Dayjs } from 'dayjs'
import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { AppointmentType } from '../../../__generated__/api'
import { useIsMobile } from '../../hooks/useBreakpoint'
import { scale } from '../../utils/scale'
import { formatCallbackTime } from '../../utils/text'
import { getLocationWWWPath } from '../../utils/www'
import { ColumnFlex, RowFlex } from '../Layout/Layout'
import TooltipModal from '../TooltipModal/TooltipModal'
import { Text } from '../Typography/Typography'

const Container = styled(ColumnFlex)`
  gap: ${scale(0.5)};
`

const TextWithIcon = styled(Text)`
  display: flex;
  align-content: center;
  justify-content: center;

  svg {
    margin-right: ${scale(0.5)};
  }
`

const IconTextContainer = styled(RowFlex)`
  align-items: center;

  svg {
    margin-right: ${scale(0.75)};
    color: ${Gray600};
  }
`

const TooltipButton = styled.button`
  text-align: left;
`

const TooltipContentContainer = styled(ColumnFlex)`
  overflow: auto;
  gap: ${scale(1.5)};
`

interface Props {
  locationName: string
  locationAddress: string
  appointmentTypes: AppointmentType[]
  locationShortName?: string
  variant?: 'S' | 'M'
  className?: string
  remoteSupported: boolean
  date: Dayjs
}

const AppointmentLocationDetails: React.FC<React.PropsWithChildren<Props>> = ({
  locationName,
  locationShortName,
  locationAddress,
  appointmentTypes,
  variant = 'M',
  className,
  remoteSupported,
  date,
}) => {
  const [popperOpen, setPopperOpen] = useState<boolean>(false)
  const [popperAnchorEl, setPopperAnchorEl] = React.useState<HTMLElement | null>(null)
  const isMobile = useIsMobile()

  const { t, i18n } = useTranslation()
  const isCallbackAppointment = appointmentTypes?.includes(AppointmentType.Callback)

  const firstLink = useRef<HTMLAnchorElement | null>(null)

  const onDetailsClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setPopperOpen(true)
    setPopperAnchorEl(event.currentTarget)
    setTimeout(() => firstLink.current?.focus(), 100)
  }

  const onDetailsKeyHandler = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      event.stopPropagation()
      setPopperOpen(true)
      setPopperAnchorEl(event.currentTarget)
    }
  }

  if (appointmentTypes.length === 0) {
    return null
  }

  const isRemoteAppointment = !appointmentTypes.includes(AppointmentType.Clinic)

  const isAlsoRemoteAppointment =
    appointmentTypes.includes(AppointmentType.Clinic) && appointmentTypes.length > 1

  const hasDetails = !isRemoteAppointment && !isMobile && !isCallbackAppointment

  const onClose = () => {
    setPopperOpen(false)
  }

  return (
    <>
      <Container className={className}>
        <Text
          as={hasDetails ? TooltipButton : 'span'}
          onClick={hasDetails ? onDetailsClick : undefined}
          onKeyPress={hasDetails ? onDetailsKeyHandler : undefined}
          $size={isMobile ? 300 : variant === 'S' ? 300 : 400}
          $weight={isMobile ? 'Regular' : 'Medium'}
          $height="Medium"
          $color={hasDetails ? Primary500 : Gray900}
        >
          {isCallbackAppointment && (
            <Text $size={variant === 'S' ? 200 : 300} $height="Medium" $color={Gray600}>
              {date && formatCallbackTime(dayjs(date)) !== ''
                ? t('component.appointmentLocationDetails.callback')
                : t('component.simpleAppointmentDetails.timeframeSubstitute')}
            </Text>
          )}
          {isRemoteAppointment && !isCallbackAppointment
            ? t('component.appointmentLocationDetails.onlyRemote')
            : isMobile && locationShortName
            ? locationShortName
            : locationName}
        </Text>
        {remoteSupported && isAlsoRemoteAppointment && !isCallbackAppointment && (
          <IconTextContainer>
            {!isMobile && <Headset />}
            <Text $size={variant === 'S' ? 300 : 300} $height="Medium" $color={Gray600}>
              {t('component.appointmentLocationDetails.alsoRemote')}
            </Text>
          </IconTextContainer>
        )}
      </Container>
      {hasDetails && (
        <TooltipModal open={popperOpen} onClose={onClose} anchorEl={popperAnchorEl}>
          <TooltipContentContainer>
            <Text
              as="a"
              href={getLocationWWWPath(i18n.language, locationName)}
              target="_blank"
              className="no-external-icon no-link-styles"
              $color={Primary500}
              $size={variant === 'S' ? 300 : 400}
              $weight="Medium"
              $height="Medium"
              ref={firstLink}
            >
              {locationName}
            </Text>
            <TextWithIcon $size={300}>
              <MapMarker fontSize="small" /> {locationAddress}
            </TextWithIcon>
            <Text
              as="a"
              href={getLocationWWWPath(i18n.language, locationName)}
              target="_blank"
              className="no-external-icon no-link-styles"
              $color={Primary500}
              $size={300}
            >
              {t('component.appointmentLocationDetails.linkText')}
            </Text>
          </TooltipContentContainer>
        </TooltipModal>
      )}
    </>
  )
}

export default styled(AppointmentLocationDetails)``
