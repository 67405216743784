import React from 'react'

import { stripTagsAllowBasicHtml } from '../../utils/xss'
import { Text } from '../Typography/Typography'

interface Props {
  introduction?: string
}

const PractitionerIntroduction: React.FC<React.PropsWithChildren<Props>> = ({ introduction }) => {
  if (!introduction) {
    return null
  }

  return (
    <Text
      as="div"
      $height="Large"
      dangerouslySetInnerHTML={{
        __html: stripTagsAllowBasicHtml(introduction),
      }}
      data-cy="practitionerIntroduction"
    />
  )
}

export default PractitionerIntroduction
