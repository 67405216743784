import Cookies from 'js-cookie'
import { useState } from 'react'

export const useCookie = <T extends object = object>(
  key: string,
  defaultValue: string | T,
  options?: typeof Cookies.attributes
): [cookie: string | T, updateCookie: (value: string | T) => void] => {
  const getCookie = () => Cookies.get(key) || defaultValue

  const [cookie, setCookie] = useState(getCookie())
  const updateCookie = (value: string | T) => {
    setCookie(value)
    Cookies.set(key, String(value), options)
  }

  return [cookie, updateCookie]
}
