import styled from '@emotion/styled'
import { Gray600, Gray900, Primary500 } from '@mehilainen/design-system-tokens/colors'
import { CircularProgress } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'

import { NotificationStyle, SupportedLanguage } from '../../../../__generated__/api'
import { DefaultButton } from '../../../../common/components/DefaultButton/DefaultButton'
import { UiAngleRight, UiExternalLink } from '../../../../common/components/Icons/Icons'
import {
  CenteredColumnFlex,
  CenteredRowFlex,
  ColumnFlex,
  MinMaxDiv,
} from '../../../../common/components/Layout/Layout'
import Notification from '../../../../common/components/Notification/Notification'
import { Text } from '../../../../common/components/Typography/Typography'
import { useApi } from '../../../../common/hooks/useApi'
import api from '../../../../common/services/api'
import { scale } from '../../../../common/utils/scale'
import {
  isFromAppAtom,
  isRedirectedFromInsuranceProviderAtom,
} from '../../../../state/common/atoms'
import useReserveState from '../../hooks/useReserveState'
import LahiTapiolaSVG from '../../images/LahiTapiola.svg?react'
import LahiTapiolaCheckSVG from '../../images/LahiTapiolaCheck.svg?react'
import { serializeQueryData } from '../InsuranceRedirect'

import CustomershipCheck from './CustomershipCheck'
import {
  ButtonContent,
  Container,
  ContentContainer,
  Heading,
  Paragraph,
  StyledButton,
} from './Shared'

export type LahiTapiolaInformation = {
  permitId: string
}

interface DirectCompensationButtonProps {
  onClick(): void
  title: string
  subtitle: string
  subtitle2: string
  subtitle3: string
}

export const DirectCompensationButton: React.FC<
  React.PropsWithChildren<DirectCompensationButtonProps>
> = ({ title, subtitle, subtitle2, subtitle3, onClick }) => {
  return (
    <StyledButton onClick={onClick} variant="outlined">
      <ButtonContent>
        <CenteredRowFlex $gap="16px">
          <LahiTapiolaCheckSVG />
          <ColumnFlex>
            <Text $size={400} $weight="Regular" $height="Medium" $color={Gray900}>
              {title}
            </Text>
            <Text $size={200} $weight="Regular" $height="Large" $color={Gray900}>
              {subtitle}
            </Text>
            <Text $size={200} $weight="Regular" $height="Large" $color={Gray600}>
              {subtitle2}
            </Text>
            <Text $size={200} $weight="Regular" $height="Large" $color={Gray600}>
              {subtitle3}
            </Text>
          </ColumnFlex>
        </CenteredRowFlex>
        <UiAngleRight htmlColor={Primary500} fontSize="large" />
      </ButtonContent>
    </StyledButton>
  )
}

interface ContinueButtonProps {
  onClick(): void
}

export const ContinueButton: React.FC<React.PropsWithChildren<ContinueButtonProps>> = ({
  onClick,
}) => {
  const { t } = useTranslation()
  return (
    <StyledButton onClick={onClick} variant="outlined">
      <ButtonContent>
        <Text $size={400} $height="Medium" $weight="Regular" $color={Gray900}>
          {t('component.lahiTapiolaForm.directCompensationSelect.continueAsSelfPaying')}
        </Text>
        <UiAngleRight htmlColor={Primary500} fontSize="large" />
      </ButtonContent>
    </StyledButton>
  )
}

interface SeekCompensationPermitsButtonProps {
  // TODO: props required for redirect
}

export const SeekCompensationPermitsButton: React.FC<
  React.PropsWithChildren<SeekCompensationPermitsButtonProps>
> = ({}) => {
  const { t, i18n } = useTranslation()
  const isFromApp = useRecoilValue(isFromAppAtom)

  const {
    selectedUser,
    appointmentId,
    selectedAppointmentType,
    selectedAppointmentLength,
    selectedInsuranceContractId,
    selectedInsurancePayerId,
    selectedNodeId,
    selectedServiceId,
  } = useReserveState()

  if (!selectedInsuranceContractId) {
    throw `Could not form link for seeking insurance compensation permit: no insurance contract id selected.`
  }

  if (!selectedInsurancePayerId) {
    throw `Could not form link for seeking insurance compensation permit: no insurance payer id selected.`
  }

  const data = serializeQueryData({
    userOmUid: selectedUser.omUid,
    appointmentId,
    appointmentType: selectedAppointmentType,
    appointmentLength: selectedAppointmentLength,
    language: i18n.language as SupportedLanguage,
    insuranceContractId: selectedInsuranceContractId,
    insurancePayerId: selectedInsurancePayerId,
    ...(selectedNodeId ? { nodeId: selectedNodeId } : {}),
    ...(selectedServiceId ? { serviceId: selectedServiceId } : {}),
    isFromApp,
  })

  return (
    <StyledButton
      href={`${process.env.REACT_APP_INSURANCES_API_URL}/v1/direct-compensation-permits/lähitapiola?channel=web&lang=${i18n.language}&data=${data}`}
      variant="outlined"
      className="no-link-styles no-external-icon"
    >
      <ButtonContent>
        <Text $size={400} $height="Medium" $weight="Regular" $color={Gray900}>
          {t('component.lahiTapiolaForm.directCompensationSelect.seekCompensationPermits')}
        </Text>
        <UiExternalLink htmlColor={Primary500} fontSize="large" />
      </ButtonContent>
    </StyledButton>
  )
}

export const ButtonGroupContainer = styled(CenteredColumnFlex)`
  width: 100%;
  gap: ${scale(1)};
`

interface DirectCompensationSelectProps {
  subjectOmUid: number
  onSelect(id: string): void
  onBack(): void
  onContinueAsSelfPaying(): void
}

const DirectCompensationSelect: React.FC<
  React.PropsWithChildren<DirectCompensationSelectProps>
> = ({ subjectOmUid, onSelect, onBack, onContinueAsSelfPaying }) => {
  const { t, i18n } = useTranslation()
  const {
    data: directCompensationOptions,
    pending,
    error,
  } = useApi(
    api.v1.insuranceDirectCompensationPermits,
    { subjectOmUid, insuranceCompanyId: 'lähitapiola', lang: i18n.language as SupportedLanguage },
    []
  )

  if (error) {
    throw 'Error loading direct compensation permits'
  }

  return (
    <ContentContainer>
      <Heading>{t('component.lahiTapiolaForm.directCompensationSelect.heading')}</Heading>
      <Paragraph>{t('component.lahiTapiolaForm.directCompensationSelect.text')}</Paragraph>
      <MinMaxDiv size="540px">
        <ButtonGroupContainer>
          {pending && <CircularProgress />}
          {directCompensationOptions.map((option) => (
            <DirectCompensationButton
              key={`diractCompensationButton-${option.id}`}
              onClick={() => onSelect(option.id)}
              title={option.i18n.title?.[i18n.language as SupportedLanguage] ?? ''}
              subtitle={option.i18n.subtitle?.[i18n.language as SupportedLanguage] ?? ''}
              subtitle2={option.i18n.subtitle2?.[i18n.language as SupportedLanguage] ?? ''}
              subtitle3={
                (option.i18n as { subtitle3?: { fi: string; en: string; sv: string } }).subtitle3?.[
                  i18n.language as SupportedLanguage
                ] ?? ''
              }
            />
          ))}
          <ContinueButton onClick={onContinueAsSelfPaying} />
          <SeekCompensationPermitsButton />
          {!pending && directCompensationOptions.length === 0 && (
            <Notification
              style={NotificationStyle.Info}
              title={t('component.lahiTapiolaForm.directCompensationSelect.note.heading')}
            >
              {t('component.lahiTapiolaForm.directCompensationSelect.note.text')}
            </Notification>
          )}
        </ButtonGroupContainer>
      </MinMaxDiv>
      <DefaultButton variant="text" onClick={onBack}>
        {t('common.goBack')}
      </DefaultButton>
    </ContentContainer>
  )
}

interface Props {
  onSubmit(data?: LahiTapiolaInformation): void
  onBack(): void
  onContinueAsSelfPaying(): void
}

const LahiTapiolaForm: React.FC<React.PropsWithChildren<Props>> = ({
  onSubmit,
  onBack,
  onContinueAsSelfPaying,
}) => {
  const { i18n } = useTranslation()
  const { selectedUser } = useReserveState()
  const isRedirectedFromInsuranceProvider = useRecoilValue(isRedirectedFromInsuranceProviderAtom)
  const [customershipVerified, setCustomershipVerified] = useState<boolean>(false)

  const {
    data: options,
    pending: optionsPending,
    error: optionsError,
  } = useApi(
    api.v1.insuranceOptions,
    {
      subjectOmUid: selectedUser.omUid,
      insuranceCompanyId: 'lähitapiola',
      lang: i18n.language as SupportedLanguage,
    },
    { checkCustomership: true }
  )

  if (optionsPending) {
    return null
  }

  if (optionsError) {
    throw 'Error fetching insurance options'
  }

  if (isRedirectedFromInsuranceProvider || customershipVerified || !options.checkCustomership) {
    return (
      <Container>
        <DirectCompensationSelect
          subjectOmUid={selectedUser.omUid}
          onSelect={(id) => onSubmit({ permitId: id })}
          onBack={onBack}
          onContinueAsSelfPaying={onContinueAsSelfPaying}
        />
      </Container>
    )
  }

  return (
    <Container>
      <CustomershipCheck
        insurancesApiInsuranceCompanyId="lähitapiola"
        translationKey="lahiTapiolaForm"
        Logo={LahiTapiolaSVG}
        subjectOmUid={selectedUser.omUid}
        onBack={onBack}
        onSuccess={() => setCustomershipVerified(true)}
        onContinueAsSelfPaying={onContinueAsSelfPaying}
      />
    </Container>
  )
}

export default LahiTapiolaForm
