import { DefaultValue, selector } from 'recoil'

import {
  selectedInsuranceAtom,
  selectedInsurancePayerAtom,
  selectedSearchInsuranceAtom,
  insuranceModalOpenAtom,
  preSelectedInsuranceIdAtom,
} from './atoms'

export enum InsurancePaymentType {
  SELF = 'self',
  INSURANCE_COMPANY = 'insurance_company',
}

type SelectedInsurance = {
  id: number | 'other'
  paymentType: InsurancePaymentType
}

/*
Values:
SelectedInsurance = insurance known
undefined = insurance unknown, but implied
null = no insurance
*/
export const selectedInsuranceSelector = selector<SelectedInsurance | undefined | null>({
  key: 'common-setSelectedInsurance',
  get: ({ get }) => {
    const selectedInsurance = get(selectedInsuranceAtom)
    const selectedSearchInsurance = get(selectedSearchInsuranceAtom)
    if (selectedInsurance) {
      return { id: selectedInsurance, paymentType: InsurancePaymentType.INSURANCE_COMPANY }
    }
    if (selectedSearchInsurance) {
      return { id: selectedSearchInsurance, paymentType: InsurancePaymentType.SELF }
    }
    return null
  },
  set: ({ set }, value) => {
    set(selectedInsurancePayerAtom, null)

    if (value === null || value instanceof DefaultValue) {
      set(selectedInsuranceAtom, null)
      set(selectedSearchInsuranceAtom, null)
      return
    }

    if (value === undefined) {
      set(insuranceModalOpenAtom, true)
      return
    }

    if (value.paymentType === InsurancePaymentType.SELF) {
      set(selectedInsuranceAtom, null)
      set(selectedSearchInsuranceAtom, value.id)
      return
    }

    if (value.paymentType === InsurancePaymentType.INSURANCE_COMPANY) {
      set(selectedInsuranceAtom, value.id)
      set(selectedSearchInsuranceAtom, value.id)
      return
    }

    set(preSelectedInsuranceIdAtom, value.id)
    set(insuranceModalOpenAtom, true)
  },
})
