import { css } from '@emotion/react'
import { Gray900, Primary500, Primary600 } from '@mehilainen/design-system-tokens/colors'
import { baseTypography } from '@mehilainen/design-system-tokens/typography'
import reactStyleReset from 'react-style-reset/string'

import ExternalIcon from './externalIcon.svg'

const BodyText = css`
  body {
    color: ${Gray900};
  }
`

const Links = css`
  a:not(.no-link-styles) {
    &:link {
      color: ${Primary500};
      text-decoration: underline;
    }

    &:visited {
      color: ${Primary500};
    }

    &:focus,
    &:hover {
      color: ${Primary600};
    }
  }

  .no-link-styles {
    text-decoration: none;
  }
`

// ${ExternalIcon} needs to be surrounded by double quotes to work with url() with Vite
// prettier-ignore
const ExternalLinks = css`
  --base-domain: ${process.env.REACT_APP_AV3_BASE_DOMAIN};

  a:not([href*='var(--base-domain)']):not([href^='#']):not([href^='/']):not([href^='tel:']):not(
      .no-external-icon
    ):after {
    display: inline-block;
    content: '';
    width: 16px;
    height: 16px;
    margin-left: 4px;
    background-color: currentColor;
    mask-image: url("${ExternalIcon}");
    vertical-align: text-top;
  }
`

/**
 * Using old reset from https://paja.mehilainen.local/sovelluskehitys/design-system/-/blob/master/src/reset/index.ts
 */
const GlobalStyle = css`
  ${reactStyleReset};
  body {
    color: ${Gray900};
    font-family: 'Gotham SSm A', 'Gotham SSm B', 'Verdana', 'Geneva', sans-serif;
    font-size: ${baseTypography.size[400].pixels}px;
    font-style: normal;
    font-weight: ${baseTypography.weight.Regular};
    line-height: 1.5;
  }

  button {
    font-family: 'Gotham SSm A', 'Gotham SSm B', 'Verdana', 'Geneva', sans-serif;
  }

  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  ${BodyText};
  ${Links}
  ${ExternalLinks};
`

export default GlobalStyle
