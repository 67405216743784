import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { InsuranceCompany, InsurancePayerOption, SupportedLanguage } from '../../__generated__/api'
import api from '../services/api'

import { useApi } from './useApi'

export const useInsurances = (): { insurances: InsuranceCompany[]; pending: boolean } => {
  const { i18n } = useTranslation()

  const { data, pending } = useApi(
    api.v1.insuranceCompanies,
    {
      lang: i18n.language as SupportedLanguage,
    },
    []
  )

  return { insurances: data, pending }
}

export const useInsuranceName = (
  id: number | 'other' | undefined
): { name: string; pending: boolean } => {
  const { t } = useTranslation()
  const { insurances, pending } = useInsurances()
  const [name, setName] = useState<string>('')

  useEffect(() => {
    setName(
      [...insurances, { id: 'other', name: t('common.otherInsurance') }].find(
        (insurance) => insurance.id === id
      )?.name ?? ''
    )
  }, [insurances, id, t])

  return { name, pending }
}

export const useInsurancePayerOptions = (id: number | null | undefined) => {
  const { insurances, pending } = useInsurances()
  const [insurancePayerOptions, setInsurancePayerOptions] = useState<InsurancePayerOption[]>([])
  useEffect(() => {
    if (insurances.length > 0) {
      const selectedInsurance = insurances.find((insurance) => insurance.id === id)
      setInsurancePayerOptions(selectedInsurance?.insurancePayerOptions ?? [])
    }
  }, [insurances, id])

  return { insurancePayerOptions, pending }
}

export const useInsurancePayerOption = (
  contractId: number | null | undefined,
  payerId: string | null
) => {
  const { insurancePayerOptions, pending } = useInsurancePayerOptions(contractId)
  const [insurancePayerOption, setInsurancePayerOption] = useState<InsurancePayerOption>()

  useEffect(() => {
    if (insurancePayerOptions.length > 0) {
      const found = insurancePayerOptions.find((option) => option.id === payerId)
      setInsurancePayerOption(found)
    }
  }, [insurancePayerOptions, payerId])

  return { insurancePayerOption, pending }
}
