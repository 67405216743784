import { useEffect, useState } from 'react'

import { AuthorizedPerson, UserWithDelegateRole } from '../../../__generated__/api'
import api from '../../../common/services/api'
import { LoginStatus } from '../../../state/user/atoms'

type UserState = {
  user: UserWithDelegateRole | undefined
  authorizations: AuthorizedPerson[] | undefined
  pending: boolean
}

const useUserState = (loginStatus: LoginStatus): UserState => {
  const [userState, setUserState] = useState<UserState>({
    user: undefined,
    authorizations: undefined,
    pending: true,
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: user } = await api.v1.getUser()
        if (user.isDelegateUser) {
          fetchAuthorizations()
        }
        setUserState((prevState) => ({
          ...prevState,
          pending: false,
          user: user,
        }))
      } catch (error) {
        setUserState({ user: undefined, pending: false, authorizations: undefined })
      }
    }

    const fetchAuthorizations = async () => {
      setUserState((prevState) => ({
        ...prevState,
        pending: true,
      }))
      const { data: users } = await api.v1.getAuthorizations()
      setUserState((prevState) => ({
        ...prevState,
        pending: false,
        authorizations: users,
      }))
    }

    if (loginStatus) {
      fetchData()
    }
  }, [loginStatus])

  return userState
}

export default useUserState
