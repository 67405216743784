import { Dayjs } from 'dayjs'
import { TFunction } from 'i18next'

import { PublicNode } from '../../__generated__/api'
import i18n from '../i18n/i18n'

// https://stackoverflow.com/a/6969486
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
export const escapeRegExp = (input: string) => {
  return input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export const formatPrice = (
  minValue: number,
  maxValue: number,
  locale: string,
  currency = 'EUR'
): string => {
  if (minValue < maxValue) {
    return new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).formatRange(
      minValue,
      maxValue
    )
  }
  return new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(maxValue)
}

export const formatDate = (value: Dayjs, t: TFunction, capitalize = false): string => {
  const dayPart = value.isToday()
    ? t('common.time.today')
    : value.isTomorrow()
    ? t('common.time.tomorrow')
    : value.format('dddd')

  const datePart = value.format('DD.MM.YYYY')

  return capitalize ? capitalizeFirstLetter(`${dayPart} ${datePart}`) : `${dayPart} ${datePart}`
}

export const formatDate2 = (value: Dayjs, t: TFunction): string => {
  const dayPart = value.isToday()
    ? t('common.time.today')
    : value.isTomorrow()
    ? t('common.time.tomorrow')
    : null

  return dayPart?.toLowerCase() || value.format('DD.MM.YYYY')
}

export const formatDate3 = (value: Dayjs, t: TFunction): string => {
  const dayPart = value.isToday()
    ? t('common.time.today')
    : value.isTomorrow()
    ? t('common.time.tomorrow')
    : null

  return dayPart || value.format('dd D.M.')
}

export const formatDate4 = (value: Dayjs): string => {
  return capitalizeFirstLetter(value.format('dddd DD.MM.YYYY'))
}

export const formatDateAndTime = (value: Dayjs, t: TFunction): string => {
  const dayPart = value.isToday()
    ? t('common.time.today')
    : value.isTomorrow()
    ? t('common.time.tomorrow')
    : value.format('dddd DD.MM.')

  return `${capitalizeFirstLetter(dayPart)} ${value.format('HH:mm')}`
}

export const formatDateAndTime2 = (value: Dayjs): string => {
  return `${capitalizeFirstLetter(value.format('dddd DD.MM.YYYY'))} ${value.format('HH:mm')}`
}

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const localizeRating = (rating: number | undefined): string | undefined => {
  return rating
    ? new Intl.NumberFormat(i18n.language, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }).format(rating)
    : undefined
}

export const getAppointmentTitle = (
  node?: PublicNode | null,
  serviceName?: string | null,
  specialtyName?: string | null
): string | undefined => {
  if (specialtyName) {
    return specialtyName
  }

  if (serviceName) {
    return serviceName
  }

  if (!node) {
    return undefined
  }

  if (node.title) {
    return node.title
  }

  if (node.parentName) {
    return `${node.parentName} · ${node.name}`
  }

  return node.name
}

export const formatCallbackTimeRaw = (time: Dayjs): { start: Dayjs; end: Dayjs } | null => {
  time = time.tz('Europe/Helsinki')

  const startTime = time.hour(19).minute(59).second(59)
  const endTime = time.hour(23).minute(59).second(59)

  // If between 20:00 - 23:59 the time is omitted
  if (time.isAfter(startTime) && time.isBefore(endTime)) {
    return null
  } else {
    // Round to the nearest hour
    const roundedTime =
      time.minute() >= 30 ? time.add(1, 'hour').startOf('hour') : time.startOf('hour')

    // Calculate the time range of +/- 1 hour
    const roundedStartTime = roundedTime.subtract(1, 'hour')
    const roundedEndTime = roundedTime.add(1, 'hour')

    return { start: roundedStartTime, end: roundedEndTime }
  }
}

export const formatCallbackTime = (time: Dayjs, includeDate = false): string => {
  const formattedTime = formatCallbackTimeRaw(time)
  if (!formattedTime) {
    return ''
  } else {
    const roundedStartTime = formattedTime.start.format('HH:mm')
    const roundedEndTime = formattedTime.end.format('HH:mm')

    return capitalizeFirstLetter(
      `${includeDate ? `${formatDate(time, i18n.t)} ` : ''}${roundedStartTime} - ${roundedEndTime}`
    )
  }
}
