/* eslint-disable no-console */
import styled from '@emotion/styled'
import { White } from '@mehilainen/design-system-tokens/colors'
import { Alert, Snackbar } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom'
import { UnwrapRecoilValue, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import { NodeType, SupportedLanguage } from './__generated__/api'
import CallbackModal from './common/components/CallbackModal/CallbackModal'
import ExternalBanner from './common/components/ExternalBanner/ExternalBanner'
import ExternalRedirect from './common/components/ExternalRedirect/ExternalRedirect'
import Footer from './common/components/Footer/Footer'
import ImpersonationBanner from './common/components/ImpersonationBanner/ImpersonationBanner'
import LandingModal from './common/components/LandingModal/LandingModal'
import NavBar from './common/components/NavBar/NavBar'
import NodeRedirectModal from './common/components/NodeRedirectModal/NodeRedirectModal'
import OHCModal from './common/components/OHCModal/OHCModal'
import PartnerRedirect from './common/components/PartnerRedirect/PartnerRedirect'
import PractitionerInfoModal from './common/components/PractitionerInfoModal/PractitionerInfoModal'
import ScheduledMaintenance from './common/components/ScheduledMaintenance/ScheduledMaintenance'
import SetContractImpersonation from './common/components/SetContractImpersonation/SetContractImpersonation'
import TabTitle from './common/components/TabTitle/TabTitle'
import { useBackendTranslations } from './common/hooks/useBackendTranslations'
import useChangeLanguage from './common/hooks/useChangeLanguage'
import { useCookie } from './common/hooks/useCookie'
import { useLocationSideEffects } from './common/hooks/useLocationSideEffects'
import useLoginState from './common/hooks/useLoginState'
import useMobileObserver from './common/hooks/useMobileObserver'
import { useNodeSideEffects } from './common/hooks/useNodeSideEffects'
import Sentry from './common/services/sentry'
import { maxWidth } from './common/utils/breakpoint'
import { filterExternalUrlParameters } from './common/utils/url'
import AppointmentOptionsSelectContainer from './domain/appointmentOptionsSelect/container/AppointmentOptionsSelectContainer'
import AppointmentOptionSelectView from './domain/appointmentOptionsSelect/container/AppointmentOptionsSelectView'
import AppointmentCancellationLegacyView from './domain/cancellation/container/AppointmentCancellationLegacyView'
import AppointmentCancellationView from './domain/cancellation/container/AppointmentCancellationView'
import BookingConfirmationView from './domain/confirmation/container/BookingConfirmationView'
import InsuranceRedirect from './domain/reserve/components/InsuranceRedirect'
import ReserveView from './domain/reserve/container/ReserveView'
import SearchView from './domain/search/container/SearchView'
import useSearchTarget, { SearchTargetValue } from './domain/search/hooks/useSearchTarget'
import {
  modalTriggererAtom as modalTriggerer,
  selectedAppointmentIdAtom as aid,
  selectedPractitionerIdAtom as pid,
} from './state/appointmentOptionsSelect/atoms'
import {
  generalErrorMessageAtom,
  insuranceModalOpenAtom,
  isFromAppAtom,
  isFromOMAtom,
  isOHCAtom,
  selectedAppointmentIdAtom,
  selectedAppointmentLengthAtom,
  selectedAppointmentNodeConnectionIdAtom,
  selectedAppointmentNodeIdAtom,
} from './state/common/atoms'
import {
  practitionerModalPractitionerId,
  selectedDateAtom as selectedPractitionerDateAtom,
  selectedPractitionerIdAtom,
} from './state/practitioner/atoms'
import { selectedAppointmentDetailsAtom } from './state/reserve/atoms'
import {
  appointmentSearchModeAtom,
  defaultDentalSearchNodeId,
  searchServiceModalAutoOpenAtom,
  selectedDateAtom,
  callbackModalOpenAtom,
  searchServiceModalOpenAtom,
  isUserSelectedNodeAtom,
} from './state/search/atoms'
import { getSelectedNodeOrDefault } from './state/search/selectors'

const Main = styled.main`
  background: ${White};

  @media (min-width: ${maxWidth.body + 1}px) {
    width: ${maxWidth.body}px;
    margin: auto;
  }
`

export const reserveRoutePath = 'reserve'

interface Props {
  basePath: string
}

const Routes: React.FC<React.PropsWithChildren<Props>> = ({ basePath }) => {
  const { t } = useTranslation()
  const { changeLanguage, changeNamespace } = useChangeLanguage()
  useBackendTranslations()
  const history = useHistory()

  const setSelectedPractitionerId = useSetRecoilState(selectedPractitionerIdAtom)
  const setPractitionerDate = useSetRecoilState(selectedPractitionerDateAtom)
  const setPractitionerAppointmentDuration = useSetRecoilState(selectedAppointmentLengthAtom)
  const setPractitionerModalPractitionerId = useSetRecoilState(practitionerModalPractitionerId)
  const setSelectedAppointmentId = useSetRecoilState(selectedAppointmentIdAtom)
  const setSelectedAppointmentNodeId = useSetRecoilState(selectedAppointmentNodeIdAtom)
  const setSelectedNodeConnectionId = useSetRecoilState(selectedAppointmentNodeConnectionIdAtom)
  const setInsuranceModalOpen = useSetRecoilState(insuranceModalOpenAtom)
  const selectedDate = useRecoilValue(selectedDateAtom)
  const selectedNodeId = useRecoilValue(getSelectedNodeOrDefault)
  const setTriggerer = useSetRecoilState(modalTriggerer)
  const setAid = useSetRecoilState(aid)
  const setPid = useSetRecoilState(pid)
  const setSelectedAppointmentDetails = useSetRecoilState(selectedAppointmentDetailsAtom)
  const { setSearchTarget } = useSearchTarget()
  const isOHC = useRecoilValue(isOHCAtom)
  const appointmentSearchMode = useRecoilValue(appointmentSearchModeAtom)
  const [isOHCRedirected, setIsOHCRedirected] = useState<boolean>(false)
  const setServiceModalAutoOpen = useSetRecoilState(searchServiceModalAutoOpenAtom)

  const isFromApp = useRecoilValue(isFromAppAtom)

  const openAppointmentModal = useCallback(
    (
      triggerer: UnwrapRecoilValue<typeof modalTriggerer>,
      appointmentId: UnwrapRecoilValue<typeof aid>,
      practitionerId: UnwrapRecoilValue<typeof pid>
    ) => {
      setTriggerer(triggerer)
      setAid(appointmentId)
      setPid(practitionerId)
    },
    [setTriggerer, setAid, setPid]
  )

  const [generalErrorMessage, setGeneralErrorMessage] = useRecoilState(generalErrorMessageAtom)

  const sessionExpiredCallback = useCallback(
    () => setGeneralErrorMessage('common.sessionExpired'),
    [setGeneralErrorMessage]
  )

  const { loginStatus, logout } = useLoginState(sessionExpiredCallback)

  useEffect(
    () =>
      history.listen((_, action) => {
        if (action !== 'REPLACE') {
          window.scrollTo(0, 0)
        }
      }),
    [history]
  )

  const location = useLocation()
  const prevLocation: { current: typeof location } = useRef(location)

  useEffect(() => {
    Sentry.addBreadcrumb({
      category: 'navigation',
      data: {
        from: {
          pathname: prevLocation.current.pathname,
          search: Object.fromEntries(new URLSearchParams(prevLocation.current.search)),
        },
        to: {
          pathname: location.pathname,
          search: Object.fromEntries(new URLSearchParams(location.search)),
        },
      },
    })

    prevLocation.current = location
  }, [location])

  const setIsFromApp = useSetRecoilState(isFromAppAtom)
  const setIsFromOM = useSetRecoilState(isFromOMAtom)
  const [isFromAppCookie, updateIsFromAppCookie] = useCookie('MOBILE', '0')
  const setCallbackModalOpen = useSetRecoilState(callbackModalOpenAtom)
  const setServiceModalOpen = useSetRecoilState(searchServiceModalOpenAtom)
  const setIsUserSelectedNode = useSetRecoilState(isUserSelectedNodeAtom)

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (searchParams.has('isFromApp') || isFromAppCookie === '1') {
      setIsFromApp(true)
      updateIsFromAppCookie('1')
    }
    if (searchParams.has('isFromOM')) {
      setIsFromOM(true)
    }
    if (searchParams.has('translationNamespace')) {
      changeNamespace(searchParams.get('translationNamespace') ?? '')
    }
    if (searchParams.has('isRedirected')) {
      setIsOHCRedirected(true)
    }
    if (searchParams.has('noServiceSelect')) {
      setServiceModalAutoOpen(false)
    }
    if (searchParams.has('callbackOpen')) {
      setCallbackModalOpen(true)
    }
    if (searchParams.has('serviceSelect')) {
      setServiceModalOpen(true)
    }
    if (searchParams.has('language')) {
      changeLanguage(searchParams.get('language') as SupportedLanguage)
    }
  }, [
    location,
    setIsFromApp,
    setIsFromOM,
    isFromAppCookie,
    updateIsFromAppCookie,
    changeNamespace,
    setServiceModalAutoOpen,
    setCallbackModalOpen,
    setServiceModalOpen,
    changeLanguage,
  ])

  useNodeSideEffects()
  useLocationSideEffects()

  // Observe topmost element and update mobile header accordingly
  useMobileObserver(isFromApp)

  return (
    <>
      <ScheduledMaintenance />

      <ExternalBanner loginStatus={loginStatus} />
      <ImpersonationBanner loginStatus={loginStatus} />

      <Snackbar
        autoHideDuration={10000}
        open={Boolean(generalErrorMessage)}
        onClose={() => setGeneralErrorMessage(undefined)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="error">{t(generalErrorMessage ?? '')}</Alert>
      </Snackbar>

      <AppointmentOptionsSelectContainer
        onSelect={(appointmentId, choices) => {
          setSelectedAppointmentId(appointmentId)
          const searchParams = filterExternalUrlParameters(location.search).toString() // Preserve external parameters
          if (choices.duration === 'default' || typeof choices.duration === 'number') {
            setPractitionerAppointmentDuration(choices.duration)
            history.push(
              `${basePath}/appointment/${appointmentId}?length=${choices.duration}&type=${choices.preselectedType}&service=${choices.serviceId}&${searchParams}`
            )
            return
          }
          history.push(
            `${basePath}/appointment/${appointmentId}?type=${choices.preselectedType}&service=${choices.serviceId}&${searchParams}`
          )
        }}
        onSelectUnavailableDuration={(practitionerId, duration) => {
          setSelectedPractitionerId(practitionerId)
          setPractitionerDate(selectedDate)
          setPractitionerAppointmentDuration(duration)
          setSearchTarget({ id: practitionerId, value: SearchTargetValue.Practitioner })
        }}
      />

      <PractitionerInfoModal
        onDisplayAppointmentsClick={(practitionerId) => {
          setSearchTarget(
            { id: practitionerId, value: SearchTargetValue.Practitioner },
            selectedNodeId
          ).then(() => history.push(basePath))
          setPractitionerModalPractitionerId(undefined)
        }}
      />

      <Main>
        <Route exact path={basePath === '' ? '/' : basePath}>
          {isOHC && (
            <OHCModal
              hasLoggedIn={loginStatus === 'authenticated' || loginStatus === 'impersonated'}
              hasRedirected={isOHCRedirected}
            />
          )}

          <LandingModal onLanguageSelect={changeLanguage} />
          <CallbackModal />
          <NodeRedirectModal />
          <TabTitle value={t('component.tabTitle.search.title')} />
          <React.Suspense fallback={<></>}>
            {!isFromApp && (
              <NavBar
                onLanguageSelect={changeLanguage}
                loginStatus={loginStatus}
                logoutCallBack={logout}
                variant="nested"
              />
            )}
            <SearchView
              onAppointmentSelect={(selection) => {
                const { appointment } = selection
                setSelectedAppointmentDetails(appointment)
                const appointmentId = appointment.assisDentSearchResult
                  ? appointment.assisDentSearchResult.id
                  : appointment.appointmentId
                const searchParams = filterExternalUrlParameters(location.search) // Preserve external parameters
                if ('node' in selection) {
                  setSelectedAppointmentNodeId(selection.node.id)
                  setSelectedNodeConnectionId(appointment.connectionId)

                  if (
                    selection.node.type === NodeType.ServicePackage ||
                    appointment.assisDentSearchResult
                  ) {
                    setSelectedAppointmentId(appointmentId)

                    history.push(
                      `${basePath}/appointment/${appointmentId}?length=${
                        appointment.duration
                      }&node=${selection.node.id}&${searchParams.toString()}`
                    )
                    return
                  }

                  if (typeof appointmentId === 'number') {
                    openAppointmentModal(
                      'appointmentSelect',
                      appointmentId,
                      appointment.specialistId
                    )
                  }

                  return
                } else if ('practitionerId' in selection) {
                  setSelectedAppointmentNodeId(selection.nodeId)
                  setSelectedNodeConnectionId(undefined)
                  setSelectedAppointmentId(appointmentId)
                  setSelectedPractitionerId(selection.practitionerId)

                  if (selection.duration) {
                    searchParams.append('length', String(selection.duration))
                  }

                  if (selection.serviceId) {
                    searchParams.append('service', String(selection.serviceId))
                  }
                  searchParams.append('specialty', String(selection.specialtyId))
                  searchParams.append('mode', appointmentSearchMode)

                  history.push(`${basePath}/appointment/${appointmentId}?${searchParams}`)
                }
              }}
              onPractitionerDetailsClick={setPractitionerModalPractitionerId}
            />
          </React.Suspense>
        </Route>

        <Route
          exact
          path={`${basePath}/insurance`}
          render={({ location: routeLocation }) => {
            setInsuranceModalOpen(true)
            return (
              <Redirect
                to={`${basePath}/${
                  routeLocation.search ? `${routeLocation.search}&noLanding` : '?noLanding'
                }`}
              />
            )
          }}
        />

        <Route
          exact
          path={`${basePath}/practitioner/:practitionerId?`}
          render={(props) => <Redirect to={`${basePath}?p=${props.match.params.practitionerId}`} />}
        />

        <Route exact path={`${basePath}/appointment/:appointmentId?`}>
          {!isFromApp && (
            <NavBar
              onLanguageSelect={changeLanguage}
              loginStatus={loginStatus}
              logoutCallBack={logout}
              variant="detached"
            />
          )}
          <TabTitle value={t('component.tabTitle.confirmSelect.title')} />
          <AppointmentOptionSelectView
            onSelect={(appointmentId) => {
              history.push(`${basePath}/reserve/${appointmentId}`)
            }}
            onBack={
              (/* appointmentId, practitionerId */) => {
                // FIXME: We only want to trigger the modal if coming from the search view.
                // openAppointmentModal('back', appointmentId, practitionerId)
                history.goBack()
              }
            }
            onNavigate={(searchTarget) => {
              setSearchTarget(searchTarget).then(() => history.push(basePath))
            }}
          />
        </Route>

        <Route exact path={`${basePath}/${reserveRoutePath}/:appointmentId?`}>
          {!isFromApp && (
            <NavBar
              onLanguageSelect={changeLanguage}
              loginStatus={loginStatus}
              logoutCallBack={logout}
              variant="detached"
            />
          )}
          <TabTitle value={t('component.tabTitle.confirmReservation.title')} />
          <ReserveView
            onBack={history.length <= 2 ? () => history.push(basePath) : () => history.goBack()}
            onReserved={(reserveResult) =>
              history.push(
                `${basePath}/confirmation-info/${reserveResult.reservationId}/${reserveResult.appointmentId}`
              )
            }
            onBackToStart={() => history.push(basePath)}
          />
        </Route>

        <Route exact path={`${basePath}/confirmation-info/:reservationId/:appointmentId`}>
          {!isFromApp && (
            <NavBar
              onLanguageSelect={changeLanguage}
              loginStatus={loginStatus}
              logoutCallBack={logout}
              variant="detached"
            />
          )}
          <TabTitle value={t('component.tabTitle.confirmThankYou.title')} />
          <BookingConfirmationView onBackToStart={() => history.push(basePath)} />
        </Route>

        <Route exact path={`${basePath}/cancel-appointment/:reservationId/:appointmentId`}>
          <NavBar
            onLanguageSelect={changeLanguage}
            loginStatus={loginStatus}
            logoutCallBack={logout}
            variant="detached"
          />
          <TabTitle value={t('component.tabTitle.cancelReservation.title')} />
          <AppointmentCancellationView />
        </Route>

        <Route exact path={`${basePath}/cancel`}>
          <NavBar
            onLanguageSelect={changeLanguage}
            loginStatus={loginStatus}
            logoutCallBack={logout}
            variant="detached"
          />
          <TabTitle value={t('component.tabTitle.cancelReservation.title')} />
          <AppointmentCancellationLegacyView />
        </Route>

        <Route
          exact
          path={`${basePath}/external`}
          render={() => (
            <ExternalRedirect
              basePath={basePath}
              redirectPath={`${process.env.REACT_APP_MEHIDOC_BASE_URL}/asiakaspalvelu/external`}
              loginStatus={loginStatus}
              onLogout={logout}
            />
          )}
        />

        <Route
          exact
          path={`${basePath}/partner`}
          render={() => <PartnerRedirect redirectPath={basePath} />}
        />
        <Route
          exact
          path={`${basePath}/set-contract`}
          render={() => <SetContractImpersonation />}
        />

        <InsuranceRedirect basePath={basePath} />
      </Main>

      <Route
        exact
        path={`${basePath}/hammashoito`}
        render={(props) => {
          setSearchTarget({
            id: defaultDentalSearchNodeId,
            value: SearchTargetValue.Node,
          })
          setIsUserSelectedNode(true)

          const search = new URLSearchParams(props.location.search)
          search.set('noLanding', 'dental')
          search.set('serviceSelect', '')

          return <Redirect to={`${basePath}?${search}`} />
        }}
      />

      <Route
        exact
        path="/help"
        render={() => {
          window.location.assign(t('redirect.help'))
          return null
        }}
      />

      <Route
        exact
        path="/callrequest"
        render={() => {
          return <Redirect to={`${basePath}?callbackOpen&noLanding`} />
        }}
      />

      <Route
        exact
        path="/callback"
        render={() => {
          setCallbackModalOpen(true)
          return <Redirect to={`${basePath}`} />
        }}
      />

      {!isFromApp && <Footer />}
    </>
  )
}

export default Routes
