import styled from '@emotion/styled'
import React from 'react'

import { scale } from '../../utils/scale'
import { RowFlex } from '../Layout/Layout'

import InfoChip from './InfoChip'

const Container = styled(RowFlex)`
  gap: ${scale(1)};
  flex-wrap: wrap;
`

interface Props {
  chipTexts: string[]
}

const InfoChips: React.FC<React.PropsWithChildren<Props>> = ({ chipTexts }) => {
  return (
    <Container role="list">
      {chipTexts.map((text, idx) => (
        <InfoChip key={`${text}-${idx}`} text={text} />
      ))}
    </Container>
  )
}

export default InfoChips
