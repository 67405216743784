import styled from '@emotion/styled'
import { Black } from '@mehilainen/design-system-tokens/colors'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { jwtKey } from '../../../constants'
import { LoginStatus } from '../../../state/user/atoms'
import api from '../../services/api'
import {
  ohcCompanyImpersonationKey,
  ohcCompanyImpersonationNthEmployeeKey,
} from '../SetContractImpersonation/SetContractImpersonation'
import { Text } from '../Typography/Typography'

const Container = styled.div`
  width: 100%;
  height: 50px;
  background: ${Black};
  color: white;
  text-align: center;
`

interface Props {
  loginStatus?: LoginStatus
}

const ImpersonationBanner: React.FC<React.PropsWithChildren<Props>> = ({ loginStatus }) => {
  const { t } = useTranslation()
  const [userName, setUserName] = useState<string | null>(null)

  const handleSetUserName = useCallback(async () => {
    const response = await api.v1.getUser({})
    const user = response.data
    setUserName(`${user.firstName} ${user.lastName}`)
  }, [setUserName])

  useEffect(() => {
    const token = sessionStorage.getItem(jwtKey)
    if (!userName && loginStatus === 'impersonated' && token) {
      handleSetUserName()
    } else if (!loginStatus && userName) {
      setUserName(null)
    }
  }, [handleSetUserName, loginStatus, userName])

  if (loginStatus !== 'impersonated') {
    return null
  }

  return (
    <Container>
      <Text $size={300} $height="Large">
        {t('component.impersonationBanner.heading', { userName })}
      </Text>
      {sessionStorage.getItem(ohcCompanyImpersonationKey) && (
        <Text $size={300} $height="Large">
          {' - Yritys: '}
          {sessionStorage.getItem(ohcCompanyImpersonationKey)}
          {sessionStorage.getItem(ohcCompanyImpersonationNthEmployeeKey) &&
            ` (${sessionStorage.getItem(ohcCompanyImpersonationNthEmployeeKey)})`}
        </Text>
      )}
    </Container>
  )
}

export default ImpersonationBanner
