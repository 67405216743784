import styled from '@emotion/styled'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { PractitionerDetails } from '../../../__generated__/api'
import { scale } from '../../utils/scale'
import AppointmentLengthSelect from '../AppointmentLengthSelect/AppointmentLengthSelect'
import { ColumnFlex } from '../Layout/Layout'
import PractitionerHeader from '../PractitionerDetails/PractitionerHeader'
import { Text } from '../Typography/Typography'

const Container = styled(ColumnFlex)`
  gap: ${scale(1.25)};

  ${AppointmentLengthSelect} {
    margin: ${scale(1.5)} 0;
  }
`

const StyledUl = styled.ul`
  list-style: disc inside;
`

interface Props {
  practitionerDetails: PractitionerDetails
  lengthSelectComponent: React.ReactNode
  heading?: string
}

const PractitionerAppointmentInstructions: React.FC<React.PropsWithChildren<Props>> = ({
  practitionerDetails,
  lengthSelectComponent,
  heading,
}) => {
  const { t } = useTranslation()

  return (
    <Container>
      <PractitionerHeader
        practitioner={practitionerDetails}
        showDisplayAppointmentsButton={false}
        disableRating
      />
      <Text
        as="h2"
        $size={400}
        $weight="Regular"
        $height="Medium"
        data-cy="practitionerAppointmentInstructions-heading"
      >
        {heading ?? t('component.practitionerAppointmentInstructions.heading')}
      </Text>
      {lengthSelectComponent}
      {practitionerDetails.patientRestrictions.length > 0 && (
        <>
          <Text as="h3" $size={400} $weight="Regular" $height="Medium">
            {t('component.appointmentOptionsSelect.patientRestrictionsHeading')}
          </Text>
          <StyledUl>
            {practitionerDetails.patientRestrictions.map((restriction, index) => (
              <li key={`${restriction.id}-${index}`}>
                <Text $size={300} $height="Large" $weight="Regular">
                  {restriction.patientRestriction}
                </Text>
              </li>
            ))}
          </StyledUl>
        </>
      )}
    </Container>
  )
}

export default PractitionerAppointmentInstructions
