import React, { useCallback } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'

import {
  modalTriggererAtom,
  selectedAppointmentIdAtom,
  selectedPractitionerIdAtom,
} from '../../../state/appointmentOptionsSelect/atoms'
import { AppointmentOptions } from '../components/AppointmentOptionsSelectModal'
import AppointmentOptionsSelectModalWrapper from '../components/AppointmentOptionsSelectModalWrapper'

interface Props {
  onSelectUnavailableDuration(practitionerId: number, duration: number): void
  onSelect(appointmentId: number, options: AppointmentOptions): void
}

const AppointmentOptionsSelectContainer: React.FC<React.PropsWithChildren<Props>> = ({
  onSelect,
  onSelectUnavailableDuration,
}) => {
  const setModalTriggerer = useSetRecoilState(modalTriggererAtom)
  const [practitionerId, setPractitionerId] = useRecoilState(selectedPractitionerIdAtom)
  const [appointmentId, setAppointmentId] = useRecoilState(selectedAppointmentIdAtom)

  const closeModal = useCallback(() => {
    setModalTriggerer(undefined)
    setPractitionerId(undefined)
    setAppointmentId(undefined)
  }, [setModalTriggerer, setAppointmentId, setPractitionerId])

  if (!practitionerId || !appointmentId) {
    return null
  }

  return (
    <AppointmentOptionsSelectModalWrapper
      appointmentId={appointmentId}
      practitionerId={practitionerId}
      onClose={() => {
        closeModal()
      }}
      onSelect={(appointmentOptions) => {
        closeModal()
        onSelect(appointmentId, appointmentOptions)
      }}
      onSelectUnavailableDuration={(duration) => {
        closeModal()
        onSelectUnavailableDuration(practitionerId, duration)
      }}
    />
  )
}

export default AppointmentOptionsSelectContainer
