import { i18n as i18nI, TFunction } from 'i18next'
import { useEffect } from 'react'
import { useTranslation as useTranslationOriginal } from 'react-i18next'

export const useTranslation = (lang?: string): { t: TFunction; i18n: i18nI } => {
  const { t, i18n } = useTranslationOriginal()
  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang)
    }
  }, [i18n, lang])
  return {
    t,
    i18n,
  }
}

export default useTranslation
