import styled from '@emotion/styled'
import { Gray100, Primary500 } from '@mehilainen/design-system-tokens/colors'
import { Alert, Button, Snackbar } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { SupportedLanguage } from '../../../__generated__/api'
import { DentalMaintenance } from '../../../common/components/DentalMaintenance/DentalMaintenance'
import { ColumnFlex } from '../../../common/components/Layout/Layout'
import Pattern from '../../../common/components/Pattern/Pattern'
import { useApi } from '../../../common/hooks/useApi'
import useLang from '../../../common/hooks/useLang'
import { useTranslation } from '../../../common/hooks/useTranslation'
import api from '../../../common/services/api'
import { isDentalMaintenanceError } from '../../../common/utils/error/utils'
import { scale } from '../../../common/utils/scale'
import AppointmentCancellationForm from '../components/AppointmentCancellationForm'
import { AppointmentCancelled, AppointmentMissing } from '../components/AppointmentCancelled'

const Container = styled(ColumnFlex)<{ omitPattern?: boolean }>`
  ${({ omitPattern }) => !omitPattern && Pattern}

  min-height: 100%;
  padding-bottom: ${scale(12)};
  background-color: ${({ omitPattern }) => (omitPattern ? Gray100 : Primary500)};
`

const BackButton = styled(Button)`
  align-self: center;
`

const AppointmentCancellationView: React.FC<React.PropsWithChildren<unknown>> = () => {
  const history = useHistory()
  const { t, i18n } = useTranslation()
  useLang()
  const { reservationId, appointmentId } = useParams<{
    reservationId: string
    appointmentId: string
  }>()
  const [cancellationError, setCancellationError] = useState<string | null>(null)
  const [cancelled, setCancelled] = useState<boolean>(false)

  const { data: cancellationInfo, error: cancellationInfoError } = useApi(
    api.v1.cancellationInfo,
    {
      reservationId,
      appointmentId: parseInt(appointmentId, 10),
      lang: i18n.language as SupportedLanguage,
    },
    undefined
  )

  const getErrorMessage = useCallback(
    (statusCode: number): string => {
      switch (statusCode) {
        case 404:
          return t('component.appointmentCancellationView.error.notFound')
        case 410:
          return t('component.appointmentCancellationView.error.expired')
        default:
          return t('common.error')
      }
    },
    [t]
  )

  const onSubmitCallback = useCallback(async (): Promise<void> => {
    await api.v1
      .cancelAppointment({
        reservationId,
        lang: i18n.language as SupportedLanguage,
      })
      .then(() => {
        setCancelled(true)
        window.scrollTo({ top: 0 })
      })
      .catch((err) => {
        setCancellationError(getErrorMessage(err.statusCode))
      })
  }, [getErrorMessage, reservationId, i18n.language])

  const showDentalMaintenance = isDentalMaintenanceError(cancellationInfoError)

  return (
    <Container omitPattern={showDentalMaintenance}>
      {showDentalMaintenance ? (
        <>
          <DentalMaintenance variant="full" displayCancellation />
          <BackButton onClick={() => history.push('/')}>{t('maintenance.back')}</BackButton>
        </>
      ) : (
        <>
          {cancellationInfoError?.statusCode === 404 && <AppointmentMissing />}
          {cancellationInfoError && cancellationInfoError.statusCode !== 404 && (
            <Alert severity="error">{getErrorMessage(cancellationInfoError.statusCode)}</Alert>
          )}
          {cancellationInfo && !cancelled && (
            <AppointmentCancellationForm
              cancellationInfo={cancellationInfo}
              onSubmit={onSubmitCallback}
            />
          )}
          {cancelled && <AppointmentCancelled />}
          <Snackbar
            open={Boolean(cancellationError)}
            onClose={() => setCancellationError(null)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert severity="error">{cancellationError}</Alert>
          </Snackbar>
        </>
      )}
    </Container>
  )
}

export default AppointmentCancellationView
