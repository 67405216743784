import { LocationAutocompleteResult, NodeAutocompleteResult } from '../../__generated__/api'
import AVSearch from '../services/search/AVSearch'
import { LocationSearch } from '../services/search/LocationSearch'
import { NodeSearch } from '../services/search/NodeSearch'

export const useNodeSearch = (isOhc: boolean): AVSearch<NodeAutocompleteResult> => {
  const nodeSearch = new NodeSearch()
  nodeSearch.setIsOhc(isOhc)
  return nodeSearch
}

export const useLocationSearch = (
  isOhc: boolean,
  nodeId?: string
): AVSearch<LocationAutocompleteResult> => {
  const locationSearch = new LocationSearch()
  locationSearch.setIsOhc(isOhc)
  if (nodeId) {
    locationSearch.setNodeId(nodeId)
  }
  return locationSearch
}
