import { PluginFunc } from 'dayjs'

import i18n from '../../i18n/i18n'

const localeAwareFormat: PluginFunc = (option, dayjsClass) => {
  const oldFormat = dayjsClass.prototype.format
  const locale = dayjsClass.prototype.locale
  const tz = dayjsClass.prototype.tz
  dayjsClass.prototype.format = function (args) {
    const localized = locale.bind(this)(i18n.language)
    const forceTz = tz.bind(localized)('Europe/Helsinki')
    const result = oldFormat.bind(forceTz)(args)
    return result
  }
}

export default localeAwareFormat
