import * as SentryTracing from '@sentry/tracing'

import {
  Api,
  ApiConfig,
  FullRequestParams,
  HttpClient,
  HttpResponse,
} from '../../__generated__/api'
import { jwtKey } from '../../constants'
import {
  ohcCompanyImpersonationKey,
  ohcCompanyImpersonationNthEmployeeKey,
} from '../components/SetContractImpersonation/SetContractImpersonation'
import { default as i18nInstance } from '../i18n/i18n'
import APIError from '../utils/error/APIError'

import Sentry, { sessionId } from './sentry'

interface AvApiConfig<SecurityDataType = unknown> extends ApiConfig<SecurityDataType> {
  i18nInstance: typeof i18nInstance
  sessionId: string
  jwt: string | null
  companyId: string | null
  nthEmployee: string | null
}

class AvHttpClient<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  private readonly i18nInstance: typeof i18nInstance
  private readonly sessionId: string
  private jwt: string | null
  private companyId: string | null
  private nthEmployee: string | null
  private readonly originalRequest: <T = unknown, E = unknown>(
    params: FullRequestParams
  ) => Promise<HttpResponse<T, E>>

  constructor(apiConfig: AvApiConfig<SecurityDataType>) {
    super(apiConfig as ApiConfig<SecurityDataType>)
    this.originalRequest = this.request
    this.i18nInstance = apiConfig.i18nInstance
    this.sessionId = apiConfig.sessionId
    this.jwt = apiConfig.jwt
    this.companyId = apiConfig.companyId
    this.nthEmployee = apiConfig.nthEmployee

    this.request = async <T = unknown, E = unknown>(
      params: FullRequestParams
    ): Promise<HttpResponse<T, E>> => {
      const credentials = params.credentials ?? 'include'
      const baseUrl = params.baseUrl ?? this.baseUrl ?? process.env.REACT_APP_API
      const url = `${baseUrl}${params.path}`
      if (this.jwt) {
        params.headers = { ...params.headers, Authorization: this.jwt }
      }
      if (this.companyId) {
        params.headers = { ...params.headers, 'x-av-company-id': this.companyId }
      }
      if (this.nthEmployee) {
        params.headers = { ...params.headers, 'x-av-nth-employee': this.nthEmployee }
      }

      const transaction = Sentry.startTransaction(
        {
          name: `API ${params.method} ${params.path}`,
          op: 'http.client',
          data: { type: 'fetch', method: params.method, url },
          description: `${params.method} ${url}`,
        },
        {
          method: params.method,
          path: params.path,
        }
      )

      return this.originalRequest<T, E>({
        ...params,
        credentials,
        baseUrl,
        headers: {
          ...params.headers,
          'Accept-Language': this.i18nInstance.language,
          'X-AV-Session-ID': this.sessionId,
          ...(transaction ? { 'sentry-trace': transaction.toTraceparent() } : {}),
        },
      })
        .then((response: HttpResponse<T, E>) => {
          Sentry.addBreadcrumb({
            category: 'xhr',
            data: {
              url,
              method: params.method,
              status_code: response.status,
              trace: transaction?.traceId,
              traceSampled: transaction?.sampled,
            },
            type: 'http',
          })

          transaction?.setHttpStatus(response.status)
          Sentry.finishTransaction(transaction)

          return response
        })
        .catch((errResponse: HttpResponse<T, E> | Error) => {
          // Unknown errors & special cases (but ignore cancelled requests)
          if (errResponse instanceof Error) {
            if (errResponse.name !== 'AbortError') {
              Sentry.captureAPIError?.(undefined, errResponse)
            }

            transaction?.setStatus(SentryTracing.SpanStatus.Cancelled)
            Sentry.finishTransaction(transaction)

            throw errResponse
          }

          Sentry.addBreadcrumb({
            category: 'xhr',
            data: {
              url,
              method: params.method,
              status_code: errResponse.status,
              trace: transaction?.traceId,
              traceSampled: transaction?.sampled,
            },
            type: 'http',
          })

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const error = errResponse.error as any
          const syntheticError =
            typeof error.request === 'string'
              ? new APIError(`Error fetching request ${error.request}`)
              : undefined

          Sentry.captureAPIError?.(errResponse, syntheticError)

          transaction?.setHttpStatus(errResponse.status)
          Sentry.finishTransaction(transaction)

          return errResponse
        })
    }
  }

  public setBaseUrl = (baseUrl: string) => {
    this.baseUrl = baseUrl
  }
  public setJwt = (jwt: string) => {
    this.jwt = jwt
  }
}

const api = new Api(
  new AvHttpClient({
    i18nInstance,
    sessionId,
    baseUrl: process.env.REACT_APP_API,
    jwt: sessionStorage.getItem(jwtKey),
    companyId: sessionStorage.getItem(ohcCompanyImpersonationKey),
    nthEmployee: sessionStorage.getItem(ohcCompanyImpersonationNthEmployeeKey),
  })
)

export default api
