export const breakpoint = {
  xs: 0,
  sm: 599,
  md: 899,
  lg: 1199,
  xl: 1535,
} as const

export const maxWidth = {
  body: 1920,
  content: 1200,
  banner: 1488, // navbar and footer
} as const
