import styled from '@emotion/styled'
import React from 'react'

import { PractitionerDetails as PractitionerDetailsT } from '../../../__generated__/api'
import { useIsMobile } from '../../hooks/useBreakpoint'
import { scale } from '../../utils/scale'
import { ColumnFlex, MinMaxDiv, RowFlex } from '../Layout/Layout'
import Quote from '../Quote/Quote'

import PractitionerBookingInstructions from './PractitionerBookingInstructions'
import PractitionerChips from './PractitionerChips'
import PractitionerIntroduction from './PractitionerIntroduction'
import PractitionerPriceDetails from './PractitionerPriceDetails'

const Container = styled(ColumnFlex)`
  gap: ${scale(3)};
`

const DesktopDetailsContainer = styled(RowFlex)`
  gap: ${scale(5)};
`

interface Props {
  details: PractitionerDetailsT
  compactLayout?: boolean
}

const PractitionerDetails: React.FC<React.PropsWithChildren<Props>> = ({
  details,
  compactLayout = false,
}) => {
  const isMobile = useIsMobile()
  return compactLayout || isMobile ? (
    <Container data-cy="practitionerDetails">
      {details.testimonial && <Quote text={details.testimonial} />}
      <PractitionerIntroduction introduction={details.introduction} />
      <PractitionerChips
        competencies={details.competencies}
        languages={details.languages}
        locations={details.locations}
        ageGroups={details.patientAgeGroups}
      />
      <PractitionerPriceDetails priceEstimates={details.priceEstimates} />
      <PractitionerBookingInstructions />
    </Container>
  ) : (
    <Container data-cy="practitionerDetails">
      {details.testimonial && <Quote text={details.testimonial} />}
      <DesktopDetailsContainer>
        <Container>
          <PractitionerIntroduction introduction={details.introduction} />
          <PractitionerPriceDetails priceEstimates={details.priceEstimates} />
          <PractitionerBookingInstructions />
        </Container>
        <Container>
          <MinMaxDiv size="300px">
            <PractitionerChips
              competencies={details.competencies}
              languages={details.languages}
              locations={details.locations}
              ageGroups={details.patientAgeGroups}
            />
          </MinMaxDiv>
        </Container>
      </DesktopDetailsContainer>
    </Container>
  )
}

export default PractitionerDetails
