import styled from '@emotion/styled'
import { Primary300 } from '@mehilainen/design-system-tokens/colors'
import { ExclamationTriangle } from '@mehilainen/mds-customer/icons'
import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'

import { isFromAppAtom } from '../../../state/common/atoms'
import { useOHC } from '../../hooks/useOHC'
import i18n from '../../i18n/i18n'
import { getCustomerServiceNumber } from '../../services/customerService'
import { scale } from '../../utils/scale'
import { DefaultButton } from '../DefaultButton/DefaultButton'
import { ColumnFlex } from '../Layout/Layout'
import ResponsiveModal from '../Modal/ResponsiveModal/ResponsiveModal'
import { Text } from '../Typography/Typography'

interface Props {
  ohcWarningReason: 'disallowed' | 'online_reservation_denied'
}
const TopContainer = styled(ColumnFlex)`
  gap: ${scale(3)};
  align-items: center;
  text-align: center;
`

const WarningTriangle = styled(ExclamationTriangle)`
  width: ${scale(5)};
  height: ${scale(5)};
`

const StyledButton = styled(DefaultButton)`
  padding: 5px 15px;
`

const OHCWarningModal: React.FC<React.PropsWithChildren<Props>> = ({
  ohcWarningReason: ohcWarningReason,
}) => {
  const { t } = useTranslation()
  const { setOHC } = useOHC()

  const isFromApp = useRecoilValue(isFromAppAtom)

  const onDcRedirect = useCallback(
    (queue: string) => {
      setOHC(false)
      if (isFromApp) {
        window.location.replace(t('component.digitalClinicTile.redirectUrlOMApp'))
      } else {
        window.open(
          `${t(
            'component.digitalClinicTile.redirectUrlOMWeb'
          )}/?redirectAfterLogin=/digital-clinic/new/${queue}`
        )
      }
    },
    [isFromApp, setOHC, t]
  )

  const link = getCustomerServiceNumber('general', i18n.language)
  const { label: telNumber } = link

  if (ohcWarningReason === 'online_reservation_denied') {
    return (
      <ResponsiveModal open={true} fullWidth disableMobileClose>
        <TopContainer>
          <WarningTriangle />
          <Text $size={500}>{t('component.ohcModal.onlineReservationDenied.header')} </Text>
          <Text $size={300}>
            <Trans
              i18nKey={'component.ohcModal.onlineReservationDenied.description'}
              values={{ telNumber }}
            >
              <Text as="a" $height="Small" $weight="Medium" $color={Primary300} href={link.link} />
            </Trans>
          </Text>
          <DefaultButton
            onClick={() => {
              onDcRedirect('digityöterveysasema')
            }}
          >
            {t('component.ohcModal.onlineReservationDenied.moveToDigitalClinic')}
          </DefaultButton>
          <StyledButton
            variant="outlined"
            onClick={() => {
              setOHC(false)
            }}
          >
            {t('component.ohcModal.contractNotFound.returnToPrivate')}
          </StyledButton>
        </TopContainer>
      </ResponsiveModal>
    )
  }
  return (
    <ResponsiveModal open={true} fullWidth disableMobileClose>
      <TopContainer>
        <WarningTriangle />
        <Text $size={500}>{t('component.ohcModal.contractNotFound.header')} </Text>
        <Text $size={300}>{t('component.ohcModal.contractNotFound.description')} </Text>
        <DefaultButton
          onClick={() => {
            setOHC(false)
          }}
        >
          {t('component.ohcModal.contractNotFound.returnToPrivate')}
        </DefaultButton>
      </TopContainer>
    </ResponsiveModal>
  )
}

export default OHCWarningModal
