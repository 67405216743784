import { Clock } from '@mehilainen/mds-customer/icons'
import dayjs, { Dayjs } from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { NotificationStyle } from '../../../__generated__/api'
import Notification from '../Notification/Notification'

interface Props {
  releaseDateTime?: Dayjs
  className?: string
}

const LockReleaseWarning: React.FC<React.PropsWithChildren<Props>> = ({
  releaseDateTime,
  className,
}) => {
  const { t } = useTranslation()
  const [secondsLeft, setSecondsLeft] = useState<number>()

  useEffect(() => {
    const interval = setInterval(() => {
      if (releaseDateTime) {
        setSecondsLeft(releaseDateTime.diff(dayjs(), 'seconds'))
      }
    })

    return () => clearInterval(interval)
  }, [releaseDateTime, setSecondsLeft])

  if (!releaseDateTime || !secondsLeft || secondsLeft > 120) {
    return null
  }

  let timeLeft

  if (secondsLeft >= 0) {
    const minutes = Math.floor(secondsLeft / 60)
    const seconds = secondsLeft - minutes * 60
    if (minutes > 0) {
      timeLeft = `${minutes}:${String(seconds).padStart(2, '0')}min`
    } else {
      timeLeft = `${seconds}s`
    }
  }

  return (
    <Notification
      style={NotificationStyle.Warning}
      className={className}
      icon={<Clock />}
      title={
        timeLeft
          ? t('component.lockReleaseWarning.lockedHeading', { timeLeft })
          : t('component.lockReleaseWarning.releasedHeading')
      }
    >
      {timeLeft
        ? t('component.lockReleaseWarning.lockedText')
        : t('component.lockReleaseWarning.releasedText')}
    </Notification>
  )
}

export default LockReleaseWarning
