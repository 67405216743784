import styled from '@emotion/styled'
import { Black, White } from '@mehilainen/design-system-tokens/colors'
import { Facebook, Instagram, Twitter, Youtube, Linkedin } from '@mehilainen/mds-customer/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { scale } from '../../utils/scale'
import { GappedRowFlex } from '../Layout/Layout'

const SocialMediaButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Black};
  color: ${White};
  border-radius: 50%;
  width: ${scale(4)};
  height: ${scale(4)};
  &:hover {
    background: ${White};
    color: ${Black};
  }
`

SocialMediaButton.defaultProps = {
  target: '_blank',
  className: 'no-external-icon no-link-styles',
}

const SocialMediaButtons: React.FC<React.PropsWithChildren<{ className?: string }>> = ({
  className,
}) => {
  const { t } = useTranslation()
  return (
    <GappedRowFlex gap={scale(3)} className={className}>
      <SocialMediaButton
        href={t('component.socialMediaButtons.instagram')}
        aria-label="Mehiläinen Oy Instagram"
      >
        <Instagram />
      </SocialMediaButton>
      <SocialMediaButton
        href={t('component.socialMediaButtons.facebook')}
        aria-label="Mehiläinen Oy Facebook"
      >
        <Facebook />
      </SocialMediaButton>
      <SocialMediaButton
        href={t('component.socialMediaButtons.twitter')}
        aria-label="Mehiläinen Oy Twitter"
      >
        <Twitter />
      </SocialMediaButton>
      <SocialMediaButton
        href={t('component.socialMediaButtons.linkedIn')}
        aria-label="Mehiläinen Oy LinkedIn"
      >
        <Linkedin />
      </SocialMediaButton>
      <SocialMediaButton
        href={t('component.socialMediaButtons.youtube')}
        aria-label="Mehiläinen Oy Youtube"
      >
        <Youtube />
      </SocialMediaButton>
    </GappedRowFlex>
  )
}

export default styled(SocialMediaButtons)``
