import styled from '@emotion/styled'
import { Error500, Gray100, Gray800, Gray900 } from '@mehilainen/design-system-tokens/colors'
import { Primary500 } from '@mehilainen/mds-customer/colors'
import { CircleTimes } from '@mehilainen/mds-customer/icons'
import React from 'react'
import { Trans } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { SupportedLanguage } from '../../../__generated__/api'
import { ColumnFlex } from '../../../common/components/Layout/Layout'
import { Text } from '../../../common/components/Typography/Typography'
import { useIsMobile } from '../../../common/hooks/useBreakpoint'
import useChangeLanguage from '../../../common/hooks/useChangeLanguage'
import { useTranslation } from '../../../common/hooks/useTranslation'
import { getCustomerServiceNumber } from '../../../common/services/customerService'
import { scale } from '../../../common/utils/scale'

const Container = styled(ColumnFlex)<{ isMobile?: boolean }>`
  min-height: 100%;
  background-color: ${Gray100};
  padding: 16px;
  padding-bottom: ${scale(12)};
  gap: 16px;
  align-items: ${({ isMobile }) => (isMobile ? 'flex-start' : 'center')};
  text-align: ${({ isMobile }) => (isMobile ? 'left' : 'center')};
  white-space: pre-wrap;
`

const AppointmentCancellationLegacyView: React.FC = () => {
  const { t, i18n } = useTranslation()
  const isMobile = useIsMobile()
  const location = useLocation()
  const { changeLanguage } = useChangeLanguage()
  const searchParams = new URLSearchParams(location.search)
  if (searchParams.has('language')) {
    changeLanguage(searchParams.get('language') as SupportedLanguage)
  }
  const isDental = searchParams.has('od') && searchParams.get('od') === '1'
  const customerService = getCustomerServiceNumber(isDental ? 'dental' : 'general', i18n.language)

  return (
    <Container isMobile={isMobile}>
      <CircleTimes sx={{ color: Error500, alignSelf: 'center' }} fontSize="large" />
      <Text as="h1" $height="Medium" $weight="Medium" $size={500} $color={Gray900}>
        {t('component.legacyCancellation.heading')}
      </Text>
      <Text as="p" $height="Large" $weight="Regular" $size={300} $color={Gray800}>
        <Trans i18nKey={'component.legacyCancellation.text'}>
          <Text
            as="a"
            $height="Large"
            $weight="Medium"
            $size={300}
            href={t('component.legacyCancellation.link')}
            target="_blank"
          />
        </Trans>
      </Text>
      <div>
        <Text as="p" $height="Large" $weight="Medium" $size={400} $color={Gray800}>
          {t(
            `component.legacyCancellation.${isDental ? 'dentalCustomerService' : 'customerService'}`
          )}
        </Text>
        <Text
          as="a"
          $height="Medium"
          $weight="Medium"
          $size={600}
          $color={Primary500}
          href={customerService.link}
          className="no-link-styles"
        >
          {customerService.label}
        </Text>
        <Text as="p" $height="Small" $weight="Regular" $size={200}>
          {t('component.legacyCancellation.callPrice')}
        </Text>
      </div>
    </Container>
  )
}

export default AppointmentCancellationLegacyView
