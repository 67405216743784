import React, {
  createContext,
  useContext,
  useCallback,
  ReactNode,
  useMemo,
  useEffect,
  useState,
} from 'react'
import { useSetRecoilState } from 'recoil'
import * as yup from 'yup'

import { isFromRelayAppAtom } from '../../state/common/atoms'

declare global {
  interface Window {
    webkit?: {
      messageHandlers?: {
        avHandler?: {
          postMessage?: (message: string) => void
        }
      }
    }
    avAndroid?: {
      avPostMessage?: (message: string) => void
    }
    receiveMessageFromMobile: (message: string) => void
  }
}

export enum RelayEvent {
  Init = 'INIT',
  PopupOpen = 'POPUP_OPEN',
  HeaderState = 'HEADER_STATE',
  AppointmentConfirmation = 'APPOINTMENT_CONFIRMATION',
  SearchHeight = 'SEARCH_HEIGHT',
}

export enum MobileHeaderState {
  Main = 'main',
  Sub = 'sub',
  Modal = 'modal',
}

const mobileMessageSchema = yup.object().shape({
  event: yup.mixed().oneOf(Object.values(RelayEvent)).required(),
  value: yup.lazy(() => {
    return yup
      .mixed()
      .when('event', (event, schema) => {
        switch (event) {
          case RelayEvent.Init:
            return schema.shape({
              flags: yup.array().of(yup.string().required()).required(),
            })
          case RelayEvent.PopupOpen:
            return yup.boolean().required()
          case RelayEvent.HeaderState:
            return yup.string().oneOf(Object.values(MobileHeaderState)).required()
          case RelayEvent.SearchHeight:
            return yup.number().required()
        }
      })
      .nullable()
  }),
})

interface MobileMessageEvent extends Event {
  detail: unknown
}
const MOBILE_MESSAGE_EVENT_NAME = 'messageFromMobile'

window.receiveMessageFromMobile = (mobileMessage: string) => {
  if (mobileMessage) {
    let message
    try {
      message = JSON.parse(mobileMessage)
      mobileMessageSchema.validateSync(message)
    } catch (error) {
      message = null
    }

    if (message) {
      const customEvent: MobileMessageEvent = new CustomEvent<MobileMessageEvent>(
        MOBILE_MESSAGE_EVENT_NAME,
        { detail: message }
      )
      window.dispatchEvent(customEvent)
    }
  }
}

export interface RelayMessage {
  event: RelayEvent
  value: unknown
  ts: number
}

type MobileRelayContextType = {
  postMessage(event: RelayEvent, value: unknown): void
  message: RelayMessage | null
}

const MobileRelayContext = createContext<MobileRelayContextType>({
  postMessage: () => {
    /* Placeholder */
  },
  message: null,
})

export const MobileRelayProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [message, setMessage] = useState<RelayMessage | null>(null)

  const setIsFromRelayApp = useSetRecoilState(isFromRelayAppAtom)
  if (window.webkit?.messageHandlers?.avHandler?.postMessage || window.avAndroid?.avPostMessage) {
    setIsFromRelayApp(true)
  }

  const postMessage = useCallback((event: RelayEvent, value: unknown) => {
    let json
    try {
      const newMessage = { event: event, value: value }
      mobileMessageSchema.validateSync(newMessage)
      json = JSON.stringify(newMessage)
    } catch (error) {
      json = null
    }

    if (json && window.webkit?.messageHandlers?.avHandler?.postMessage) {
      window.webkit.messageHandlers.avHandler.postMessage(json)
    }
    if (json && window.avAndroid?.avPostMessage) {
      window.avAndroid.avPostMessage(json)
    }
  }, [])

  const handleMessage = useCallback((event: CustomEvent) => {
    setMessage({ ...event.detail, ts: Date.now() })
  }, [])

  useEffect(() => {
    window.addEventListener(MOBILE_MESSAGE_EVENT_NAME, handleMessage as EventListener)

    return () => {
      window.removeEventListener(MOBILE_MESSAGE_EVENT_NAME, handleMessage as EventListener)
    }
  }, [handleMessage])

  const memoizedValue = useMemo(
    () => ({
      postMessage,
      message,
    }),
    [postMessage, message]
  )

  return <MobileRelayContext.Provider value={memoizedValue}>{children}</MobileRelayContext.Provider>
}

const useMobileRelay = () => {
  return useContext(MobileRelayContext)
}

export default useMobileRelay
