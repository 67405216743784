import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'

import { SupportedLanguage } from '../../../__generated__/api'
import { practitionerModalPractitionerId } from '../../../state/practitioner/atoms'
import { useApi } from '../../hooks/useApi'
import { useOHC } from '../../hooks/useOHC'
import api from '../../services/api'
import Modal from '../Modal/Modal'
import PractitionerDetails from '../PractitionerDetails/PractitionerDetails'
import PractitionerHeader from '../PractitionerDetails/PractitionerHeader'

interface PractitionerInfoModalProps {
  practitionerId?: number
  open: boolean
  onClose(): void
  onDisplayAppointmentsClick(): void
}

const PractitionerInfoModal: React.FC<React.PropsWithChildren<PractitionerInfoModalProps>> = ({
  practitionerId,
  open,
  onClose,
  onDisplayAppointmentsClick,
}) => {
  const { i18n } = useTranslation()
  const { isOHCSide } = useOHC()

  const { data: details, pending } = useApi(
    api.v1.getPractitionerDetails,
    {
      lang: i18n.language as SupportedLanguage,
      practitionerId: practitionerId ?? 0,
      isOhc: isOHCSide,
    },
    null,
    practitionerId !== undefined
  )

  return (
    <Modal open={open && !pending} onClose={onClose} maxWidth="sm">
      {details && (
        <>
          <PractitionerHeader
            practitioner={details}
            onDisplayAppointmentsClick={onDisplayAppointmentsClick}
            showDisplayAppointmentsButton
          />
          <PractitionerDetails details={details} compactLayout />
        </>
      )}
    </Modal>
  )
}

interface Props {
  onDisplayAppointmentsClick(practitionerId: number): void
}

const PractitionerInfoModalWrapper: React.FC<React.PropsWithChildren<Props>> = ({
  onDisplayAppointmentsClick,
}) => {
  const [practitionerId, setPractitionerId] = useRecoilState(practitionerModalPractitionerId)

  return (
    <PractitionerInfoModal
      practitionerId={practitionerId}
      open={practitionerId !== undefined}
      onClose={() => setPractitionerId(undefined)}
      onDisplayAppointmentsClick={() =>
        practitionerId && onDisplayAppointmentsClick(practitionerId)
      }
    />
  )
}

export default PractitionerInfoModalWrapper
