import { atom } from 'recoil'

import { AppointmentSearchResult } from '../../__generated__/api'

export const initialReserveHistoryRestoredAtom = atom<boolean>({
  key: 'reserve-initialReserveHistoryRestoredAtom',
  default: false,
})

export const bookedForSelfAtom = atom<boolean>({
  key: 'reserve-bookedForSelf',
  default: true,
})

export const preSelectedUserOmIdAtom = atom<number | undefined>({
  key: 'reserve-selectedUserOmId',
  default: undefined,
})

export const selectedAppointmentDetailsAtom = atom<AppointmentSearchResult | undefined>({
  key: 'reserve-selectedAppointmentDetails',
  default: undefined,
})
