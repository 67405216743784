import { Alert } from '@mui/material'
import type React from 'react'
import { useRecoilValue } from 'recoil'

import { scheduledMaintenanceMessageAtom } from '../../../state/common/atoms'
import dayjs from '../../utils/dayjs/dayjs'
import { capitalizeFirstLetter } from '../../utils/text'

const ScheduledMaintenance: React.FC<React.PropsWithChildren<unknown>> = () => {
  const scheduledMaintenanceMessage = useRecoilValue(scheduledMaintenanceMessageAtom)

  if (scheduledMaintenanceMessage) {
    const splitted = scheduledMaintenanceMessage.split(';')
    const timeTo = dayjs().to(splitted[0])

    if (dayjs().isBefore(splitted[0])) {
      return <Alert severity="warning">{`${capitalizeFirstLetter(timeTo)}: ${splitted[1]}`}</Alert>
    }
  }

  return null
}

export default ScheduledMaintenance
