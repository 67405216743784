import dayjs, { Dayjs } from 'dayjs'

import { AppointmentSearchResult } from '../../../__generated__/api'

/**
 * Split into two AppointmentSearchResult groups splitted by splitDate.
 * Each practitioner is present only in one or the other group.
 */
export const mapToPractitionerGroups = (
  appointments: AppointmentSearchResult[],
  splitDate: Dayjs
): { first: AppointmentSearchResult[]; later: AppointmentSearchResult[] } => {
  const appointmentsCopy = [...appointments]
  // sort descending so that last added item (=next appointment) to the Map is in effect
  const sorted = appointmentsCopy.sort((a, b) => dayjs(b.time).unix() - dayjs(a.time).unix())
  const uniqueByPractitioner = [...new Map(sorted.map((i) => [i.specialistId, i])).values()].sort(
    (a, b) =>
      dayjs(a.time).unix() - dayjs(b.time).unix() ||
      a.specialistName.localeCompare(b.specialistName)
  )
  let splitIndex = uniqueByPractitioner.findIndex((i) => dayjs(i.time).isAfter(splitDate, 'day'))
  if (splitIndex === -1) splitIndex = uniqueByPractitioner.length
  const first = uniqueByPractitioner.slice(0, splitIndex)
  const later = uniqueByPractitioner.slice(splitIndex)
  return { first, later }
}
