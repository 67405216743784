import dayjs from './dayjs/dayjs'

const ssnValidationTable = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'H',
  'J',
  'K',
  'L',
  'M',
  'N',
  'P',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
]

const ssnRe = /^([0-9]{6})([\\+\-A-FU-Y])([0-9]{3})([0-9A-FHJ-NPR-Y])$/

export const isSsn = (value: string | undefined): boolean => {
  if (!value) {
    return false
  }
  const valueToTest = value.toUpperCase()

  if (!ssnRe.test(valueToTest)) {
    return false
  }

  const groups = valueToTest.match(ssnRe)
  if (!groups) {
    return false
  }

  const number = parseInt(`${groups[1]}${groups[3]}`, 10)
  const checkCharacter = groups[4]

  if (checkCharacter === ssnValidationTable[number % 31]) {
    // Age feasibility check
    const age = getSsnAge(value)

    if (age === null || age < 0) {
      return false
    }

    if (process.env.REACT_APP_ENV !== 'test' && age > 120) {
      return false
    }
  }

  return true
}

export const getSsnAge = (value: string | undefined): number | null => {
  if (!value || !ssnRe.test(value)) {
    return null
  } else {
    const htc = value.substring(6, 7).toUpperCase()
    const htd = value.substring(0, 2)
    const htm = value.substring(2, 4)
    const hty = value.substring(4, 6)
    const century =
      htc === '+'
        ? '18'
        : ['-', 'U', 'V', 'W', 'X', 'Y'].includes(htc)
        ? '19'
        : ['A', 'B', 'C', 'D', 'E', 'F'].includes(htc)
        ? '20'
        : null

    if (!century) {
      return null
    }

    const birthday = dayjs(`${dayjs().year()}${htm}${htd}`, 'YYYYMMDD')
    let age = dayjs().year() - parseInt(century + hty)

    if (dayjs().isBefore(birthday, 'day')) {
      age--
    }

    return age
  }
}
