import styled from '@emotion/styled'
import { Checkbox, FormControlLabel } from '@mui/material'
import { SwitchBaseProps } from '@mui/material/internal/SwitchBase'
import React from 'react'

import { CenteredColumnFlex } from '../../../common/components/Layout/Layout'
import { Text } from '../../../common/components/Typography/Typography'
import { scale } from '../../../common/utils/scale'

export const ButtonContainer = styled(CenteredColumnFlex)`
  margin-top: ${scale(3)};
  gap: ${scale(2)};
`

export const StyledFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: (prop) => prop !== 'addTopMargin' && prop !== 'addBottomMargin',
})<{
  addTopMargin?: boolean
  addBottomMargin?: boolean
}>`
  margin-left: 0;
  margin-top: ${(props) => (props.addTopMargin ? '16px' : '0')};
  margin-bottom: ${(props) => (props.addBottomMargin ? '16px' : '0')};
  width: 100%;

  .MuiButtonBase-root {
    margin-right: 8px;
  }

  &:focus-within {
    outline: auto;
  }
`
StyledFormControlLabel.defaultProps = { disableTypography: true }

export const StyledCheckBox = styled(Checkbox)`
  align-self: flex-start;
`

interface ReserveCheckboxProps {
  name: string
  checked: SwitchBaseProps['checked']
  onChange: SwitchBaseProps['onChange']
  label: string
}

export const ReserveCheckbox: React.FC<React.PropsWithChildren<ReserveCheckboxProps>> = ({
  name,
  checked,
  onChange,
  label,
}) => {
  return (
    <StyledFormControlLabel
      control={
        <StyledCheckBox
          disableRipple
          id={name}
          name={name}
          checked={checked}
          onChange={onChange}
          color="primary"
        />
      }
      label={
        <Text $size={300} $weight="Regular" $height="Medium">
          {label}
        </Text>
      }
    />
  )
}
