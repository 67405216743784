import { NodeAutocompleteResult } from '../../../__generated__/api'
import i18n from '../../i18n/i18n'
import api from '../api'

import AVSearch from './AVSearch'

export class NodeSearch extends AVSearch<NodeAutocompleteResult> {
  isOhc = false
  setIsOhc(isOhc: boolean) {
    this.isOhc = isOhc
  }
  search(searchInput: string, limit: number): Promise<NodeAutocompleteResult[]> {
    return api.v1
      .autocompleteNode({
        searchString: searchInput,
        limit,
        lang: i18n.language,
        ohc: this.isOhc,
      })
      .then((res) => res.data)
  }
}
