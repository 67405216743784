import styled from '@emotion/styled'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { PractitionerDetails } from '../../../__generated__/api'
import AppointmentLengthSelect, {
  AppointmentLengthOption,
} from '../../../common/components/AppointmentLengthSelect/AppointmentLengthSelect'
import { ColumnFlex } from '../../../common/components/Layout/Layout'
import { Text } from '../../../common/components/Typography/Typography'
import { scale } from '../../../common/utils/scale'

const ContentContainer = styled(ColumnFlex)`
  & > ${Text} {
    margin-bottom: ${scale(2)};
  }
`

const Container = styled(ColumnFlex)`
  ${ContentContainer} + ${ContentContainer} {
    margin-top: ${scale(3)};
  }
`

const StyledUl = styled.ul`
  list-style: disc inside;
`

interface Props {
  practitionerDetails: PractitionerDetails
  appointmentLengthOptions: AppointmentLengthOption[]
  selectedLength: AppointmentLengthOption
  onSelect(length: AppointmentLengthOption): void
  onSelectUnavailableDuration?(duration: number): void
}

const LengthSelect: React.FC<React.PropsWithChildren<Props>> = ({
  practitionerDetails,
  appointmentLengthOptions,
  selectedLength,
  onSelect,
  onSelectUnavailableDuration,
}) => {
  const { t } = useTranslation()

  return (
    <Container>
      {appointmentLengthOptions.length > 1 && (
        <ContentContainer>
          <Text as="h2" $size={400} $weight="Regular" $height="Medium" aria-live="assertive">
            {t('component.appointmentOptionsSelect.lengthSelectHeading')}
          </Text>
          <AppointmentLengthSelect
            options={appointmentLengthOptions}
            value={selectedLength}
            onSelect={onSelect}
            onSelectUnavailableDuration={onSelectUnavailableDuration}
          />
          {!selectedLength.available && (
            <Text>{t('component.appointmentLengthSelect.optionNotAvailable')}</Text>
          )}
        </ContentContainer>
      )}

      {practitionerDetails.patientRestrictions.length > 0 && (
        <ContentContainer>
          <Text as="h2" $size={400} $weight="Regular" $height="Medium">
            {t('component.appointmentOptionsSelect.patientRestrictionsHeading')}
          </Text>
          <StyledUl>
            {practitionerDetails.patientRestrictions.map((restriction, index) => (
              <li key={`${restriction.id}-${index}`}>
                <Text $size={300} $height="Large" $weight="Regular">
                  {restriction.patientRestriction}
                </Text>
              </li>
            ))}
          </StyledUl>
        </ContentContainer>
      )}
    </Container>
  )
}

export default LengthSelect
