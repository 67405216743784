import styled from '@emotion/styled'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import type React from 'react'
import { useTranslation } from 'react-i18next'

import { VisuallyHidden } from '../Layout/Layout'

import Fieldset from './Fieldset'
import { Gender } from './types'

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: column;
`

interface Props {
  name: string
  value: Gender
  handleChange: (event: React.ChangeEvent<{ value: Gender }>) => void
  hideAccordion?: boolean
}

const GenderSelect: React.FC<React.PropsWithChildren<Props>> = ({
  name,
  value,
  handleChange,
  hideAccordion,
}) => {
  const { t } = useTranslation()

  return (
    <Fieldset
      legend={t('component.filterOptions.genderSelect.legend')}
      chip={
        value !== Gender.UNSPECIFIED ? (
          <>
            <VisuallyHidden as="span">, </VisuallyHidden>
            <span>1</span>
            <VisuallyHidden as="span">{t('component.filterOptions.aria.selection')}</VisuallyHidden>
          </>
        ) : undefined
      }
      hideAccordion={hideAccordion}
    >
      <StyledRadioGroup>
        {Object.values(Gender).map((gender) => (
          <FormControlLabel
            key={`genderSelect-RadioButton-${gender}`}
            control={
              <Radio
                id={`genderSelect-gender-${gender}-radio`}
                checked={gender === value}
                onChange={() => {
                  handleChange({
                    target: {
                      name,
                      value: gender,
                    },
                  } as unknown as React.ChangeEvent<{ value: Gender }>)
                }}
              />
            }
            label={t(`component.filterOptions.genderSelect.gender.${gender}`)}
            value={gender}
          />
        ))}
      </StyledRadioGroup>
    </Fieldset>
  )
}

export default GenderSelect
