import { useHistory } from 'react-router-dom'

export const useInlineLinks = () => {
  const history = useHistory()

  const handleInlineLinks = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const isInternalLink = event.currentTarget.host === window.location.host

    if (isInternalLink) {
      event.preventDefault()
      history.push(event.currentTarget.pathname + event.currentTarget.search)
    }
  }

  return handleInlineLinks
}
