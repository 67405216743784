import * as qs from 'qs'
import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { SupportedLanguage } from '../../__generated__/api'

import useChangeLanguage from './useChangeLanguage'

const useLang = (): void => {
  const { currentLanguage, changeLanguage } = useChangeLanguage()
  const history = useHistory()
  // Deserialization fn to restore state from url
  const restoreFromHistory = useCallback(
    (searchString: string) => {
      if (searchString !== '') {
        const parsed = qs.parse(searchString)

        parsed.lang && changeLanguage(String(parsed.lang) as SupportedLanguage)
      }
    },
    [changeLanguage]
  )

  useEffect(() => {
    restoreFromHistory(history.location.search.slice(1))
    return history.listen((location, action) => {
      if (action !== 'REPLACE') {
        restoreFromHistory(history.location.search.slice(1))
      }
    })
  }, [restoreFromHistory, history])

  // Serialize relevant state to url query string
  useEffect(() => {
    history.replace({
      search: qs.stringify({
        lang: currentLanguage,
      }),
    })
  }, [history, currentLanguage])
}

export default useLang
