import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { White, Gray700, Info100, Info500, Gray900 } from '@mehilainen/design-system-tokens/colors'
import { FormControl, FormLabel } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import React, { ReactElement, useState } from 'react'

import Tooltip from './Tooltip'

const StyledFormControl = styled(FormControl, {
  shouldForwardProp: (prop) => !['hideBorder'].includes(prop),
})<{
  hideBorder?: boolean
}>`
  .MuiPaper-root {
    padding: 0;
  }
`

const StyledAccordion = styled(Accordion, {
  shouldForwardProp: (prop) => !['hideExpandToggle'].includes(prop),
})<{ hideExpandToggle: boolean }>`
  background: transparent;
  box-shadow: none;
  ${(props) =>
    props.hideExpandToggle &&
    css`
      .MuiAccordionSummary-expandIconWrapper {
        display: none !important;
      }
    `}
`

const StyledAccordionSummary = styled(AccordionSummary, {
  shouldForwardProp: (prop) => !['hideAccordion'].includes(prop),
})<{ hideAccordion?: boolean }>`
  background: ${White};
  border-radius: 2px;
  min-height: 0;
  padding: 15px 12px;

  .MuiAccordionSummary-content {
    margin: 0;
  }

  &.Mui-expanded {
    min-height: 0;
  }

  ${(props) =>
    props.hideAccordion &&
    css`
      background: transparent;
      padding: 16px 0;

      &.MuiButtonBase-root:hover {
        cursor: default;
      }
    `}
`

const StyledAccordionDetails = styled(AccordionDetails, {
  shouldForwardProp: (prop) => !['hideAccordion'].includes(prop),
})<{ hideAccordion?: boolean }>`
  padding: 0 16px 16px 16px;

  .MuiFormControlLabel-label,
  .MuiSelect-select {
    font-size: 0.813rem;
  }

  ${(props) =>
    props.hideAccordion &&
    css`
      padding: 0;
    `}
`

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`

const StyledFormLabel = styled(FormLabel, {
  shouldForwardProp: (prop) => !['hideAccordion'].includes(prop),
})<{ hideAccordion?: boolean }>`
  font-weight: 500;
  font-size: 0.813rem;
  color: ${Gray700};
  line-height: 1.4;

  ${(props) =>
    props.hideAccordion &&
    css`
      font-size: 0.813rem;
      color: ${Gray900};
      font-weight: 600;
      line-height: 1;
    `}
`

const Chip = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 24px;
  height: 24px;
  border-radius: 999px;
  color: ${Info500};
  background: ${Info100};
  font-weight: 500;
  font-size: 0.813rem;
`

interface Props {
  legend: string
  tooltip?: string
  tooltipLink?: { link: string; text: string }
  chip?: string | ReactElement
  hideAccordion?: boolean
  hideBorder?: boolean
  labelId?: string
}

const Fieldset: React.FC<React.PropsWithChildren<Props>> = ({
  legend,
  tooltip,
  tooltipLink,
  children,
  chip,
  hideAccordion,
  hideBorder,
  labelId,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false)

  return (
    <StyledFormControl hideBorder={hideBorder}>
      <StyledAccordion
        expanded={hideAccordion || Boolean(chip) || expanded}
        onChange={() => !chip && setExpanded(!expanded)}
        hideExpandToggle={Boolean(!hideAccordion && chip)}
      >
        <StyledAccordionSummary hideAccordion={hideAccordion} aria-disabled={Boolean(chip)}>
          <LabelContainer>
            <StyledFormLabel id={labelId} hideAccordion={hideAccordion}>
              {legend}
            </StyledFormLabel>
            <Tooltip tooltip={tooltip} tooltipLink={tooltipLink} />
          </LabelContainer>
          {!hideAccordion && chip && <Chip>{chip}</Chip>}
        </StyledAccordionSummary>
        <StyledAccordionDetails hideAccordion={hideAccordion}>{children}</StyledAccordionDetails>
      </StyledAccordion>
    </StyledFormControl>
  )
}

export default Fieldset
