import styled from '@emotion/styled'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  Competence,
  PatientAgeGroup,
  PractitionerLanguage,
  PractitionerLocation,
} from '../../../__generated__/api'
import { scale } from '../../utils/scale'
import InfoChips from '../InfoChip/InfoChips'
import { ColumnFlex } from '../Layout/Layout'
import { Text } from '../Typography/Typography'

const Container = styled(ColumnFlex)`
  gap: ${scale(1.5)};
`

interface Props {
  competencies: Competence[]
  languages: PractitionerLanguage[]
  locations: PractitionerLocation[]
  ageGroups: PatientAgeGroup[]
}

const PractitionerChips: React.FC<React.PropsWithChildren<Props>> = ({
  competencies,
  languages,
  locations,
  ageGroups,
}) => {
  const { t } = useTranslation()

  return (
    <>
      {ageGroups.length > 0 && (
        <Container>
          <Text $weight="Bold" $height="Large">
            {t('component.practitionerDetails.ageGroups')}
          </Text>
          <InfoChips chipTexts={ageGroups.map((group) => group.patientAgeGroup)} />
        </Container>
      )}

      {[2, 3, 4, 5, 6, 1].map((type, typeIdx) => {
        let filtered = competencies.filter((competence) => competence.type === type)

        if (filtered.length === 0) {
          return null
        }

        // Only display first 5 professional expertise
        if (type === 1) {
          filtered = filtered.slice(0, 5)
        }

        return (
          <Container key={typeIdx}>
            <Text $weight="Bold" $height="Large">
              {t(`component.practitionerCompetencies.${type}`)}
            </Text>
            <InfoChips
              chipTexts={filtered.map(
                (competence) =>
                  `${competence.competence}${competence.year ? ` (${competence.year})` : ''}`
              )}
            />
          </Container>
        )
      })}

      {languages.length > 0 && (
        <Container>
          <Text $weight="Bold" $height="Large">
            {t('component.practitionerDetails.languages')}
          </Text>
          <InfoChips
            chipTexts={languages.map((language) => `${language.language} (${language.level})`)}
          />
        </Container>
      )}

      {locations.length > 0 && (
        <Container>
          <Text $weight="Bold" $height="Large">
            {t('component.practitionerDetails.locations')}
          </Text>
          <ColumnFlex role="list">
            {locations.map((location) => (
              <Text role="listitem" key={`location-${location.id}`}>
                {location.name}
              </Text>
            ))}
          </ColumnFlex>
        </Container>
      )}
    </>
  )
}

export default PractitionerChips
