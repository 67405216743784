import { Base64 } from 'js-base64'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'

import { AppointmentType, SupportedLanguage } from '../../../__generated__/api'
import useChangeLanguage from '../../../common/hooks/useChangeLanguage'
import useQuery from '../../../common/hooks/useQuery'
import * as Analytics from '../../../common/utils/analytics'
import {
  selectedAppointmentLengthAtom,
  selectedAppointmentNodeIdAtom,
  selectedAppointmentServiceIdAtom,
  selectedAppointmentTypeAtom,
  selectedInsurancePayerAtom,
  isRedirectedFromInsuranceProviderAtom,
  isFromAppAtom,
  AppointmentId,
} from '../../../state/common/atoms'
import { InsurancePaymentType, selectedInsuranceSelector } from '../../../state/common/selectors'
import { preSelectedUserOmIdAtom } from '../../../state/reserve/atoms'

type InsuranceQueryData = {
  userOmUid: number
  appointmentId: AppointmentId
  appointmentType: AppointmentType
  appointmentLength: 'default' | number
  language: SupportedLanguage
  insuranceContractId: number
  insurancePayerId: string
  nodeId?: string
  serviceId?: number
  isFromApp?: boolean
}

const parseQueryData = (data64Data: string): InsuranceQueryData => {
  const data = Base64.decode(data64Data)
  const split = data.split(',')

  const parsed = split.reduce((acc, s) => {
    if (s.startsWith('u')) {
      return { ...acc, userOmUid: Number(s.slice(2)) }
    }
    if (s.startsWith('aid')) {
      const id = s.slice(4)
      return { ...acc, appointmentId: isNaN(Number(id)) ? id : Number(id) }
    }
    if (s.startsWith('at')) {
      return { ...acc, appointmentType: s.slice(6) }
    }
    if (s.startsWith('al')) {
      return {
        ...acc,
        appointmentLength: s.slice(3) === 'default' ? 'default' : Number(s.slice(3)),
      }
    }
    if (s.startsWith('icid')) {
      return { ...acc, insuranceContractId: Number(s.slice(5)) }
    }
    if (s.startsWith('ipid')) {
      return { ...acc, insurancePayerId: s.slice(5) }
    }
    if (s.startsWith('l')) {
      return { ...acc, language: s.slice(2) }
    }
    if (s.startsWith('nid')) {
      return { ...acc, nodeId: s.slice(4) }
    }
    if (s.startsWith('sid')) {
      return { ...acc, serviceId: Number(s.slice(4)) }
    }
    if (s.startsWith('app')) {
      return { ...acc, isFromApp: true }
    }

    return acc
  }, {})

  const missingFields = (
    [
      'appointmentId',
      'appointmentType',
      'appointmentLength',
      'language',
      'insuranceContractId',
      'insurancePayerId',
    ] as Array<keyof InsuranceQueryData>
  ).filter((field) => (parsed as unknown as InsuranceQueryData)[field] === undefined)

  if (missingFields.length > 0) {
    throw `Failed parsing insurance redirect data. Missing fields: ${missingFields.join(',')}`
  }

  return parsed as InsuranceQueryData
}

export const serializeQueryData = (data: InsuranceQueryData): string => {
  const parts = []

  parts.push(`u:${data.userOmUid}`)
  parts.push(`aid:${data.appointmentId}`)
  parts.push(`al:${data.appointmentLength}`)
  parts.push(`at:${data.appointmentType}`)
  parts.push(`l:${data.language}`)
  parts.push(`icid:${data.insuranceContractId}`)
  parts.push(`ipid:${data.insurancePayerId}`)

  if (data.nodeId) {
    parts.push(`nid:${data.nodeId}`)
  }

  if (data.serviceId) {
    parts.push(`sid:${data.serviceId}`)
  }

  if (data.isFromApp) {
    parts.push('app')
  }

  const joinedParts = parts.join(',')
  const base64Parts = Base64.encode(joinedParts)
  return base64Parts
}

interface Props {
  basePath: string
}

const SuccessRedirect: React.FC<React.PropsWithChildren<Props>> = ({ basePath }) => {
  const setSelectedAppointmentType = useSetRecoilState(selectedAppointmentTypeAtom)
  const setSelectedAppointmentLength = useSetRecoilState(selectedAppointmentLengthAtom)
  const setSelectedNodeId = useSetRecoilState(selectedAppointmentNodeIdAtom)
  const setSelectedService = useSetRecoilState(selectedAppointmentServiceIdAtom)
  const setSelectedInsuranceContractId = useSetRecoilState(selectedInsuranceSelector)
  const setSelectedInsurancePayerId = useSetRecoilState(selectedInsurancePayerAtom)
  const setPreSelectedUserOmIdAtom = useSetRecoilState(preSelectedUserOmIdAtom)
  const setIsRedirectedFromInsuranceProvider = useSetRecoilState(
    isRedirectedFromInsuranceProviderAtom
  )
  const setIsFromApp = useSetRecoilState(isFromAppAtom)
  const { changeLanguage } = useChangeLanguage()

  const query = useQuery()

  if (!query.has('data')) {
    return <Redirect to={`${basePath}/vahingonkorvauslupa/virhe`} />
  }

  try {
    const queryData = query.get('data')!
    const parsed = parseQueryData(queryData)
    Analytics.trackInsuranceRedirectParams(JSON.stringify(parsed), queryData)

    setPreSelectedUserOmIdAtom(parsed.userOmUid)
    setSelectedAppointmentType(parsed.appointmentType)
    setSelectedAppointmentLength(parsed.appointmentLength)
    setSelectedInsuranceContractId({
      id: parsed.insuranceContractId,
      paymentType: InsurancePaymentType.INSURANCE_COMPANY,
    })
    setSelectedInsurancePayerId(parsed.insurancePayerId)
    setSelectedNodeId(parsed.nodeId)
    setSelectedService(parsed.serviceId)
    changeLanguage(parsed.language)
    setIsRedirectedFromInsuranceProvider(true)

    if (parsed.isFromApp) {
      setIsFromApp(true)
    }

    return <Redirect to={`${basePath}/reserve/${parsed.appointmentId}`} />
  } catch {
    return <Redirect to={`${basePath}/`} />
  }
}

const InsuranceRedirect: React.FC<React.PropsWithChildren<Props>> = ({ basePath }) => {
  return (
    <>
      <Route
        exact
        path={[
          `${basePath}/vahingonkorvauslupa/valmis`,
          `${basePath}/vahingonkorvauslupa/peruuta`,
          `${basePath}/vahingonkorvauslupa/virhe`,
        ]}
      >
        <SuccessRedirect basePath={basePath} />
      </Route>
    </>
  )
}

export default InsuranceRedirect
