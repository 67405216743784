import React, { useEffect } from 'react'

import { LoginStatus } from '../../../state/user/atoms'

interface Props {
  basePath: string
  redirectPath: string
  loginStatus: LoginStatus
  onLogout: () => void
}

const ExternalRedirect: React.FC<React.PropsWithChildren<Props>> = ({
  basePath,
  redirectPath,
  loginStatus,
  onLogout,
}) => {
  useEffect(() => {
    if (!loginStatus) {
      window.location.href = redirectPath
      return
    }
    if (loginStatus !== 'external') {
      onLogout()
      window.location.href = redirectPath
      return
    }
    window.location.pathname = basePath
  }, [basePath, loginStatus, onLogout, redirectPath])

  return <></>
}

export default ExternalRedirect
