import {
  uiAngleRight,
  notificationCircleInfo,
  socialmediaTwitter,
  socialmediaInstagram,
  socialmediaYoutube,
  socialmediaFacebook,
  socialmediaLinkedin,
  uiBurgerMenu,
  travelGlobe,
  uiLongArrowUp,
  travelMapMarker,
  uiTimes,
  uiArrowLeft,
  uiAngleBottom,
  notificationExclamationTriangle,
  timeClock,
  uiExternalLink,
  notificationExclamationCircle,
} from '@mehilainen/design-system-tokens/icons'
import { SvgIcon } from '@mui/material'
import React from 'react'

const getIconComponent =
  (paths: string[]): React.VFC<React.ComponentProps<typeof SvgIcon>> =>
  (props) => (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      {paths.map((pathString, i) => (
        <path key={i} d={pathString} />
      ))}
    </SvgIcon>
  )

// If another icon is needed, just import it and export it like below
// Naming should follow hive convention - remove the category (if any) and rest as PascalCase e.g. ui/cog -> Cog
export const UiAngleRight = getIconComponent(uiAngleRight)
export const CircleInfo = getIconComponent(notificationCircleInfo)
export const Twitter = getIconComponent(socialmediaTwitter)
export const Instagram = getIconComponent(socialmediaInstagram)
export const Youtube = getIconComponent(socialmediaYoutube)
export const Facebook = getIconComponent(socialmediaFacebook)
export const LinkedIn = getIconComponent(socialmediaLinkedin)
export const UiBurgerMenu = getIconComponent(uiBurgerMenu)
export const TravelGlobe = getIconComponent(travelGlobe)
export const UiLongArrowUp = getIconComponent(uiLongArrowUp)
export const TravelMapMarker = getIconComponent(travelMapMarker)
export const UiTimes = getIconComponent(uiTimes)
export const ArrowLeft = getIconComponent(uiArrowLeft)
export const UiAngleBottom = getIconComponent(uiAngleBottom)
export const ExclamationTriangle = getIconComponent(notificationExclamationTriangle)
export const ExclamationCircle = getIconComponent(notificationExclamationCircle)
export const TimeClock = getIconComponent(timeClock)
export const UiExternalLink = getIconComponent(uiExternalLink)
