import { useCallback, useEffect } from 'react'

import useMobileRelay, { RelayEvent } from './useMobileRelay'

const UPDATE_INTERVAL = 250 /* ms */

let interval: ReturnType<typeof setInterval> | undefined
let lastHeader: string | undefined
let lastElement: Element | null

const useMobileObserver = (enabled: boolean) => {
  const { postMessage } = useMobileRelay()

  const checkCenterElement = useCallback(() => {
    const element = document?.elementFromPoint(window.innerWidth / 2, window.innerHeight / 2)
    if (element && lastElement !== element) {
      lastElement = element

      const header = element?.closest('[data-mobile-header]')
      if (header instanceof HTMLElement) {
        const headerName = header.dataset.mobileHeader

        if (headerName !== undefined && headerName !== lastHeader) {
          postMessage(RelayEvent.HeaderState, headerName)
          lastHeader = headerName
        }
      }
    }
  }, [postMessage])

  useEffect(() => {
    if (enabled && interval === undefined) {
      interval = setInterval(checkCenterElement, UPDATE_INTERVAL)
    }

    return () => {
      clearInterval(interval)
      interval = undefined
    }
  }, [enabled, checkCenterElement])
}

export default useMobileObserver
