import { useTranslation } from 'react-i18next'

import { SupportedLanguage } from '../../__generated__/api'
import api from '../services/api'

import { useApi } from './useApi'

export const useServiceName = (
  serviceId: number | undefined
): { name: string | null; pending: boolean } => {
  const { i18n } = useTranslation()
  const { data, pending } = useApi(
    api.v1.getServiceName,
    {
      serviceId: serviceId ?? -1,
      lang: i18n.language as SupportedLanguage,
    },
    undefined,
    Boolean(serviceId)
  )

  return { name: data?.name ?? null, pending }
}
