import styled from '@emotion/styled'
import { White } from '@mehilainen/design-system-tokens/colors'
import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AuthorizedPerson, User } from '../../../__generated__/api'
import { ColumnFlex } from '../../../common/components/Layout/Layout'
import ModalHeader from '../../../common/components/Modal/ModalHeader/ModalHeader'
import ResponsiveModal from '../../../common/components/Modal/ResponsiveModal/ResponsiveModal'
import useReserveState from '../../../domain/reserve/hooks/useReserveState'

import UserSelect from './UserSelect'

const Container = styled(ColumnFlex)``

const ModalContentContainer = styled(ColumnFlex)`
  background: ${White};
  height: 100%;
`

interface Props {
  users?: AuthorizedPerson[]
  loggedInUser: User | undefined
  onSelectUser(omUid?: number): void
  onSelectNewUser(omUid: number): void
}

const UserSelectModalButton: React.FC<React.PropsWithChildren<Props>> = ({
  users,
  loggedInUser,
  onSelectUser,
  onSelectNewUser,
}) => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const { selectedInsuranceContractId } = useReserveState()
  return (
    <Container>
      <Button type="button" variant="outlined" onClick={() => setIsModalOpen(true)}>
        {t('component.reserveUserSelect.changeButtonText')}
      </Button>

      <ResponsiveModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <ModalContentContainer>
          <ModalHeader open={isModalOpen} onClose={() => setIsModalOpen(false)} />
          <UserSelect
            users={users}
            onSelectUser={(omUid) => {
              onSelectUser(omUid)
              setIsModalOpen(false)
            }}
            onNewUser={(omUid) => {
              onSelectNewUser(omUid)
              setIsModalOpen(false)
            }}
            onReserveSelf={() => {
              onSelectUser(loggedInUser?.omUid)
              setIsModalOpen(false)
            }}
            appointmentInfo={null}
            node={null}
            nodePending={false}
            disableUndefinedSelection={Boolean(selectedInsuranceContractId)}
          />
        </ModalContentContainer>
      </ResponsiveModal>
    </Container>
  )
}

export default UserSelectModalButton
