import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Button } from '@mui/material'

import { scale } from '../../utils/scale'

const AnchorButton = styled(Button)<{ showIcon?: boolean; target?: string }>`
  ${(props) =>
    props.showIcon
      ? css`
          div {
            display: flex;
            flex-direction: row;
            gap: ${scale(1)};

            div {
              display: none;
            }

            i {
              width: 13px;
              height: 13px;

              svg {
                width: 13px;
                height: 13px;
              }
            }
          }
        `
      : css`
          text-decoration: none !important;
          ::after {
            display: none !important;
          }
        `}
`

export default AnchorButton
