import styled from '@emotion/styled'
import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  BookingInstructions as BookingInstructionsT,
  NotificationStyle,
} from '../../../__generated__/api'
import { scale } from '../../utils/scale'
import { stripTagsAllowBasicHtml } from '../../utils/xss'
import { ColumnFlex } from '../Layout/Layout'
import Modal from '../Modal/Modal'
import Notification from '../Notification/Notification'
import { Text } from '../Typography/Typography'

const ModalContainer = styled(ColumnFlex)`
  gap: ${scale(3)};
`

const InstructionsContainer = styled(Text)`
  white-space: pre-wrap;
`

interface Props {
  instructions: BookingInstructionsT
}

const BookingInstructions: React.FC<React.PropsWithChildren<Props>> = ({ instructions }) => {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  return (
    <>
      <Notification
        style={NotificationStyle.Warning}
        action={
          <Button
            onClick={() => setModalOpen(true)}
            variant="outlined"
            size="small"
            color="warning"
          >
            {t('component.bookingInstructions.buttonText')}
          </Button>
        }
      >
        {t('component.bookingInstructions.text')}
      </Notification>
      {modalOpen && (
        <Modal open onClose={() => setModalOpen(false)}>
          <ModalContainer>
            <Text $size={400} $weight="Medium" $height="Medium">
              {t('component.bookingInstructions.modal.heading')}
            </Text>
            <InstructionsContainer
              as="div"
              $size={300}
              $weight="Regular"
              $height="Large"
              dangerouslySetInnerHTML={{
                __html: stripTagsAllowBasicHtml(instructions.bookingInstructions ?? ''),
              }}
            />
            {instructions.attachments && instructions.attachments.length && (
              <>
                <Text $size={400} $weight="Medium" $height="Medium">
                  {t('component.bookingInstructions.modal.attachmentsHeading')}
                </Text>
                <ul>
                  {instructions.attachments.map((attachment, index) => (
                    <li key={`attachment-${attachment.link}-${index}`}>
                      <Text
                        as="a"
                        href={attachment.link}
                        target="_blank"
                        $size={300}
                        $weight="Regular"
                        $height="Medium"
                      >
                        {attachment.title}
                      </Text>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </ModalContainer>
        </Modal>
      )}
    </>
  )
}

export default BookingInstructions
