import styled from '@emotion/styled'
import { AutocompleteRenderOptionState, AutocompleteRenderGroupParams } from '@mui/material'
import { Autocomplete } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'

import * as Analytics from '../../../common/utils/analytics'
import { useDeepCompareEffect } from '../../hooks/useDeepCompare'
import AVSearch from '../../services/search/AVSearch'
import { useDebouncedValue } from '../../utils/debounce'
import { ColumnFlex, VisuallyHidden } from '../Layout/Layout'

import SearchEndAdornment from './SearchEndAdornment'
import StyledTextField from './SearchStyledTextField'
import TextFieldHeading from './SearchTextFieldHeading'

export interface AutocompleteSearchResult {
  keyword?: string
  name?: string
}

const InputContainer = styled(ColumnFlex)`
  padding: 0 16px;
`

// eslint-disable-next-line @typescript-eslint/ban-types
interface Props<V extends object> {
  searchIndex: AVSearch<V>
  renderAutocompleteOption(
    props: React.HTMLAttributes<HTMLLIElement>,
    option: V,
    state: AutocompleteRenderOptionState
  ): React.ReactNode
  onAutocompleteOptionSelect(option: V): void
  getOptionLabel(option: V): string
  optionListModifier?(
    options: Array<V & AutocompleteSearchResult>
  ): Array<V & AutocompleteSearchResult>
  label: string
  placeholder?: string
  inputValue?: string
  useTracking?: boolean
  renderGroup?(params: AutocompleteRenderGroupParams): React.ReactNode
  groupBy?(option: V): string
}

const SearchAutocomplete = <V extends object>({
  searchIndex,
  renderAutocompleteOption,
  onAutocompleteOptionSelect,
  optionListModifier,
  label,
  placeholder,
  getOptionLabel,
  useTracking,
  renderGroup,
  groupBy,
}: Props<V>): JSX.Element => {
  const { t, i18n } = useTranslation()
  const [searchWord, setSearchWord] = useState<string>('')
  const [value, setValue] = useState<(V & AutocompleteSearchResult) | null>(null)
  const [options, setOptions] = useState<Array<V & AutocompleteSearchResult>>([])
  const processedOptions = optionListModifier ? optionListModifier(options) : options
  const debouncedInputValue = useDebouncedValue(searchWord, 300)

  const textFieldId = useMemo(() => `searchTextField-${uuid()}`, [])

  useEffect(() => {
    if (debouncedInputValue !== '' && debouncedInputValue.length > 2) {
      searchIndex.search(debouncedInputValue, 10).then((results) => {
        setOptions(results)
        if (useTracking) {
          Analytics.trackAutocomplete(debouncedInputValue, results.length)
        }
      })
    } else {
      setOptions([])
    }
  }, [i18n.language, debouncedInputValue, searchIndex, useTracking])

  useDeepCompareEffect(() => {
    if (value) {
      onAutocompleteOptionSelect(value)
    }
  }, [value])

  return (
    <div>
      <Autocomplete
        disableCloseOnSelect
        disablePortal
        renderInput={(props) => (
          <>
            <InputContainer>
              <TextFieldHeading id={`${textFieldId}-heading`}>{label}</TextFieldHeading>
              <StyledTextField
                {...props}
                id={textFieldId}
                size="medium"
                variant="outlined"
                placeholder={placeholder ?? t('component.search.searchTextField.placeholder')}
                autoFocus
                InputProps={{
                  'aria-labelledby': `${textFieldId}-heading`,
                  'aria-describedby': `${textFieldId}-info`,
                  ref: props.InputProps.ref,
                  endAdornment: <SearchEndAdornment value={searchWord} setValue={setSearchWord} />,
                }}
              />
            </InputContainer>
            <VisuallyHidden id={`${textFieldId}-info`}>
              {t('component.search.searchTextField.ariaDescription')}
            </VisuallyHidden>
          </>
        )}
        autoHighlight
        filterOptions={(x) => x}
        options={processedOptions}
        value={value}
        onChange={(_, newValue: unknown) => {
          setValue(newValue as V)
        }}
        inputValue={searchWord}
        onInputChange={(_event, newValue, reason) => {
          // prevent blur from resetting the input field
          if (reason === 'input') {
            setSearchWord(newValue)
          }
        }}
        renderOption={(props, option, state) => renderAutocompleteOption(props, option as V, state)}
        onClose={(_, reason) => {
          if (reason === 'escape') {
            setSearchWord('')
          }
        }}
        noOptionsText={t('component.search.searchAutocomplete.noOptions')}
        getOptionLabel={(option: unknown) => getOptionLabel(option as V)}
        renderGroup={renderGroup}
        groupBy={groupBy && ((option: unknown) => groupBy(option as V))}
      />
    </div>
  )
}

export default SearchAutocomplete
