import styled from '@emotion/styled'
import React from 'react'

import InsuranceOptionsSelect from '../../../common/components/InsuranceOptionsSelect/InsuranceOptionsSelect'
import { CenteredColumnFlex, MinMaxDiv } from '../../../common/components/Layout/Layout'
import { Text } from '../../../common/components/Typography/Typography'
import { useInsurancePayerOptions } from '../../../common/hooks/useInsurance'
import useTranslation from '../../../common/hooks/useTranslation'
import * as Analytics from '../../../common/utils/analytics'
import { scale } from '../../../common/utils/scale'

const Container = styled(CenteredColumnFlex)`
  min-height: 100%;
  margin-bottom: ${scale(12)};
`

interface Props {
  insuranceId: number
  onSelect(payerOptionId: string): void
  onBack(): void
}

const InsuranceOptionsSelectView: React.FC<React.PropsWithChildren<Props>> = ({
  insuranceId,
  onSelect,
  onBack,
}) => {
  const { t, i18n } = useTranslation()
  const { insurancePayerOptions } = useInsurancePayerOptions(insuranceId)

  return (
    <Container>
      <MinMaxDiv size="700px" gap="0">
        <InsuranceOptionsSelect
          options={insurancePayerOptions}
          heading={t('component.insuranceOptionsSelect.header')}
          selectHeading={
            i18n.exists(`component.insuranceOptionsSelect.selectHeader.${insuranceId}`)
              ? t(`component.insuranceOptionsSelect.selectHeader.${insuranceId}`)
              : undefined
          }
          renderOptionDescription={(option) => {
            if (i18n.exists(`component.insuranceOptionsSelect.description.${option.id}`)) {
              return (
                <Text $size={200} $weight="Regular" $height="Large">
                  {t(`component.insuranceOptionsSelect.description.${option.id}`)}
                </Text>
              )
            }
            return null
          }}
          dataCyContinue="insurancePayerContinueButton"
          dataCyBack="insurancePayerBackButton"
          dataAnalyticsPathContinue="insurance-payer-continue"
          dataAnalyticsPathBack="insurance-payer-back"
          renderOptionHeader={(option) => (
            <Text $size={400} $height="Medium" $weight="Regular">
              {option.name}
            </Text>
          )}
          onSelect={(option) => {
            Analytics.trackInsuranceEventSelect(option.name, 'continue')
            onSelect(option.id)
          }}
          onBack={() => {
            Analytics.trackInsuranceEventSelect('', 'cancel')
            onBack()
          }}
        />
      </MinMaxDiv>
    </Container>
  )
}

export default InsuranceOptionsSelectView
