import { Dayjs } from 'dayjs'
import { atom } from 'recoil'

import dayjs from '../../common/utils/dayjs/dayjs'

export const defaultSelectedDate = dayjs()
export const selectedDateAtom = atom<Dayjs>({
  key: 'practitioner-selectedDateAtom',
  default: defaultSelectedDate,
})

export const selectedAppointmentIdAtom = atom<number | undefined>({
  key: 'practitioner-selectedAppointmentIdAtom',
  default: undefined,
})

export const selectedPractitionerIdAtom = atom<number | undefined>({
  key: 'practitioner-selectedPractitionerIdAtom',
  default: undefined,
})

export const practitionerModalPractitionerId = atom<number | undefined>({
  key: 'practitioner-practitionerModalPractitionerId',
  default: undefined,
})
