import styled from '@emotion/styled'
import { Search, Times } from '@mehilainen/mds-customer/icons'
import React from 'react'

const EndAdornmentContainer = styled.div`
  cursor: pointer;
`

interface Props {
  value: string
  setValue(value: string): void
}

const SearchEndAdornment: React.FC<React.PropsWithChildren<Props>> = ({ value, setValue }) => (
  <EndAdornmentContainer onClick={() => setValue('')}>
    {value ? <Times /> : <Search />}
  </EndAdornmentContainer>
)

export default SearchEndAdornment
