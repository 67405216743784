import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { SupportedLanguage } from '../../../../__generated__/api'
import { useApi } from '../../../../common/hooks/useApi'
import api from '../../../../common/services/api'
import useReserveState from '../../hooks/useReserveState'
import PohjolaSVG from '../../images/Pohjola.svg?react'

import CustomershipCheck from './CustomershipCheck'
import { Container } from './Shared'

interface Props {
  onSubmit(): void
  onBack(): void
  onContinueAsSelfPaying(): void
}

const PohjolaForm: React.FC<React.PropsWithChildren<Props>> = ({
  onSubmit,
  onBack,
  onContinueAsSelfPaying,
}) => {
  const { i18n } = useTranslation()
  const { selectedUser } = useReserveState()
  const insurancesApiInsuranceCompanyId = 'op'

  const {
    data: options,
    pending: optionsPending,
    error: optionsError,
  } = useApi(
    api.v1.insuranceOptions,
    {
      subjectOmUid: selectedUser.omUid,
      insuranceCompanyId: insurancesApiInsuranceCompanyId,
      lang: i18n.language as SupportedLanguage,
    },
    { checkCustomership: true }
  )

  useEffect(() => {
    if (!options.checkCustomership) {
      onSubmit()
    }
  }, [options, onSubmit])

  if (optionsPending) {
    return null
  }

  if (optionsError) {
    throw 'Error fetching insurance options'
  }

  return (
    <Container>
      <CustomershipCheck
        Logo={PohjolaSVG}
        translationKey="pohjolaForm"
        insurancesApiInsuranceCompanyId={insurancesApiInsuranceCompanyId}
        subjectOmUid={selectedUser.omUid}
        onBack={onBack}
        onSuccess={() => onSubmit()}
        onContinueAsSelfPaying={onContinueAsSelfPaying}
      />
    </Container>
  )
}

export default PohjolaForm
