import {
  Gray100,
  Gray300,
  Gray600,
  Gray700,
  Gray900,
  Primary500,
} from '@mehilainen/design-system-tokens/colors'
import { baseTypography } from '@mehilainen/design-system-tokens/typography'
import { AngleRight } from '@mehilainen/mds-customer/icons'
import { Stack, SxProps, Typography, styled, ButtonBase } from '@mui/material'
import React from 'react'

// Placeholder for MDS
const shouldForwardProp = (propName: string): boolean => propName[0] !== '$'

const Button = styled(ButtonBase)({
  ':hover': {
    backgroundColor: Gray100,
  },
  ':focus': {
    outline: 'revert',
  },
})

const Container = styled(Stack)(() => ({
  width: '100%',
  gap: '16px',
  padding: '0px 16px',
}))

const IconContainer = styled(Stack, { shouldForwardProp })<{ $primary?: boolean }>(
  ({ $primary }) => ({
    color: $primary ? Primary500 : Gray700,
  })
)

const ContentContainer = styled(Stack)({
  borderBottom: `1px solid ${Gray300}`,
})

const TitleContainer = styled(Stack)({
  gap: '4px',
  padding: '16px 16px 16px 0px',
  width: '100%',
})

const Title = styled(Typography, { shouldForwardProp })<{ $size: DefaultedSize }>(({ $size }) => ({
  fontSize: $size === 'small' ? baseTypography.size[300].rem : baseTypography.size[400].rem,
  fontWeight: baseTypography.weight.Regular,
  lineHeight: baseTypography.height.Medium,
  color: Gray900,
  textAlign: 'left',
}))

const Subtitle = styled(Title, { shouldForwardProp })<{ $size: DefaultedSize }>(({ $size }) => ({
  fontSize: $size === 'small' ? baseTypography.size[200].rem : baseTypography.size[300].rem,
  color: Gray600,
  textAlign: 'left',
}))

type DefaultedSize = NonNullable<Props['size']>

interface Props {
  hideAngle?: boolean
  icon?: React.ReactNode
  onClick?(): void
  size?: 'small' | 'medium'
  subtitle?: React.ReactNode
  sx?: SxProps
  title: React.ReactNode
}

/**
 * ListBlock component for listing items in a list.
 */
const ListBlock: React.VFC<Props> = ({
  hideAngle,
  icon,
  onClick,
  subtitle,
  size: $size = 'medium',
  sx,
  title,
  ...rest
}) => {
  return (
    <Button onClick={onClick} sx={sx} {...rest}>
      <Container direction="row" alignItems="center">
        {icon && <IconContainer $primary>{icon}</IconContainer>}
        <ContentContainer
          flex={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <TitleContainer>
            <Title $size={$size}>{title}</Title>
            {subtitle && <Subtitle $size={$size}>{subtitle}</Subtitle>}
          </TitleContainer>
          {!hideAngle && (
            <IconContainer alignItems="center" justifyContent="center">
              <AngleRight fontSize="large" />
            </IconContainer>
          )}
        </ContentContainer>
      </Container>
    </Button>
  )
}

export default ListBlock
