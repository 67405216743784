import styled from '@emotion/styled'
import { Black, Primary500 } from '@mehilainen/design-system-tokens/colors'
import { AngleRight } from '@mehilainen/mds-customer/icons'
import { Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'

import { AuthorizedPerson } from '../../../__generated__/api'
import { isFromAppAtom } from '../../../state/common/atoms'
import delegationService from '../../services/delegationService'
import { scale } from '../../utils/scale'
import { DefaultButton } from '../DefaultButton/DefaultButton'
import { CenteredColumnFlex, MinMaxDiv, RowFlex } from '../Layout/Layout'
import { Text } from '../Typography/Typography'

const Container = styled(CenteredColumnFlex)`
  margin: ${scale(2)};
  gap: ${scale(1.5)};

  & > ${Text} {
    text-align: center;
  }
`

export const ButtonGroupContainer = styled(CenteredColumnFlex)`
  width: 100%;
  margin: ${scale(1.5)};
  gap: 8px;
`

const ButtonContent = styled(RowFlex)`
  width: 100%;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
`

const StyledButton = styled(Button)`
  max-width: 540px;
  width: 100%;
  height: 64px;
  padding: 0px 10px;
`

interface Props {
  options: AuthorizedPerson[] | undefined
  disableUndefinedSelection?: boolean
  onSelectUser(omUid: number | undefined): void
  onNewUser(omUid: number): void
  onBack(): void
}

export const SelectUserOptionButton: React.FC<
  React.PropsWithChildren<{ onClick(): void; text: string }>
> = ({ text, onClick }) => {
  return (
    <StyledButton data-cy={'userSelect-button'} onClick={onClick} variant="outlined">
      <ButtonContent>
        <Text $color={Black}>{text}</Text>
        <AngleRight htmlColor={Primary500} fontSize="large" />
      </ButtonContent>
    </StyledButton>
  )
}

const DelegationUserSelect: React.FC<React.PropsWithChildren<Props>> = ({
  options,
  onSelectUser: onSelectUser,
  disableUndefinedSelection,
  onNewUser,
  onBack,
}) => {
  const { t, i18n } = useTranslation()
  const isFromApp = useRecoilValue(isFromAppAtom)
  const isAddNewButtonVisible = !isFromApp
  return (
    <MinMaxDiv size="620px">
      <Container>
        <Text as="h1" $size={500} $weight="Medium" $height="Medium">
          {t('component.userSelect.delegateHeading')}
        </Text>
        <Text as="p" $size={300} $weight="Regular" $height="Large">
          {!disableUndefinedSelection
            ? t('component.userSelect.delegateParagraph')
            : t('component.userSelect.delegateCallbackParagraph')}
        </Text>
        <ButtonGroupContainer>
          {options &&
            options.map((i) => (
              <SelectUserOptionButton
                key={i.omUid}
                onClick={() => onSelectUser(i.omUid)}
                text={`${i.firstName} ${i.lastName}`}
              />
            ))}

          {isAddNewButtonVisible && (
            <SelectUserOptionButton
              onClick={async () => {
                const omUid = await delegationService.requestNewAuthorization(i18n.language)
                if (options?.find((i) => i.omUid === omUid)) {
                  onSelectUser(omUid)
                } else {
                  onNewUser(omUid)
                }
              }}
              text={t('component.userSelect.addNew')}
            />
          )}

          {!disableUndefinedSelection && (
            <SelectUserOptionButton
              onClick={async () => {
                onSelectUser(undefined)
              }}
              text={t('component.reserveUserSelect.modal.other')}
            />
          )}
        </ButtonGroupContainer>
        <DefaultButton variant="outlined" onClick={onBack}>
          {t('common.back')}
        </DefaultButton>
      </Container>
    </MinMaxDiv>
  )
}

export default DelegationUserSelect
