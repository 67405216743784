import React from 'react'
import { useLocation } from 'react-router-dom'

export const ohcCompanyImpersonationKey = 'contractImpersonation'
export const ohcCompanyImpersonationNthEmployeeKey = 'contractImpersonationNthUser'

const SetContractImpersonation: React.FunctionComponent<React.PropsWithChildren<object>> = () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const companyId = params.get('companyId')
  const nthEmployee = params.get('nthEmployee')
  sessionStorage.setItem(ohcCompanyImpersonationKey, String(companyId))
  if (nthEmployee) {
    sessionStorage.setItem(ohcCompanyImpersonationNthEmployeeKey, String(nthEmployee))
  }
  window.location.href = '/tyoterveys'
  return null
}

export default SetContractImpersonation
