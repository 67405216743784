import * as xss from 'xss'

const xssOptions = {
  whiteList: {
    a: ['href', 'target'],
    ul: [],
    ol: [],
    li: [],
    p: [],
    div: [],
    br: [],
    b: [],
    strong: [],
    em: [],
    h1: [],
    h2: [],
    span: [],
  },
}

const xssParser = new xss.FilterXSS(xssOptions)

/**
 * Strips out most html tags, but allows very basic html to be used.
 */
export const stripTagsAllowBasicHtml = (str: string): string => {
  return xssParser.process(str)
}
