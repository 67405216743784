import styled from '@emotion/styled'
import React from 'react'

import InputFieldContainerNew, { Props as InputFieldContainerProps } from './InputFieldContainerNew'
import { commonInputStylesNew } from './commonInputStylesNew'

const Input = styled.textarea<{ error: boolean }>`
  ${(props) => commonInputStylesNew(props.error)};
  resize: none;
`

interface Props extends React.InputHTMLAttributes<HTMLTextAreaElement>, InputFieldContainerProps {
  rows: number
}

const TextAreaNew: React.FC<React.PropsWithChildren<Props>> = React.forwardRef(
  ({ label, helperText, error, characterCount, required, id, className, ...inputProps }, ref) => {
    return (
      <InputFieldContainerNew
        label={label}
        helperText={helperText}
        error={error}
        characterCount={characterCount}
        id={id}
        className={className}
      >
        <Input
          ref={ref as React.RefObject<HTMLTextAreaElement>}
          id={id}
          error={Boolean(error)}
          {...inputProps}
        />
      </InputFieldContainerNew>
    )
  }
)

export default TextAreaNew
