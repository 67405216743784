import equal from 'fast-deep-equal'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { AppointmentType, SupportedLanguage } from '../../../__generated__/api'
import { useApi } from '../../../common/hooks/useApi'
import { useNode } from '../../../common/hooks/useNode'
import api from '../../../common/services/api'
import { flatten } from '../../../common/utils/utils'
import { generalErrorMessageAtom, isOHCAtom } from '../../../state/common/atoms'
import {
  allLocationsSelection,
  selectedAppointmentTypesAtom,
  selectedNodeLocationAtom,
} from '../../../state/search/atoms'
import { getSelectedNodeOrDefault } from '../../../state/search/selectors'
import { GetFlexibleWorklistLengthOptions, GetReqularWorkListOptions } from '../LengthOptionsHelper'

import AppointmentOptionsSelectModal, { AppointmentOptions } from './AppointmentOptionsSelectModal'

interface Props {
  appointmentId: number
  practitionerId: number
  onClose(): void
  onSelectUnavailableDuration(duration: number): void
  onSelect(options: AppointmentOptions): void
}

const AppointmentOptionsSelectModalWrapper: React.FC<React.PropsWithChildren<Props>> = ({
  appointmentId,
  practitionerId,
  onClose,
  onSelectUnavailableDuration,
  onSelect,
}) => {
  const { i18n } = useTranslation()
  const setGeneralErrorMessage = useSetRecoilState(generalErrorMessageAtom)
  const selectedAppointmentTypes = useRecoilValue(selectedAppointmentTypesAtom)
  const selectedLocation = useRecoilValue(selectedNodeLocationAtom)
  const selectedNodeId = useRecoilValue(getSelectedNodeOrDefault)
  const isOhc = useRecoilValue(isOHCAtom)

  const { data: appointmentInfo, error: appointmentInfoError } = useApi(
    api.v1.appointmentInfo,
    {
      appointmentId: String(appointmentId),
      lang: i18n.language as SupportedLanguage,
    },
    null
  )

  const { data: appointmentLengthOptions } = useApi(
    api.v1.getAppointmentLengthOptions,
    {
      lang: i18n.language as SupportedLanguage,
      appointmentId: appointmentId,
      nodeId: selectedNodeId,
    },
    null
  )

  const { data: practitionerDetails } = useApi(
    api.v1.getPractitionerDetails,
    { lang: i18n.language as SupportedLanguage, practitionerId, isOhc: isOhc },
    null
  )

  const { data: locations } = useApi(api.v1.getLocations, { lang: i18n.language }, null)

  const { node: selectedNode, pending: nodePending } = useNode(selectedNodeId)

  useEffect(() => {
    if (appointmentInfoError?.statusCode === 404) {
      setGeneralErrorMessage('error.appointmentNotFound')
      onClose()
    } else if (appointmentInfoError?.statusCode >= 400) {
      setGeneralErrorMessage('common.error')
      onClose()
    }
  }, [appointmentInfoError, setGeneralErrorMessage, onClose])

  if (
    !appointmentInfo ||
    !practitionerDetails ||
    appointmentInfoError ||
    nodePending ||
    !appointmentLengthOptions ||
    !locations
  ) {
    return null
  }

  const serviceTerms = flatten(
    selectedNode?.connections?.map((connection) =>
      connection.terms.filter((term) => term.type === 'service').map((term) => term.term)
    ) || []
  )

  const selectedClinicIds = flatten(
    selectedLocation.map(
      (selected) => locations.find((location) => location.uniqueId === selected)?.clinicIDs ?? []
    )
  )

  const lengthOptions = practitionerDetails.hasFlexibleSchedule
    ? GetFlexibleWorklistLengthOptions(
        practitionerDetails.flexibleScheduleOptions,
        serviceTerms,
        appointmentLengthOptions,
        selectedClinicIds
      )
    : GetReqularWorkListOptions(
        practitionerDetails.appointmentLengthOptions,
        appointmentLengthOptions,
        appointmentInfo.duration
      )

  const noSelectedAppointmentTypes = selectedAppointmentTypes.length === 0
  const isAllLocationsSelected = equal(selectedLocation, allLocationsSelection)
  const preselectedType =
    appointmentInfo.isClinicAppointmentPermitted &&
    ((noSelectedAppointmentTypes && !isAllLocationsSelected) ||
      selectedAppointmentTypes.some((type) => type === AppointmentType.Clinic))
      ? AppointmentType.Clinic
      : appointmentInfo.isVideoAppointmentPermitted &&
        ((noSelectedAppointmentTypes && isAllLocationsSelected) ||
          selectedAppointmentTypes.some((type) => type === AppointmentType.Video))
      ? AppointmentType.Video
      : appointmentInfo.isPhoneAppointmentPermitted &&
        ((noSelectedAppointmentTypes && isAllLocationsSelected) ||
          selectedAppointmentTypes.some((type) => type === AppointmentType.Phone))
      ? AppointmentType.Phone
      : undefined

  return (
    <AppointmentOptionsSelectModal
      practitionerDetails={practitionerDetails}
      lengthOptions={lengthOptions}
      appointmentId={appointmentId}
      onSelect={(appointmentOptions) => onSelect({ preselectedType, ...appointmentOptions })}
      onSelectUnavailableDuration={onSelectUnavailableDuration}
      onClose={onClose}
    />
  )
}

export default AppointmentOptionsSelectModalWrapper
