import styled from '@emotion/styled'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Modal from '../../../common/components/Modal/Modal'
import { Text } from '../../../common/components/Typography/Typography'

const Header = styled(Text)`
  margin-bottom: 24px;
`

const StyledText = styled(Text)`
  white-space: pre-line;
  margin-bottom: 24px;
`

interface Props {
  open: boolean
  onClose: () => void
}

const PriceDisclaimerModal: React.FC<React.PropsWithChildren<Props>> = ({ open, onClose }) => {
  const { t } = useTranslation()

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      fullWidth
      primaryButtonText={t('common.close')}
      onPrimaryButtonClick={() => onClose()}
    >
      <Header $size={500}>{t('component.priceDisclaimerModal.header')}</Header>
      <StyledText $size={300} $height="Large" $weight="Regular">
        {t('component.priceDisclaimerModal.description')}
      </StyledText>
    </Modal>
  )
}

export default PriceDisclaimerModal
