import { useEffect } from 'react'

import useMobileRelay, { RelayEvent } from './useMobileRelay'
export { MobileHeaderState } from './useMobileRelay'

const useMobileConfirmation = () => {
  const { postMessage } = useMobileRelay()

  useEffect(() => {
    postMessage(RelayEvent.AppointmentConfirmation, undefined)
  }, [postMessage])
}

export default useMobileConfirmation
